import { NavigationItem } from '../domain/navigation-item';

export class NavigationItemQuery {
  name: string;
  url: string;
  isExternal: boolean;

  static fromNavigationItem(navigationItem: NavigationItem, siteId: number): NavigationItemQuery {
    return {
      name: navigationItem.name,
      url: navigationItem.getUrl(siteId),
      isExternal: navigationItem.isExternal,
    };
  }
}
