/**
 * Replaces the properties in given path with the params supplied
 */
export class RouteWithParams {
  readonly value: string;

  /**
   * @param path - Path with parameters that need to be replaced
   */
  constructor(path: string, params: { [key: string]: string | number }) {
    Object.keys(params).forEach((key: string) => {
      path = path.replace(`:${key}`, String(params[key]));
    });
    this.value = path;
  }
}
