export const ROLE_LABEL_WITH_COLOR_MAP = {
  owner: {
    label: 'cobiro_pro_role_owner',
    colorClass: 'cs-badge-success',
  },
  admin: {
    label: 'cobiro_pro_role_admin',
    colorClass: 'cs-badge-primary',
  },
};

export class UserRoleQuery {
  private constructor(public readonly label: string, public readonly colorClass: string) {}

  static fromDTO(role: 'owner' | 'admin'): UserRoleQuery {
    const { label, colorClass } = ROLE_LABEL_WITH_COLOR_MAP[role];
    return new UserRoleQuery(label, colorClass);
  }
}
