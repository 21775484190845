import { Component, Inject, OnInit } from '@angular/core';
import { ENV_NAME, TOPBAR_BORDER_HIDDEN } from '@app.cobiro.com/cobiro-legacy';
import { PHRASE, PhraseConfigInterface } from '@app.cobiro.com/common/language';
import { ENV_CONFIG, GetsConfig, WINDOW } from '@app.cobiro.com/core/utils';
import { APP_PRELOADER_VISIBLE, UIState } from '@app.cobiro.com/shared/ui-state';
import { ProgressIndicatorLocation } from 'ngx-guided-tour';
import { FacebookEnv } from '../../../../environments/environment.config';
import { AppLayoutState } from './app-layout.state';

@Component({
  selector: 'app-cobiro-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  preloaderVisible$ = this.appPreloaderVisible.select();
  guidedTourProgressIndicatorLocation = ProgressIndicatorLocation.TopOfTourBlock;

  topBarBorderHidden$ = this.topbarBorderHidden.select();
  topBarVisible$ = this._appLayoutState.topBarVisible$;
  proModeTopbarEnabled$ = this._appLayoutState.proModeTopBarEnabled$;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject(PHRASE) private readonly phrase: PhraseConfigInterface,
    @Inject(APP_PRELOADER_VISIBLE) private readonly appPreloaderVisible: UIState<boolean>,
    @Inject(ENV_NAME) public readonly envName: string,
    @Inject(ENV_CONFIG) private readonly _config: GetsConfig,
    // TODO: REFACTOR to have only AppLayoutState
    @Inject(TOPBAR_BORDER_HIDDEN) private readonly topbarBorderHidden: UIState<boolean>,
    private readonly _appLayoutState: AppLayoutState,
  ) {}

  ngOnInit(): void {
    this.initFacebook();
    this._appLayoutState.toggleTopBar(true);

    if (!!this.window.sessionStorage.getItem('phrase')) {
      this.initPhrase();
    }
  }

  onRouteActivated(): void {
    this.appPreloaderVisible.set(false);
  }

  private initFacebook(): void {
    const config = this._config;

    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      (js as any).src = `https://connect.facebook.net/en_US/sdk.js#version=v10.0&appId=${
        config.get<FacebookEnv>('facebook').appId
      }&xfbml=true&cookie=true`;
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  private initPhrase(): void {
    this.window['PHRASEAPP_CONFIG'] = {
      projectId: this.phrase.projectId,
    };

    (function () {
      const phraseapp = document.createElement('script');
      phraseapp.type = 'text/javascript';
      phraseapp.async = true;

      phraseapp.src = `https://phraseapp.com/assets/in-context-editor/2.0/app.js?${new Date().getTime()}`;

      const s = document.getElementsByTagName('script')[0];
      s?.parentNode?.insertBefore(phraseapp, s);
    })();
  }
}
