/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { UpdatesUserDetailsDtoPort } from '../../../application/ports/secondary/updates-user-details.dto-port';
import {
  UserDetailDto,
  UserDetailDtoPartial,
} from '../../../application/ports/secondary/user-details.dto';
import { UserDetailQuery } from '../../../application/ports/primary/user-details.query';

export interface UserDetailsAttributes {
  email: string;
  partnerId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: string;
  group: string;
  createdAt: number;
  avatarUrl: string;
}

@Injectable()
export class HttpUserDetailsService implements UpdatesUserDetailsDtoPort {
  constructor(private _client: GatewayClient) {}

  update(dto: UserDetailDtoPartial, userId: string): Observable<UserDetailQuery> {
    const payload: HasData<UserDetailDtoPartial> = {
      data: {
        id: userId,
        type: 'users',
        attributes: dto,
      },
    };

    return this._client.patch<HasData<UserDetailDto>>(`v1/users/${userId}`, payload).pipe(
      map(response => ({
        firstName: response.data.attributes.first_name,
        lastName: response.data.attributes.last_name,
        phoneNumber: response.data.attributes.phone_number,
        country: response.data.attributes.country,
        email: response.data.attributes.email,
        avatar: response.data.attributes.avatar_url,
      })),
    );
  }
}
