export class UserDetailsQuery {
  constructor(
    public readonly id: string,
    public readonly displayName: string,
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly avatar: string,
  ) {}
}
