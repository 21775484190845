<div class="cs-topbar">
  <nav class="d-flex justify-content-between align-items-center w-100">
    <div class="d-flex align-items-center">
      <a [routerLink]="['/sites']" data-selector="top-bar-logo">
        <img
          class="d-none d-md-block cs-height-20-xs w-auto"
          src="./assets/logos/cobiro-full-logo.svg"
          alt="Cobiro"
        />
        <img
          class="d-block d-md-none cs-height-20-xs w-auto"
          src="./assets/logos/cobiro-logo-color.svg"
          alt="Cobiro"
        />
      </a>
      <lib-teams-switcher-widget class="cs-ml-5 cs-mr-10"></lib-teams-switcher-widget>
      <lib-navigation-list class="d-none d-md-block"></lib-navigation-list>
    </div>

    <div class="d-flex align-items-center">
      <lib-navigation-dropdown class="d-block d-md-none"></lib-navigation-dropdown>
      <lib-notifications-widget *libFeatureFlags="['notifications']"></lib-notifications-widget>
      <hui-user-menu
        [userDetails]="userMenuDetails$ | async"
        [menuItems]="userMenuItems"
      ></hui-user-menu>
    </div>
  </nav>
</div>
