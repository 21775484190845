import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { NavigationItem } from '../domain/navigation-item';
import { map, shareReplay } from 'rxjs/operators';
import { UpgradeRequestedEvent } from '@app.cobiro.com/core/events';
import { UserData } from '../domain/user-data';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';

export interface TopBarStateGetter {
  siteId: number;
  siteId$: Observable<number>;
  navigationItems$: Observable<NavigationItem[]>;
  hasUpgradeButton$: Observable<boolean>;
  user$: Observable<UserData>;
  updatePlan(): void;
}
export const TOP_BAR_STATE_GETTER = new InjectionToken<TopBarStateGetter>('TOP_BAR_STATE_GETTER');

export interface TopBarStateSetter {
  setSiteId(siteId: number): void;
  setHasUpgradeButton(hasButton: boolean): void;
  setUser(user: UserData): void;
  setHasTeams(hasTeams: boolean): void;
}

export const TOP_BAR_STATE_SETTER = new InjectionToken<TopBarStateSetter>('TOP_BAR_STATE_SETTER');

export const NAVIGATION_ITEMS: NavigationItem[] = [
  new NavigationItem('navigation_topbar_dashboard', '/site/{siteId}', false),
  new NavigationItem('navigation_topbar_my_sites', '/sites', false),
  new NavigationItem('navigation_topbar_help', 'https://support.cobiro.com/', true),
  new NavigationItem('navigation_topbar_pro', '/pro', false),
];

@Injectable()
export class TopBarState implements TopBarStateGetter, TopBarStateSetter {
  private _siteId = new BehaviorSubject<number | null>(null);
  private _hasUpgradeButton = new BehaviorSubject<boolean>(false);
  private _user = new ReplaySubject<UserData>(1);
  private readonly _hasTeams$ = new BehaviorSubject(false);

  constructor(
    @Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus,
    private readonly _featureFlagsState: FeatureFlagsState,
  ) {}

  readonly navigationItems$ = combineLatest([
    of(NAVIGATION_ITEMS),
    this._featureFlagsState.hasFlags(['cobiro_pro_app', 'cobiro_pro_in_top_bar']),
    this._hasTeams$.asObservable(),
  ]).pipe(
    map(([navigationItems, hasCobiroProFeatureFlag, hasTeams]) => {
      return navigationItems.filter(navigationItem => {
        if (navigationItem.name !== 'navigation_topbar_pro') {
          return true;
        }
        return hasCobiroProFeatureFlag && hasTeams;
      });
    }),
  );

  get siteId(): number {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._siteId.getValue()!;
  }

  get siteId$(): Observable<number> {
    return this._siteId.asObservable().pipe(shareReplay(1));
  }

  setSiteId(siteId: number): void {
    this._siteId.next(siteId);
  }

  get hasUpgradeButton$(): Observable<boolean> {
    return this._hasUpgradeButton.asObservable().pipe(shareReplay(1));
  }

  setHasTeams(hasTeams: boolean): void {
    this._hasTeams$.next(hasTeams);
  }

  setHasUpgradeButton(hasButton: boolean) {
    this._hasUpgradeButton.next(hasButton);
  }

  updatePlan(): void {
    this._applicationBus.dispatch(new UpgradeRequestedEvent('PAY_DISPLAY-PLAN'));
  }

  get user$(): Observable<UserData> {
    return this._user.asObservable();
  }

  setUser(user: UserData): void {
    this._user.next(user);
  }
}
