import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesState } from './application/features.state';
import { RouterModule } from '@angular/router';
import { FeaturesLoadedEventHandler } from './adapters/features-loaded.event-handler';
import { FeatureGuard } from './adapters/feature.guard';
import { provideApplicationEventHandler } from '@cobiro/eda';

@NgModule({
  imports: [CommonModule, RouterModule],
})
export class CorePaymentsFeaturesModule {
  static forRoot(): ModuleWithProviders<CorePaymentsFeaturesModule> {
    return {
      ngModule: CorePaymentsFeaturesModule,
      providers: [
        FeatureGuard,
        FeaturesState,
        provideApplicationEventHandler(FeaturesLoadedEventHandler),
      ],
    };
  }
}
