/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { isEmailValidator } from '@app.cobiro.com/shared/validators';
import { BehaviorSubject, combineLatest, debounceTime, Observable, Subject, zip } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  CLEARS_TEAMS_SETTINGS_COMMAND_PORT,
  ClearsTeamSettingsCommandPort,
} from '../../../../application/ports/primary/clears-team-settings.command-port';
import { CountryQuery } from '../../../../application/ports/primary/country.query';
import {
  GETS_COUNTRIES_QUERY,
  GetsCountriesQueryPort,
} from '../../../../application/ports/primary/gets-countries.query-port';
import {
  GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT,
  GetsSaveTeamCommandErrorQueryPort,
} from '../../../../application/ports/primary/gets-save-team-command-error.query-port';
import {
  GETS_TEAM_SETTINGS_QUERY_PORT,
  GetsTeamSettingsQueryPort,
} from '../../../../application/ports/primary/gets-team-settings.query-port';
import {
  GETS_VAT_NUMBER_VALIDATION_QUERY,
  GetsVatNumberValidationQueryPort,
} from '../../../../application/ports/primary/gets-vat-number-validation.query-port';
import {
  LOADS_TEAMS_SETTINGS_COMMAND_PORT,
  LoadsTeamSettingsCommandPort,
} from '../../../../application/ports/primary/loads-team-settings.command-port';
import { SavesSelectedCountryCommand } from '../../../../application/ports/primary/saves-selected-country.command';
import {
  SAVES_SELECTED_COUNTRY_COMMAND,
  SavesSelectedCountryCommandPort,
} from '../../../../application/ports/primary/saves-selected-country.command-port';
import {
  SAVES_TEAM_SETTINGS_COMMAND,
  SavesTeamSettingsCommand,
} from '../../../../application/ports/primary/saves-team-settings.command-port';
import { TeamSettingsQuery } from '../../../../application/ports/primary/team-settings.query';
import { VatNumberVeisValidator } from '../vat-number-veis-validator/vat-number-veis.validator';

const EMPTY_VAT_NUMBER = '---';

const CountrySelectionRequiredValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null =>
  control?.value !== '' && !instanceOfACountry(control?.value) ? { invalidCountry: true } : null;

function instanceOfACountry(country: any): country is CountryQuery {
  return !!country && typeof country !== 'string' && 'code' in country;
}

@Component({
  selector: 'lib-team-settings-form',
  templateUrl: 'team-settings-form.component.html',
  styleUrls: ['./team-settings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamSettingsFormComponent implements OnInit, OnDestroy {
  private readonly _vatVeisValidator = new VatNumberVeisValidator(
    this._getsVatNumberValidationQuery,
  );

  vatNumberToBeProvided = false;
  showSuccessMessage = false;

  readonly loadingSettings$ = new BehaviorSubject<boolean>(false);
  readonly saveInProgress$ = new Subject<boolean>();

  readonly form = new UntypedFormGroup({
    agencyName: new UntypedFormControl('', [Validators.required]),
    contactEmail: new UntypedFormControl('', [Validators.required, isEmailValidator]),
    addressLine1: new UntypedFormControl('', [Validators.required]),
    addressLine2: new UntypedFormControl(''),
    zipCode: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl(null, [Validators.required, CountrySelectionRequiredValidator]),
    vatNumber: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
  });
  private readonly _destroyed$ = new Subject<void>();

  readonly countries$ = combineLatest([
    this._getsCountriesQuery
      .getCountriesQuery()
      .pipe(
        map(countries =>
          countries.map(({ name, code }) =>
            name == 'Greece' ? { name, code: 'EL' } : { name, code },
          ),
        ),
      ),
    this.selectCountryFormValue(),
  ]).pipe(
    takeUntil(this._destroyed$),
    map(([countries, controlValue]: [CountryQuery[], { code: string }]) => {
      const controlValueString = controlValue?.toString() ?? '';
      return countries.filter(country =>
        country.name.toLowerCase().includes(controlValueString.toLowerCase()),
      );
    }),
  );

  constructor(
    @Inject(GETS_TEAM_SETTINGS_QUERY_PORT)
    private readonly _getsWorkspaceSettingsQuery: GetsTeamSettingsQueryPort,
    @Inject(SAVES_TEAM_SETTINGS_COMMAND)
    private readonly _savesWorkspaceSettingsCommand: SavesTeamSettingsCommand,
    @Inject(CLEARS_TEAMS_SETTINGS_COMMAND_PORT)
    private readonly _clearsTeamSettingsCommand: ClearsTeamSettingsCommandPort,
    @Inject(LOADS_TEAMS_SETTINGS_COMMAND_PORT)
    private readonly _loadsTeamSettingsCommand: LoadsTeamSettingsCommandPort,
    @Inject(GETS_COUNTRIES_QUERY)
    private readonly _getsCountriesQuery: GetsCountriesQueryPort,
    @Inject(GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT)
    private readonly _getsSaveTeamCommandErrorQuery: GetsSaveTeamCommandErrorQueryPort,
    @Inject(GETS_VAT_NUMBER_VALIDATION_QUERY)
    private readonly _getsVatNumberValidationQuery: GetsVatNumberValidationQueryPort,
    @Inject(SAVES_SELECTED_COUNTRY_COMMAND)
    private readonly _savesSelectedCountryCommand: SavesSelectedCountryCommandPort,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._loadsTeamSettingsCommand
      .loadTeamsSettings()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.loadingSettings$.next(true));

    this.selectCountryFormValue()
      .pipe(
        filter(controlValue => !!controlValue),
        switchMap(controlValue =>
          this._savesSelectedCountryCommand.saveSelectedCountry(
            new SavesSelectedCountryCommand(controlValue.code),
          ),
        ),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this.handleVatNumberVeisValidation();
    this.handleFormBuilding();
    this.handleSaveError();
  }

  onFormSubmit(): void {
    this.form.markAllAsTouched();
    const formValues = this.form.getRawValue();

    this.saveInProgress$.next(true);
    this._savesWorkspaceSettingsCommand
      .saveTeamSettings({
        agencyName: formValues.agencyName,
        contactEmail: formValues.contactEmail,
        addressLine1: formValues.addressLine1,
        addressLine2: formValues.addressLine2 || null,
        zipCode: formValues.zipCode,
        city: formValues.city,
        countryCode: formValues.country.code === 'EL' ? 'GR' : formValues.country.code,
        vatNumber: formValues.vatNumber === EMPTY_VAT_NUMBER ? null : formValues.vatNumber,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.saveInProgress$.next(false);
        if (this.form.valid) {
          this._checkForm();
        }
      });
  }

  onVatNumberToBeProvidedChange(): void {
    this.vatNumberToBeProvided = !this.vatNumberToBeProvided;
    this.form.markAsDirty();
    const vatNumberControl = this.form.get('vatNumber');

    if (this.vatNumberToBeProvided) {
      vatNumberControl.addValidators(Validators.required);
      vatNumberControl.setValue('');
      vatNumberControl.enable();
      this.form.updateValueAndValidity();
    } else {
      vatNumberControl.removeValidators(Validators.required);
      vatNumberControl.setValue(EMPTY_VAT_NUMBER);
      vatNumberControl.disable();
      this.form.updateValueAndValidity();
    }
  }

  displayCountryName(countryQuery: CountryQuery): string | null {
    return countryQuery?.name;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._vatVeisValidator.unregister();
    this._clearsTeamSettingsCommand.clearTeamSettings();
  }

  private _buildForm(workspaceSettings: TeamSettingsQuery): void {
    this.form.patchValue({
      ...workspaceSettings,
      vatNumber:
        workspaceSettings.vatNumber === null ? EMPTY_VAT_NUMBER : workspaceSettings.vatNumber,
    });
    this._checkForm();
    this.vatNumberToBeProvided = !workspaceSettings.addressLine1
      ? true
      : workspaceSettings.vatNumber !== null;

    if (!this.vatNumberToBeProvided) {
      this.form.get('vatNumber').disable();
    }
  }

  private _checkForm(): void {
    this.form.updateValueAndValidity();
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  private handleVatNumberVeisValidation(): void {
    const vatNumberControl = this.form.get('vatNumber');

    vatNumberControl.valueChanges
      .pipe(
        filter(() => this.vatNumberToBeProvided),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(vatNumber => this._vatVeisValidator.validate(vatNumber)),
        tap(() => (this.showSuccessMessage = true)),
        takeUntil(this._destroyed$),
      )
      .subscribe(validation => {
        vatNumberControl.setErrors(validation, { emitEvent: true });
        this.form.updateValueAndValidity();
        this._changeDetectorRef.detectChanges();
      });
  }

  private handleFormBuilding(): void {
    zip(
      this._getsWorkspaceSettingsQuery.getTeamSettings().pipe(
        first(query => !!query),
        map(query => (query.countryCode === 'GR' ? { ...query, countryCode: 'EL' } : query)),
      ),
      this._getsCountriesQuery.getCountriesQuery().pipe(
        filter(countries => !!countries),
        map(countries =>
          countries.map(({ name, code }) =>
            name == 'Greece' ? { name, code: 'EL' } : { name, code },
          ),
        ),
      ),
    ).subscribe(([settingsQuery, countriesQuery]) => {
      const countryCode = settingsQuery.countryCode;
      const country =
        countryCode &&
        countriesQuery.find(availableCountry => availableCountry.code === countryCode);
      this._buildForm(settingsQuery);
      this.form.get('country').setValue(country);
    });
  }

  private handleSaveError(): void {
    this._getsSaveTeamCommandErrorQuery
      .getSaveTeamCommandErrorQuery()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((errorFieldNames: string[]) => {
        errorFieldNames.forEach(errorFieldName => {
          const control = this.form.get(errorFieldName);
          control.setErrors({ ...control.errors, invalidField: true });
        });
        this.form.updateValueAndValidity();
        this._changeDetectorRef.detectChanges();
      });
  }

  private selectCountryFormValue(): Observable<{ code: string }> {
    return this.form.get('country').valueChanges.pipe(distinctUntilChanged());
  }
}
