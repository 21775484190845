/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { NavTopbarItem, TopbarItems, topbarItemsMap } from './configs/topbar-items.config';
import { SidebarItems } from './configs/sidebar-items.config';
import { ActivatedRouteSnapshot } from '@angular/router';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';

@Injectable({
  providedIn: 'root',
})
export class LayoutState {
  // TODO: it's not used anymore
  topbarItems = new BehaviorSubject<NavTopbarItem[]>(null);
  sidebarItems = new BehaviorSubject<SidebarItems[]>(null);
  bottombarItems = new BehaviorSubject<SidebarItems[]>(null);
  activeSidebarItem = new BehaviorSubject<SidebarItems>(null);
  hasNavigation = new BehaviorSubject<boolean>(true);
  hasSidebar = new BehaviorSubject<boolean>(false);
  hasTopbar = new BehaviorSubject<boolean>(false);
  hasBottombar = new BehaviorSubject<boolean>(true);
  hasBackButton = new BehaviorSubject<boolean>(false);
  hasWarning = new BehaviorSubject<boolean>(false);
  activatedRoute = new BehaviorSubject<ActivatedRouteSnapshot>(null);
  private mobileMenuOpen = new BehaviorSubject<boolean>(false);

  sidebarItems$ = this.sidebarItems.asObservable();
  activeSidebarItem$ = this.activeSidebarItem.asObservable();
  hasNavigation$ = this.hasNavigation.asObservable();
  hasSidebar$ = this.hasSidebar.asObservable();
  hasTopbar$ = this.hasTopbar.asObservable();
  hasWarning$ = this.hasWarning.asObservable();
  hasBackButton$ = this.hasBackButton.asObservable();

  constructor(private _featureFlagState: FeatureFlagsState) {
    this._featureFlagState
      .hasFlags(['cobiro_new_ui_release'])
      .pipe(take(1))
      .subscribe((res: boolean) => this.hasWarning.next(res));
  }

  toggleFullscreenLayout(isFullscreen: boolean) {
    this.toggleBottomBar(!isFullscreen);
    this.toggleBackbutton(isFullscreen);
  }

  toggleMobileMenu(open: boolean) {
    this.mobileMenuOpen.next(open);
  }

  dispatch(
    newState: Partial<{
      topbarItems: TopbarItems[];
      sidebarItems: string[];
      bottombarItems: string[];
      activeSidebarItem: string;
      hasTopbar: boolean;
      hasSidebar: boolean;
      activatedRoute: ActivatedRouteSnapshot;
    }>,
  ) {
    this.topbarItems.next(
      newState.topbarItems?.map((item: TopbarItems) => topbarItemsMap.get(item)) || [],
    );
    this.sidebarItems.next(newState.sidebarItems?.map((item: string) => SidebarItems[item]) || []);
    this.activeSidebarItem.next(SidebarItems[newState.activeSidebarItem] || null);
    this.bottombarItems.next(
      newState.bottombarItems?.map((item: string) => SidebarItems[item]) || [],
    );
    this.hasTopbar.next(newState.hasTopbar || false);
    this.hasSidebar.next(newState.hasSidebar || false);
    this.activatedRoute.next(newState.activatedRoute || null);
  }

  private toggleBottomBar(hasBottombar: boolean = false) {
    this.hasBottombar.next(hasBottombar);
  }

  private toggleBackbutton(hasBackButton: boolean = false) {
    this.hasBackButton.next(hasBackButton);
  }
}
