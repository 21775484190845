import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppLayoutState {
  private readonly _proModeTopBarEnabled$ = new BehaviorSubject<boolean>(false);
  private readonly _topBarVisible$ = new BehaviorSubject<boolean>(true);

  readonly proModeTopBarEnabled$ = this._proModeTopBarEnabled$.asObservable();
  readonly topBarVisible$ = this._topBarVisible$.asObservable();

  toggleProModeTopBar(enabled: boolean): void {
    this._proModeTopBarEnabled$.next(enabled);
  }

  toggleTopBar(visible: boolean): void {
    this._topBarVisible$.next(visible);
  }
}
