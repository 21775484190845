export class NavigationItem {
  constructor(
    public readonly name: string,
    private readonly _url: string,
    public readonly isExternal: boolean,
  ) {}

  getUrl(siteId: number): string {
    if (!this._url.includes('{siteId}')) {
      return this._url;
    }
    return siteId ? this._url.replace('{siteId}', siteId.toString()) : null;
  }
}
