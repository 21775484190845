import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationListElementQuery } from '../../query/notification-list-element.query';

@Component({
  selector: 'lib-notification-list-element',
  templateUrl: './notification-list-element.component.html',
  styleUrls: ['./notification-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListElementComponent {
  public readonly iconMap = new Map([
    ['message', 'envelope'], // TODO: Replace icon with Iconic
    ['newOrder', 'bag-checked'], // TODO: Replace icon with Iconic
  ]);
  @Input() public readonly notification: NotificationListElementQuery;
  @Output() public readonly elementClicked = new EventEmitter<NotificationListElementQuery>();
}
