import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageInterface } from '@app.cobiro.com/core/storage';

@Injectable({
  providedIn: 'root',
})
export class NotAuthenticatedGuard  {
  authStorage: LocalStorageInterface;

  constructor(private storageService: LocalStorage, private router: Router) {
    this.authStorage = this.storageService.init('auth');
  }

  canActivate(): boolean | Promise<boolean> {
    const auth = this.authStorage.get();
    if (auth && auth.access_token) {
      return this.router.navigate(['/connect']);
    }

    return true;
  }
}
