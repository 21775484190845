import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
} from '../../../application/ports/primary/gets-cobiro-pro-context.query-port';

@Injectable()
export class IsNewProModeGuard  {
  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroContextQuery: GetsCobiroProContextQueryPort,
  ) {}

  canActivate(): Observable<boolean> {
    return this._getsCobiroContextQuery.getContext().pipe(
      concatMap(() => {
        // @TODO fix that when all CobiroPro rewrite will be ready
        return of(true);
      }),
    );
  }
}
