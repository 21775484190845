import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationsComponent } from './ui/pages/notifications/notifications.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NotificationsComponent,
  },
  { path: 'open/:id', component: NotificationsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class NotificationsRouting {}
