import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  SAVE_USER_LATEST_LOGIN_REDIRECT_COMMAND,
  SavesUserLatestLoginRedirectCommandPort,
} from '../../application/ports/primary/saves-user-latest-login-redirect.command-port';

@Injectable()
export class SaveUserLatestLoginRedirectResolver  {
  constructor(
    @Inject(SAVE_USER_LATEST_LOGIN_REDIRECT_COMMAND)
    private readonly _savesUserLatestLoginRedirectCommand: SavesUserLatestLoginRedirectCommandPort,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const redirectUrl: string | undefined = route.data?.redirectUrl;

    this._savesUserLatestLoginRedirectCommand.saveUserLatestLoginRedirect({ redirectUrl });
  }
}
