/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_WORKSPACE_COMMAND,
  SetsSelectedWorkspaceCommandPort,
  WORKSPACE_ID_GETTER,
  WorkspaceIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { TeamSelectedEvent } from '@app.cobiro.com/core/events';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { GetsSelectedWorkspaceQueryPort } from '../ports/primary/gets-selected-workspace.query-port';
import { GetsWorkspacesQuery } from '../ports/primary/gets-workspaces.query';
import { IncrementsWorkspaceMembersCountCommandPort } from '../ports/primary/increments-workspace-members-count-command.port';
import { LoadsWorkspacesQueryPort } from '../ports/primary/loads-workspaces.query.port';
import { SelectedWorkspaceQuery } from '../ports/primary/selected-workspace.query';
import { SetsWorkspacesCommandPort } from '../ports/primary/sets-workspaces-command.port';
import { WorkspaceQuery } from '../ports/primary/workspace.query';
import {
  GETS_ALL_WORKSPACES_DTO,
  GetsAllWorkspacesDtoPort,
} from '../ports/secondary/gets-all-workspaces.dto-port';
import { WorkspaceDto } from '../ports/secondary/workspace.dto';

@Injectable()
export class WorkspaceSwitcherState
  implements
    GetsSelectedWorkspaceQueryPort,
    SetsWorkspacesCommandPort,
    GetsWorkspacesQuery,
    IncrementsWorkspaceMembersCountCommandPort,
    LoadsWorkspacesQueryPort
{
  private readonly _allWorkspaces$ = new BehaviorSubject<WorkspaceDto[]>([]);
  private readonly _workspacesMembersCountMap$ = new BehaviorSubject<Map<string, number>>(null);

  constructor(
    @Inject(GETS_ALL_WORKSPACES_DTO)
    private readonly _getAllWorkspacesDto: GetsAllWorkspacesDtoPort,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQueryPort: GetsCobiroProContextQueryPort,
    @Inject(APPLICATION_BUS) readonly _applicationBus: ApplicationBus,
    @Inject(SETS_SELECTED_WORKSPACE_COMMAND)
    private readonly _setsSelectedWorkspaceCommandPort: SetsSelectedWorkspaceCommandPort,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
  ) {}

  loadWorkspaces(teamId: string): Observable<boolean> {
    return this._getAllWorkspacesDto.getsAll(teamId).pipe(
      switchMap(dtos => {
        this.setWorkspaces(dtos);
        if (!this._workspaceIdGetter.getWorkspaceId() && dtos.length > 0) {
          const selectedWorkspace = dtos.at(0);
          return this._setsSelectedWorkspaceCommandPort
            .setSelectedWorkspace(
              selectedWorkspace.id,
              selectedWorkspace.name,
              selectedWorkspace.avatar,
              selectedWorkspace.membersCount,
            )
            .pipe(take(1));
        }
        return of(true);
      }),
    );
  }

  getSelectedWorkspace(): Observable<SelectedWorkspaceQuery> {
    return combineLatest([
      this._getsCobiroProContextQueryPort.getContext(),
      this._allWorkspaces$.asObservable(),
    ]).pipe(
      switchMap(([context, dtos]) => {
        const selectedWorkspace = dtos.find(dto => dto.id === context.selectedWorkspaceId);
        // if (!selectedWorkspace && dtos.length > 0) {
        //   selectedWorkspace = dtos.at(0);
        //   return this._setsSelectedWorkspaceCommandPort
        //     .setSelectedWorkspace(
        //       selectedWorkspace.id,
        //       selectedWorkspace.name,
        //       selectedWorkspace.avatar,
        //       selectedWorkspace.membersCount,
        //     )
        //     .pipe(
        //       take(1),
        //       map(() => selectedWorkspace),
        //     );
        // }
        return of(selectedWorkspace);
      }),
      filter(Boolean),
      map(selectedWorkspaceDto => {
        return new SelectedWorkspaceQuery(
          selectedWorkspaceDto.name,
          selectedWorkspaceDto.membersCount,
        );
      }),
    );
  }

  setWorkspaces(workspaces: WorkspaceDto[]): void {
    this._allWorkspaces$.next(workspaces);
    this._workspacesMembersCountMap$.next(
      new Map(workspaces.map(workspace => [workspace.id, workspace.membersCount])),
    );
  }

  getWorkspacesQuery(): Observable<WorkspaceQuery[] | null> {
    return combineLatest([
      this._allWorkspaces$.asObservable(),
      this._workspacesMembersCountMap$.asObservable(),
    ]).pipe(
      map(([workspaces, workspacesMembersCount]) => {
        return workspaces && workspacesMembersCount && this._adaptToQuery(workspaces);
      }),
    );
  }

  incrementWorkspaceMembersCount(workspaceId: string): Observable<void> {
    return this._workspacesMembersCountMap$.pipe(
      take(1),
      tap((workspaceMembersCountMap: Map<string, number>) => {
        workspaceMembersCountMap.set(workspaceId, workspaceMembersCountMap.get(workspaceId) + 1);
        this._workspacesMembersCountMap$.next(new Map(workspaceMembersCountMap.entries()));
      }),
      map(() => void 0),
    );
  }

  private _adaptToQuery(workspaces: WorkspaceDto[]): WorkspaceQuery[] {
    return workspaces.map(
      ({ id, name, avatar, membersCount }) => new WorkspaceQuery(id, name, avatar, membersCount),
    );
  }
}
