import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectsClientCommand } from './selects-client.command';

export const SELECTS_CLIENT_COMMAND = new InjectionToken<SelectsClientCommandPort>(
  'SELECTS_CLIENT_COMMAND',
);

export interface SelectsClientCommandPort {
  selectClient(command: SelectsClientCommand): Observable<void>;
}
