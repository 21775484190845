import { Inject, Injectable, Injector } from '@angular/core';
import { PAYMENT_HANDLERS, PaymentHandler } from '../../domain/payment-handler';
import { ComponentType } from '@angular/cdk/overlay';

class PaymentResolver {
  constructor(private _handler: PaymentHandler, private _nextHandler: PaymentResolver) {}

  handle(): ComponentType<unknown> {
    return this._handler.conditionFn() ? this._handler.component : this._nextHandler.handle();
  }
}

@Injectable()
export class PaymentComponentFactory {
  constructor(private _injector: Injector) {}

  build(): ComponentType<unknown> {
    return {} as any;
    // const handlers: PaymentHandler[] = this._injector.get<PaymentHandler[]>(PAYMENT_HANDLERS, []);
    // const resolver: PaymentResolver = handlers.reduce(
    //   (previousResolver, handler) => new PaymentResolver(handler, previousResolver),
    //   new PaymentResolver(this._defaultHandler, null),
    // );
    //
    // return resolver.handle();
  }
}
