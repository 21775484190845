<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  style="display: none"
>
  <defs>
    <g id="icon-home-hollow" transform="translate(-0.61 -1.591)">
      <path
        id="Path_15402"
        data-name="Path 15402"
        d="M1.458,9.618l7.189-7a1.165,1.165,0,0,1,1.617.005l7.195,7"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15403"
        data-name="Path 15403"
        d="M15.887,9.172v8.9H4.458v-8.9"
        transform="translate(-0.714 -1.777)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15404"
        data-name="Path 15404"
        d="M13.529,19.233V15.9a2.287,2.287,0,0,0-4.571,0v3.337"
        transform="translate(-1.786 -2.941)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-builder" transform="translate(-0.9 -1.65)">
      <rect
        id="Rectangle_21841"
        data-name="Rectangle 21841"
        width="16"
        height="16"
        rx="1.5"
        transform="translate(1.5 2.25)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_825"
        data-name="Line 825"
        x2="16"
        transform="translate(1.5 5.942)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_826"
        data-name="Line 826"
        y2="12.308"
        transform="translate(7.214 5.942)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_827"
        data-name="Line 827"
        x2="1.143"
        transform="translate(3.786 8.404)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_828"
        data-name="Line 828"
        x2="1.143"
        transform="translate(3.786 10.865)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_829"
        data-name="Line 829"
        x2="1.143"
        transform="translate(3.786 13.327)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_830"
        data-name="Line 830"
        x2="1.143"
        transform="translate(3.786 15.788)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <rect
        id="Rectangle_21842"
        data-name="Rectangle 21842"
        width="5.714"
        height="7.385"
        rx="0.75"
        transform="translate(9.5 8.404)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_831"
        data-name="Line 831"
        x2="5.714"
        transform="translate(9.5 12.096)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_832"
        data-name="Line 832"
        y2="3.692"
        transform="translate(11.786 12.096)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-ecommerce" transform="translate(-0.904 -0.15)">
      <path
        id="Path_15387"
        data-name="Path 15387"
        d="M14.884,6.75H4.126a1.053,1.053,0,0,0-1.1.823L1.506,17.467a.93.93,0,0,0,.3.737,1.077,1.077,0,0,0,.792.278H16.408A1.076,1.076,0,0,0,17.2,18.2a.929.929,0,0,0,.3-.736L15.978,7.572A1.048,1.048,0,0,0,14.884,6.75Z"
        transform="translate(0 -1.733)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15388"
        data-name="Path 15388"
        d="M15.03,4.52C14.72,2.338,13.309.757,11.664.75h0C10.024.756,8.616,2.324,8.3,4.5"
        transform="translate(-2.161)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15389"
        data-name="Path 15389"
        d="M13.925,12.675a1.522,1.522,0,0,0-2.1,0l-.9.867-.9-.867a1.522,1.522,0,0,0-2.1,0,1.394,1.394,0,0,0,0,2.023l2.677,2.582a.463.463,0,0,0,.638,0L13.925,14.7a1.394,1.394,0,0,0,0-2.023Z"
        transform="translate(-1.425 -3.325)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-domain" transform="translate(-0.15 -0.15)">
      <circle
        id="Ellipse_2102"
        data-name="Ellipse 2102"
        cx="8"
        cy="8"
        r="8"
        transform="translate(0.75 0.75)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15390"
        data-name="Path 15390"
        d="M8.556,16.611C7.473,15.024,6.75,12.14,6.75,8.845s.723-6.179,1.806-7.766"
        transform="translate(-1.733 -0.095)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_833"
        data-name="Line 833"
        x2="16"
        transform="translate(0.75 8.75)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_834"
        data-name="Line 834"
        x2="13.537"
        transform="translate(1.982 13.017)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_835"
        data-name="Line 835"
        x2="13.537"
        transform="translate(1.982 4.483)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15391"
        data-name="Path 15391"
        d="M14.711,1.079c1.082,1.587,1.806,4.471,1.806,7.766s-.723,6.179-1.806,7.766"
        transform="translate(-4.033 -0.095)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-git" transform="translate(-0.15 -0.15)">
      <circle
        id="Ellipse_2106"
        data-name="Ellipse 2106"
        cx="1.067"
        cy="1.067"
        r="1.067"
        transform="translate(11.419 10.885)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15400"
        data-name="Path 15400"
        d="M16.425,10.425l.314,1.033a.707.707,0,0,0,.835.484l1.047-.243a.952.952,0,0,1,.907,1.578l-.733.79a.711.711,0,0,0,0,.969l.733.79a.952.952,0,0,1-.907,1.578l-1.047-.243a.707.707,0,0,0-.835.484l-.314,1.029a.946.946,0,0,1-1.812,0L14.3,17.642a.707.707,0,0,0-.835-.484l-1.047.243a.952.952,0,0,1-.907-1.578l.733-.79a.711.711,0,0,0,0-.969l-.733-.79a.952.952,0,0,1,.907-1.578l1.047.243a.707.707,0,0,0,.835-.484l.314-1.033a.946.946,0,0,1,1.812,0Z"
        transform="translate(-3.033 -2.598)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15401"
        data-name="Path 15401"
        d="M6.084,14.62H1.817A1.067,1.067,0,0,1,.75,13.553V1.817A1.067,1.067,0,0,1,1.817.75h7.56a1.067,1.067,0,0,1,.754.312L12.173,3.1a1.067,1.067,0,0,1,.312.754V5.018"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-gmb" transform="translate(-0.15 -0.15)">
      <path
        id="Path_15396"
        data-name="Path 15396"
        d="M16.117,12.75v6.4a1.067,1.067,0,0,1-1.067,1.067H3.317A1.067,1.067,0,0,1,2.25,19.15v-6.4"
        transform="translate(-0.433 -3.467)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_15397"
        data-name="Path 15397"
        d="M15.255.75H2.245a.534.534,0,0,0-.521.418L.75,5.55a1.6,1.6,0,0,0,3.2,0,1.6,1.6,0,0,0,3.2,0,1.6,1.6,0,1,0,3.2,0,1.6,1.6,0,0,0,3.2,0,1.6,1.6,0,1,0,3.2,0l-.974-4.383A.533.533,0,0,0,15.255.75Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_837"
        data-name="Line 837"
        x2="9.067"
        transform="translate(1.817 12.483)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_838"
        data-name="Line 838"
        y1="7.467"
        transform="translate(10.883 9.283)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-google-search" data-name="Group 40056" transform="translate(-0.802 0.573)">
      <path
        id="Path_10237"
        data-name="Path 10237"
        d="M83.454,1.874A2.891,2.891,0,0,1,84.137.791a2.675,2.675,0,0,1,4.208.625c.644,1.173,1.327,2.32,1.991,3.48,1.108,1.927,2.23,3.853,3.325,5.786A2.664,2.664,0,1,1,89,13.266q-1.459-2.542-2.925-5.078a.584.584,0,0,0-.071-.1,1.048,1.048,0,0,1-.213-.316c-.432-.76-.876-1.514-1.308-2.268-.277-.49-.567-.973-.844-1.463a2.668,2.668,0,0,1-.354-1.418,1.975,1.975,0,0,1,.168-.747"
        transform="translate(-76.607 0)"
        fill="none"
        stroke="#464646"
        stroke-width="1.2"
      />
      <path
        id="Path_10238"
        data-name="Path 10238"
        d="M16.2,28.6a3.706,3.706,0,0,0-.122.709,2.784,2.784,0,0,0,.387,1.514q1.063,1.827,2.12,3.666c.064.11.116.219.18.322-.387.67-.773,1.334-1.166,2-.541.934-1.083,1.875-1.63,2.809-.026,0-.032-.013-.039-.032a.4.4,0,0,1,.026-.148,2.593,2.593,0,0,0-.619-2.558,2.456,2.456,0,0,0-1.514-.78,2.615,2.615,0,0,0-2.068.573c-.11.084-.18.206-.309.271a.043.043,0,0,1-.045-.032c.309-.535.612-1.07.921-1.6q1.914-3.325,3.834-6.643c.013-.026.032-.045.045-.071"
        transform="translate(-9.354 -26.726)"
        fill="none"
        stroke="#464646"
        stroke-width="1.2"
      />
      <path
        id="Path_10239"
        data-name="Path 10239"
        d="M2.072,144.986c.122-.11.238-.226.367-.329A2.663,2.663,0,0,1,6.7,146.275a2.832,2.832,0,0,1-.1,1.372.674.674,0,0,1-.026.11c-.058.1-.11.213-.174.316A2.591,2.591,0,0,1,3.87,149.42a2.645,2.645,0,0,1-2.442-2.307,2.578,2.578,0,0,1,.354-1.714c.064-.116.142-.219.213-.335.032-.026.019-.077.077-.077"
        transform="translate(0 -134.775)"
        fill="none"
        stroke="#464646"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-google-css" transform="translate(-1.275 -0.9)">
      <path
        id="Path_15399"
        data-name="Path 15399"
        d="M17.875,9.5a2.668,2.668,0,0,0-1.862-2.543,2.667,2.667,0,0,0-3.6-3.6,2.667,2.667,0,0,0-5.085,0,2.667,2.667,0,0,0-3.6,3.6,2.668,2.668,0,0,0,0,5.086,2.667,2.667,0,0,0,3.6,3.6,2.667,2.667,0,0,0,5.085,0,2.668,2.668,0,0,0,3.6-3.6A2.667,2.667,0,0,0,17.875,9.5Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <line
        id="Line_839"
        data-name="Line 839"
        y1="5.714"
        x2="5.926"
        transform="translate(7.208 6.643)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <ellipse
        id="Ellipse_2104"
        data-name="Ellipse 2104"
        cx="1.185"
        cy="1.143"
        rx="1.185"
        ry="1.143"
        transform="translate(6.616 6.072)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <ellipse
        id="Ellipse_2105"
        data-name="Ellipse 2105"
        cx="1.185"
        cy="1.143"
        rx="1.185"
        ry="1.143"
        transform="translate(11.356 10.643)"
        fill="none"
        stroke="#464646"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>

    <g id="icon-zapier">
      <path
        id="Zapier_logo_2_"
        data-name="Zapier_logo (2)"
        d="M283.518,8.284a4.79,4.79,0,0,1-.308,1.689,4.8,4.8,0,0,1-1.689.307h-.007a4.792,4.792,0,0,1-1.69-.308,4.785,4.785,0,0,1-.307-1.689V8.276a4.79,4.79,0,0,1,.307-1.689,4.781,4.781,0,0,1,1.69-.307h.007a4.773,4.773,0,0,1,1.689.307,4.782,4.782,0,0,1,.307,1.689v.008h0Zm5.889-1.337h-4.67l3.3-3.3a8.032,8.032,0,0,0-1.886-1.885l-3.3,3.3V.391A8.038,8.038,0,0,0,281.522.28h-.009a8.013,8.013,0,0,0-1.329.111v4.67l-3.3-3.3a7.988,7.988,0,0,0-1.021.864l0,0A8.053,8.053,0,0,0,275,3.645l3.3,3.3h-4.671s-.111.877-.111,1.331v.006a8,8,0,0,0,.111,1.331H278.3l-3.3,3.3a8.056,8.056,0,0,0,1.886,1.886l3.3-3.3v4.67a8.074,8.074,0,0,0,1.327.111h.011a8.072,8.072,0,0,0,1.327-.111V11.5l3.3,3.3a8.06,8.06,0,0,0,1.021-.864h0a8.08,8.08,0,0,0,.863-1.021l-3.3-3.3h4.671a8.031,8.031,0,0,0,.111-1.328V8.274a8.041,8.041,0,0,0-.111-1.327h0Z"
        transform="translate(-273.018 0.22)"
        fill="none"
        stroke="#000"
        stroke-width="1"
      />
    </g>
  </defs>
</svg>
