import { VisitedClientDto } from '../secondary/visited-client.dto';

export class VisitedClientQuery {
  constructor(
    readonly clientId: string,
    readonly name: string,
    readonly avatar: string | null,
    readonly sitePublicId: string,
  ) {}

  static fromDto(dto: VisitedClientDto) {
    return new VisitedClientQuery(dto.id, dto.name, dto.avatar, dto.sitePublicId);
  }
}
