import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { NotificationsRouting } from './notifications-routing';
import { NotificationsComponent } from './ui/pages/notifications/notifications.component';
import { MessageNotificationModalComponent } from './ui/message-notification-modal/message-notification-modal.component';
import { NotificationListElementComponent } from './ui/notification-list-element/notification-list-element.component';
import { NotificationsWidgetComponent } from './ui/notifications-widget/notifications-widget.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    LanguageModule,
    HttpClientModule,
    MatDialogModule,
    NotificationsRouting,
    SharedAnalyticsModule,
    MatIconModule,
  ],
  providers: [{ provide: DOCUMENT, useValue: document }],
  declarations: [
    NotificationsWidgetComponent,
    NotificationListElementComponent,
    NotificationsComponent,
    MessageNotificationModalComponent,
  ],
  exports: [NotificationsWidgetComponent],
})
export class NotificationsModule {}
