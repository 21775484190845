import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HUB_URL } from '@app.cobiro.com/common/hub';
import { GetsCountries } from '../domain/gets-countries';
import { Country } from '../domain/country';
import { HttpClient } from '@angular/common/http';
import { HasDataCollection } from '@cobiro/jsonapi';

export interface CountryAttributes {
  code: string;
  code3: string;
  isoCode: string;
  numericCode: string;
  geonamesCode: number;
  fipsCode: string;
  area: number;
  currency: string;
  phonePrefix: string;
  mobileFormat: string;
  landlineFormat: string;
  trunkPrefix: string;
  population: number;
  continent: string;
  language: string;
  name: string;
}

@Injectable()
export class HttpGetsCountriesService implements GetsCountries {
  constructor(private _client: HttpClient, @Inject(HUB_URL) private _baseUrl: string) {}

  getAll(): Observable<Country[]> {
    return this._client
      .get<HasDataCollection<CountryAttributes>>(this._baseUrl + '/v1/google-locations/countries')
      .pipe(map(response => response.data.map(item => this._mapToCountry(item.attributes))));
  }

  private _mapToCountry(countryAttributes: CountryAttributes): Country {
    return {
      code: countryAttributes.code,
      name: countryAttributes.name,
      code3: countryAttributes.code3,
      isoCode: countryAttributes.code3,
      numericCode: countryAttributes.numericCode,
      geonamesCode: countryAttributes.geonamesCode,
      fipsCode: countryAttributes.fipsCode,
      area: countryAttributes.area,
      currency: countryAttributes.currency,
      phonePrefix: countryAttributes.phonePrefix,
      mobileFormat: countryAttributes.mobileFormat,
      landlineFormat: countryAttributes.landlineFormat,
      trunkPrefix: countryAttributes.trunkPrefix,
      population: countryAttributes.population,
      continent: countryAttributes.continent,
      language: countryAttributes.language,
    };
  }
}
