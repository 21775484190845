import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RumResolver } from './rum.resolver';
import { ENV_NAME } from '../../../../../cobiro-legacy/src/lib/app.config';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { provideWindowConfig } from '../../../../utils/src/lib/config/window-config.service';

const config = provideWindowConfig();

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    RumResolver,
    { provide: WINDOW, useValue: window },
    { provide: ENV_NAME, useValue: config.name },
  ],
})
export class DatadogModule {}
