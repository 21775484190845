/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Inject, Injectable } from '@angular/core';

import { STORAGE } from '@app.cobiro.com/core/storage';
import { combineLatest, map, Observable, take } from 'rxjs';
import {
  GETS_INTEGRATION_ERROR_QUERY,
  GetsIntegrationErrorQueryPort,
} from '../../../application/ports/primary/gets-integration-error.query-port';
import { IntegrationErrorQuery } from '../../../application/ports/primary/integration-error.query';
import {
  GETS_INTEGRATION_DTO,
  GetsIntegrationDtoPort,
} from '../../../application/ports/secondary/gets-integration.dto-port';
import { IntegrationInfo } from '../../../application/ports/secondary/integration-info.dto';
import { IntegrationDto } from '../../../application/ports/secondary/integration.dto';

@Injectable()
export class LoadIntegrationResolver  {
  constructor(
    @Inject(GETS_INTEGRATION_DTO)
    private readonly _getsIntegrationDtoPort: GetsIntegrationDtoPort,
    @Inject(GETS_INTEGRATION_ERROR_QUERY)
    private readonly _getsIntegrationErrorQuery: GetsIntegrationErrorQueryPort,
    @Inject(STORAGE)
    private readonly _storage: Storage,
  ) {}

  resolve(): Observable<IntegrationInfo> {
    const siteId = String(this._storage.getItem('cobiro-pro-current-client'));
    return combineLatest([
      this._getsIntegrationDtoPort.getIntegration(siteId),
      this._getsIntegrationErrorQuery.getIntegrationErrorQuery(),
    ]).pipe(
      take(1),
      map(([integration, errors]: [IntegrationDto, IntegrationErrorQuery]) => ({
        id: integration.integrationId,
        type: integration.integrationType,
        isErrors:
          errors !== null || (integration.status !== 'success' && integration.status !== 'missing'),
      })),
    );
  }
}
