import { Inject, Injectable } from '@angular/core';

import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { CobiroProEnterDashboardEvent } from '@app.cobiro.com/core/events';
import {
  LoadTeamsCommandPort,
  LOAD_TEAMS_COMMAND,
} from '../../../application/ports/primary/load-teams-command.port';
import { Observable, take, tap } from 'rxjs';
import { TeamDTO } from '../../../application/ports/secondary/team.dto';

@Injectable()
export class LoadTeamsResolver  {
  constructor(
    @Inject(LOAD_TEAMS_COMMAND) private readonly _loadTeams: LoadTeamsCommandPort,
    @Inject(APPLICATION_BUS) private readonly _dispatcher: Dispatcher<CobiroProEnterDashboardEvent>,
  ) {}

  resolve(): Observable<TeamDTO[]> {
    return this._loadTeams.loadAll().pipe(
      take(1),
      tap(() => this._dispatcher.dispatch(new CobiroProEnterDashboardEvent())),
    );
  }
}
