import { FeaturesLoadedEvent } from '../events/features-loaded.event';
import { Injectable } from '@angular/core';
import { FeaturesState } from '../application/features.state';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class FeaturesLoadedEventHandler implements ApplicationEventHandler {
  eventClass = FeaturesLoadedEvent;
  constructor(private _featuresState: FeaturesState) {}
  handle(event: FeaturesLoadedEvent) {
    this._featuresState.setFeatures(event.features);
  }
}
