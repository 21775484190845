import { WorkspaceDto } from '../secondary/workspace.dto';

export class WorkspaceQuery {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly avatar: string,
    public readonly membersCount: number,
  ) {}

  static fromDTO(dto: WorkspaceDto): WorkspaceQuery {
    return new WorkspaceQuery(dto.id, dto.name, dto.avatar, dto.membersCount);
  }
}
