import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { LinksGoogleAdsLinkingDto } from '../../../../application/ports/secondary/links-google-ads-account.dto';
import { LinksGoogleAdsAccountDtoPort } from '../../../../application/ports/secondary/links-google-ads-account.dto-port';
import { GetsGoogleAdsLinkingStatusDto } from '../../../../application/ports/secondary/gets-google-ads-linking-status.dto';
import { GetsGoogleAdsLinkingStatusDtoPort } from '../../../../application/ports/secondary/gets-google-ads-linking-status.dto-port';
import { UUID } from '@app.cobiro.com/core/events';
import { HasData } from '@cobiro/jsonapi';
import { CreatesNonCssIntegrationDtoPort } from '../../../../application/ports/secondary/creates-non-css-integration.dto-port';
import { CreatesNonCssIntegrationDto } from '../../../../application/ports/secondary/creates-non-css-integration.dto';
import { IntegrationInfoQuery } from '../../../../application/ports/primary/integration-info.query';
import { GetsIntegrationDtoPort } from '../../../../application/ports/secondary/gets-integration.dto-port';

export interface GoogleAdsLinkingStatusModelAttributes {
  isLinked: boolean;
}

export interface GoogleAdsLinkingStatusModel {
  id: string;
  type: string;
  attributes: GoogleAdsLinkingStatusModelAttributes;
}

@Injectable()
export class HttpComlynIntegrationService
  implements
    GetsGoogleAdsLinkingStatusDtoPort,
    LinksGoogleAdsAccountDtoPort,
    CreatesNonCssIntegrationDtoPort,
    GetsIntegrationDtoPort
{
  constructor(private readonly _client: GatewayClient) {}

  getIntegration(siteId: string): Observable<IntegrationInfoQuery> {
    return this._client
      .get<
        HasData<{
          integrationId: string;
          status: 'pending' | 'success' | 'missing';
          integrationType: 'owned' | 'switched' | 'non-css';
        }>
      >(`v1/google-css/comlyn-integration/${siteId}`)
      .pipe(
        map(response => ({
          id: response.data.attributes.integrationId,
          type: response.data.attributes.integrationType,
          isErrors:
            response.data.attributes.status !== 'success' &&
            response.data.attributes.status !== 'missing',
        })),
      );
  }

  createIntegration(dto: CreatesNonCssIntegrationDto): Observable<boolean> {
    const body: HasData<CreatesNonCssIntegrationDto> = {
      data: {
        type: 'non-css-integration',
        attributes: {
          siteId: dto.siteId,
          adsId: dto.adsId,
          websiteName: dto.websiteName,
          managerEmail: dto.managerEmail,
          merchantId: dto.merchantId,
        },
      },
    };
    return this._client.post(`v1/google-css/non-css-integration`, body).pipe(map(() => true));
  }

  getStatus(dto: GetsGoogleAdsLinkingStatusDto): Observable<boolean> {
    return this._client
      .get(`v1/google-css/comlyn-integration/${dto.siteId}/ads-account-link-status`)
      .pipe(map(({ data }: { data: GoogleAdsLinkingStatusModel }) => data.attributes.isLinked));
  }

  linkGoogleAdsAccount(dto: LinksGoogleAdsLinkingDto): Observable<boolean> {
    return this._client
      .post(`v1/google-css/comlyn-integration/${dto.siteId}/link-ads-account`, {})
      .pipe(map(() => true));
  }
}
