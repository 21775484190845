<ng-container *ngIf="isManagerVisible$ | async">
  <div
    data-selector="cobiro-pro-clients-history-manager"
    class="cs-bl-1 cs-br-1 cs-min-width-160-xs cs-b-shade-10 h-100 d-flex align-items-center cs-p-10 cs-cursor-pointer"
  >
    <button
      *ngIf="selectedClient$ | async"
      data-selector="cobiro-pro-back-btn"
      class="cs-btn cs-btn-ghost cs-min-width-25-xs cs-min-height-32-xs cs-p-0"
      [matTooltip]="'cbr_pro_back_to_workspace_btn_tooltip' | language"
      (click)="onBackButtonClicked()"
    >
      <mat-icon svgIcon="chevron-left" class="cs-ml-5 mx-auto"></mat-icon>
    </button>

    <!--  TODO: Make it centered, probably will require a hack  -->
    <mat-menu
      #menu="matMenu"
      xPosition="after"
      yPosition="below"
      [overlapTrigger]="false"
      backdropClass="cs-mat-menu-backdrop"
      class="cs-mat-menu"
    >
      <div
        class="d-flex justify-content-start align-items-center cs-min-width-200-xs cs-p-5 cs-btn cs-btn-ghost"
        *ngFor="let visitedClient of visitedClients$ | async"
        (click)="changeClient(visitedClient.clientId)"
      >
        <hui-avatar
          size="small"
          [avatarUrl]="visitedClient.avatar"
          [name]="visitedClient.name"
        ></hui-avatar>
        <p class="cs-text-2 cs-c-shade-60 cs-ml-10">{{ visitedClient.name }}</p>
      </div>
    </mat-menu>

    <div
      class="d-flex align-items-center justify-content-center cs-bg-hover-shade-4 cs-transition-regular-ease-out cs-p-5 br-5"
      [matMenuTriggerFor]="menu"
      (menuOpened)="isMenuOpened = true"
      (menuClosed)="isMenuOpened = false"
      [class.cs-bg-shade-4]="isMenuOpened"
    >
      <ng-container *ngIf="selectedClient$ | async as selectedClient; else noSelectedClient">
        <div class="d-flex align-items-center cs-ml-10">
          <hui-avatar
            [avatarUrl]="selectedClient.avatar"
            [name]="selectedClient.clientName"
          ></hui-avatar>
          <p class="cs-text-2 cs-ml-10">{{ selectedClient.clientName }}</p>
        </div>
      </ng-container>
      <ng-template #noSelectedClient>
        <p class="cs-ml-5 cs-text-2 cs-c-shade-60">
          {{ 'cbr_pro_clients_history_recently_visited' | language }}
        </p>
      </ng-template>
      <mat-icon svgIcon="chevron-down" class="cs-ml-10"></mat-icon>
    </div>
  </div>
</ng-container>
