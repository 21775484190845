import { Inject, Injectable } from '@angular/core';
import { GetsSelectedClientDtoPort } from '../../application/ports/secondary/gets-selected-client.dto-port';
import { SetsSelectedClientDtoPort } from '../../application/ports/secondary/sets-selected-client.dto-port';
import { STORAGE } from '@app.cobiro.com/core/storage';

@Injectable()
export class LocalStorageSelectedClientService
  implements GetsSelectedClientDtoPort, SetsSelectedClientDtoPort
{
  private readonly _storageKey = 'cobiro-pro-current-client';
  constructor(@Inject(STORAGE) private readonly _storage: Storage) {}

  getSelectedClient(): string {
    return this._storage.getItem(this._storageKey);
  }

  setSelectedClient(clientId: string): void {
    this._storage.setItem(this._storageKey, clientId);
  }
}
