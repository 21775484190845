import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { ClientsHistoryManagerComponent } from './clients-history-manager.component';

@NgModule({
  declarations: [ClientsHistoryManagerComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    LanguageModule,
    MatIconModule,
    MatMenuModule,
    HuiAvatarModule,
  ],
  exports: [ClientsHistoryManagerComponent],
})
export class ClientsHistoryManagerComponentModule {}
