import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationInfoQuery } from '../primary/integration-info.query';

export const GETS_INTEGRATION_DTO = new InjectionToken<GetsIntegrationDtoPort>(
  'GETS_INTEGRATION_DTO',
);

export interface GetsIntegrationDtoPort {
  getIntegration(siteId: string): Observable<IntegrationInfoQuery>;
}
