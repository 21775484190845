import { VisitedClientDto } from './visited-client.dto';
import { InjectionToken } from '@angular/core';

export interface ManagesVisitedClientsDtoPort {
  setClients(dtos: VisitedClientDto[]): void;
  getClients(): VisitedClientDto[];
  clear(): void;
}

export const MANAGES_VISITED_CLIENTS_DTO_PORT = new InjectionToken<ManagesVisitedClientsDtoPort>(
  'MANAGES_VISITED_CLIENTS_DTO_PORT',
);
