import { UserDetailQueryPartial } from '../primary/user-details.query';

export type UserDetailDtoPartial = Partial<
  Pick<UserDetailDto, 'first_name' | 'last_name' | 'avatar_url' | 'phone_number' | 'country'>
>;

export class UserDetailDto {
  constructor(
    readonly weekly_summary: boolean,
    readonly email: string,
    readonly first_name: string,
    readonly last_name: string,
    readonly country: string,
    readonly phone_number: string,
    readonly avatar_url: string,
  ) {}

  static fromQueryPartialToDtoPartial(query: UserDetailQueryPartial): UserDetailDtoPartial {
    return Object.keys(query).reduce((acc, key) => {
      acc[camelCaseToSnakeCase(key)] = query[key];
      return acc;
    }, {});
  }
}

export const camelCaseToSnakeCase = (phrase: string): string => {
  const index = [...phrase].findIndex(p => p.charAt(0) === p.charAt(0).toUpperCase());

  if (index < 0) {
    return phrase;
  }

  const first = phrase.slice(0, index);
  const rest = phrase.slice(index);
  const slicedPhrase = rest.charAt(0).toLowerCase() + rest.slice(1);

  return first + '_' + camelCaseToSnakeCase(slicedPhrase);
};
