import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsDetails, UserDetails } from '../domain/gets-details';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken } from '@app.cobiro.com/user/core';

export interface UserDetailsAttributes {
  email: string;
  partnerId: number;
  firstName: string;
  lastName: string;
  group: string;
  createdAt: number;
  avatarUrl: string;
}

@Injectable()
export class HttpGetsDetailsService implements GetsDetails {
  constructor(
    private _client: GatewayClient,
    @Inject(GETS_USER_FROM_TOKEN) private _getsUser: GetsUserFromToken,
  ) {}

  get(): Observable<UserDetails> {
    const user = this._getsUser.get();

    if (!user) {
      throw new Error('No user id');
    }

    return this._client.get<HasData<UserDetailsAttributes>>(`v2/users/${user.userId}/details`).pipe(
      map(response => ({
        email: response.data.attributes.email,
        firstName: response.data.attributes.firstName,
        lastName: response.data.attributes.lastName,
        group: response.data.attributes.group,
        partnerId: response.data.attributes.partnerId,
        createdAt: response.data.attributes.createdAt,
        avatar: response.data.attributes.avatarUrl,
      })),
    );
  }
}
