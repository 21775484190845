import { UserVerifiedAccountEvent } from '@app.cobiro.com/core/events';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationEventHandler } from '@cobiro/eda';
import { UserState } from '../application/user.state';

@Injectable()
export class UserVerifiedAccountEventHandler implements ApplicationEventHandler {
  eventClass = UserVerifiedAccountEvent;
  constructor(private _userState: UserState, private _router: Router) {}
  handle(event: UserVerifiedAccountEvent): void {
    this._userState.login(event.email, event.password).subscribe(() => {
      this._router.navigate(['/onboarding']);
    });
  }
}
