/* eslint-disable max-lines-per-function */
import { LabelManagerReportDto } from '../secondary/label-manager-report.dto';

export interface ChartSerie {
  data: number[];
}
export interface LabelManagerReportDateData {
  date: Date | null;
  totalProducts: number;
  chartSeries: ChartSerie[];
  dataLabels: string[];
}

export class LabelManagerReportQuery {
  constructor(
    public readonly id: string,
    public readonly data: LabelManagerReportDateData[],
    public readonly status: string,
    public readonly error: null | { code: string },
  ) {}

  static fromDto(dto: LabelManagerReportDto): LabelManagerReportQuery {
    const offsetMinutes = new Date().getTimezoneOffset();
    return new LabelManagerReportQuery(
      dto.id,
      dto.data
        .filter(data => data.date !== null)
        .map(current => {
          const chartSeries = [
            {
              data: Object.values(current.labels).map(i => i.count),
            },
            {
              data: Object.values(current.labels).map(i => current.totalProducts - i.count),
            },
          ];
          const date = new Date(current.date);
          return {
            date: current.date ? new Date(date.getTime() - offsetMinutes * 60 * 1000) : null,
            totalProducts: current.totalProducts,
            chartSeries: chartSeries,
            dataLabels: Object.keys(current.labels),
          };
        }),
      dto.status,
      dto.error,
    );
  }
}
