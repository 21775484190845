import { NgModule } from '@angular/core';
import { CobiroProLayoutState } from './cobiro-pro-layout.state';
import { GETS_USER_DATA_QUERY } from '../ports/primary/gets-user-data.query-port';
import { SETS_USER_DATA_COMMAND } from '../ports/primary/sets-user-data.command-port';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { CobiroProLayoutUserChangedEventHandler } from '../../adapters/primary/event-handlers/cobiro-pro-layout-user-changed.event-handler';
import { SETS_HAS_UPGRADE_BUTTON_COMMAND } from '../ports/primary/sets-has-upgrade-button.command-port';
import { GETS_HAS_UPGRADE_BUTTON_QUERY } from '../ports/primary/gets-has-upgrade-button.query-port';
import { CobiroProLayoutHasUpgradeButtonEventHandler } from '../../adapters/primary/event-handlers/cobiro-pro-layout-has-upgrade-button.event-handler';
import { UPGRADES_PLAN_COMMAND } from '../ports/primary/upgrades-plan.command-port';
import { ApplicationBusCobiroProUpgradePlanRequestedDispatcherModule } from '../../adapters/secondary/dispatchers/application-bus-cobiro-pro-upgrade-plan-requested.dispatcher-module';

@NgModule({
  imports: [ApplicationBusCobiroProUpgradePlanRequestedDispatcherModule],
  providers: [
    CobiroProLayoutState,
    { provide: GETS_USER_DATA_QUERY, useExisting: CobiroProLayoutState },
    { provide: GETS_HAS_UPGRADE_BUTTON_QUERY, useExisting: CobiroProLayoutState },
    { provide: SETS_USER_DATA_COMMAND, useExisting: CobiroProLayoutState },
    { provide: SETS_HAS_UPGRADE_BUTTON_COMMAND, useExisting: CobiroProLayoutState },
    { provide: UPGRADES_PLAN_COMMAND, useExisting: CobiroProLayoutState },
    provideApplicationEventsHandler(CobiroProLayoutUserChangedEventHandler),
    provideApplicationEventsHandler(CobiroProLayoutHasUpgradeButtonEventHandler),
  ],
})
export class CobiroProLayoutStateModule {}
