import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  TEAM_ID_GETTER,
  TeamIdGetter,
  WORKSPACE_ID_GETTER,
  WorkspaceIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { PaymentFlowStartedEvent } from '@app.cobiro.com/core/events';
import { Stepper } from '@app.cobiro.com/core/state';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { HuiStepperComponent } from '@app.cobiro.com/shared/hui/stepper';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  Observable,
  of,
  Subject,
  switchMap,
  throwError,
} from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { ConfirmIntegrationCancelDialogComponent } from '../../adapters/primary/ui/confirm-integration-cancel-dialog/confirm-integration-cancel-dialog.component';
import { AppMetadataQuery } from '../ports/primary/app-metadata.query';
import { CancelsIntegrationCommand } from '../ports/primary/cancels-integration.command';
import { CancelsIntegrationCommandPort } from '../ports/primary/cancels-integration.command-port';
import { CreatesIntegrationCommand } from '../ports/primary/creates-integration.command';
import { CreatesIntegrationCommandPort } from '../ports/primary/creates-integration.command-port';
import { CssMerchantQuery } from '../ports/primary/css-merchant.query';
import { EditsIntegrationCommand } from '../ports/primary/edits-integration.command';
import { EditsIntegrationCommandPort } from '../ports/primary/edits-integration.command-port';
import { EntersIntegrationDetailsEditModeCommand } from '../ports/primary/enters-integration-details-edit-mode.command';
import { EntersIntegrationDetailsEditModeCommandPort } from '../ports/primary/enters-integration-details-edit-mode.command-port';
import { FormStatusQuery } from '../ports/primary/form-status.query';
import { GetsCobiroCssMetaQueryPort } from '../ports/primary/gets-cobiro-css-meta.query-port';
import { GetsCssMerchantIdsQueryPort } from '../ports/primary/gets-css-merchant-ids.query-port';
import { GetsFormErrorQueryPort } from '../ports/primary/gets-form-error.query-port';
import { GetsFormStatusQueryPort } from '../ports/primary/gets-form-status.query-port';
import { GetsInstalledAppsQueryPort } from '../ports/primary/gets-installed_apps.query-port';
import { GetsIntegrationErrorQueryPort } from '../ports/primary/gets-integration-error.query-port';
import { GetsIntegrationLinkStatusQueryPort } from '../ports/primary/gets-integration-link-status.query-port';
import { GetsIntegrationPauseStatusQueryPort } from '../ports/primary/gets-integration-pause-status.query-port';
import { GetsIntegrationStatusQueryPort } from '../ports/primary/gets-integration-status.query-port';
import { GetsIntegrationQueryPort } from '../ports/primary/gets-integration.query-port';
import { GetsOwnedIntegrationPanelQueryPort } from '../ports/primary/gets-owned-integration-panel.query-port';
import { GetsSwitchedIntegrationPanelQueryPort } from '../ports/primary/gets-switched-integration-panel.query-port';
import { InitsStepperCommandPort } from '../ports/primary/inits-stepper.command-port';
import { IntegrationErrorQuery } from '../ports/primary/integration-error.query';
import { IntegrationLinkStatusQuery } from '../ports/primary/integration-link-status.query';
import { IntegrationStatusQuery } from '../ports/primary/integration-status.query';
import { IntegrationQuery } from '../ports/primary/integration.query';
import { IsCreatingIntegrationQueryPort } from '../ports/primary/is-integration-creating.query-port';
import { IsEditingIntegrationQueryPort } from '../ports/primary/is-integration-editing.query-port';
import { IsStartPageShownQueryPort } from '../ports/primary/is-start-page-shown.query-port';
import { LinksIntegrationCommand } from '../ports/primary/links-integration.command';
import { LinksIntegrationCommandPort } from '../ports/primary/links-integration.command-port';
import { LoadsIntegrationCommand } from '../ports/primary/loads-integration.command';
import { LoadsIntegrationCommandPort } from '../ports/primary/loads-integration.command-port';
import { StartsIntegrationProcessCommand } from '../ports/primary/starts-integration-process.command';
import { StartsIntegrationProcessCommandPort } from '../ports/primary/starts-integration-process.command-port';
import { SwitchesCssDomainCommand } from '../ports/primary/switches-css-domain.command';
import { SwitchesCssDomainCommandPort } from '../ports/primary/switches-css-domain.command-port';
import { SwitchesIntegrationCommand } from '../ports/primary/switches-integration.command';
import { SwitchesIntegrationCommandPort } from '../ports/primary/switches-integration.command-port';
import { UpgradesIntegrationQuery } from '../ports/primary/upgrades-integration.query';
import { UpgradesIntegrationQueryPort } from '../ports/primary/upgrades-integration.query-port';
import { AppMetadataDto } from '../ports/secondary/app-metadata.dto';
import {
  CANCELS_INTEGRATION_DTO,
  CancelsIntegrationDtoPort,
} from '../ports/secondary/cancels-integration.dto-port';
import {
  CREATES_INTEGRATION_DTO,
  CreatesIntegrationDtoPort,
  IntegrationCreateData,
} from '../ports/secondary/creates-integration.dto-port';
import {
  INTEGRATION_ID_CHANGED_DISPATCHER,
  IntegrationIdChangedDispatcherPort,
} from '../ports/secondary/dispatchers/integration-id-changed.dispatcher-port';
import {
  GETS_APPS_META_DTO_PORT,
  GetsAppsMetaDtoPort,
} from '../ports/secondary/gets-apps-meta.dto-port';
import {
  GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT,
  GetsClientSubsciptionsDtoPort,
} from '../ports/secondary/gets-client-subscriptions.dto-port';
import {
  GETS_CSS_MERCHANT_IDS_DTO_PORT,
  GetsCssMerchantIdsDtoPort,
} from '../ports/secondary/gets-css-merchant-ids.dto-port';
import {
  GETS_INSTALLED_APPS_IDS_DTO_PORT,
  GetsInstalledAppsIdsDtoPort,
} from '../ports/secondary/gets-installed-apps-ids';
import {
  GETS_INTEGRATION_ERROR_DTO,
  GetsIntegrationErrorDtoPort,
} from '../ports/secondary/gets-integration-error.dto-port';
import {
  GETS_INTEGRATION_LINK_STATUS_DTO,
  GetsIntegrationLinkStatusDtoPort,
} from '../ports/secondary/gets-integration-link-status.dto-port';
import {
  GETS_INTEGRATION_DTO,
  GetsIntegrationDtoPort,
} from '../ports/secondary/gets-integration.dto-port';
import { IntegrationDto } from '../ports/secondary/integration.dto';
import {
  LINKS_INTEGRATION_DTO,
  LinksIntegrationDtoPort,
} from '../ports/secondary/links-integration.dto-port';
import {
  PATCHES_INTEGRATION_CONTEXT,
  PatchesIntegrationContextPort,
} from '../ports/secondary/patches-integration.context-port';
import {
  SELECTS_INTEGRATION_CONTEXT,
  SelectsIntegrationContextPort,
} from '../ports/secondary/selects-integration.context-port';
import { ClientSubscriptionDto } from '../ports/secondary/subscription.dto';
import {
  SwitchCssDomainDtoPort,
  SWITCHES_CSS_DOMAIN_DTO,
} from '../ports/secondary/switches-css-domain.dto-port';
import {
  SWITCHES_INTEGRATION_DTO,
  SwitchesIntegrationDtoPort,
} from '../ports/secondary/switches-integration.dto-port';
import {
  IntegrationUpdateDataDto,
  UPDATES_INTEGRATION_DTO,
  UpdatesIntegrationDtoPort,
} from '../ports/secondary/updates-integration.dto-port';
import {
  UPGRADES_INTEGRATION_DTO,
  UpgradesIntegrationDtoPort,
} from '../ports/secondary/upgrades-integration.dto-port';

const GOOGLE_CSS_INTEGRATION_ERROR_MAP = {
  ['EMAIL_IS_NOT_LINKED_TO_ELIGIBLE_GOOGLE_ACCOUNT']: {
    title: 'google_css_integration_error_title_1',
    message: 'google_css_integration_error_message_1',
  },
  ['EMAIL_IS_INVALID']: {
    title: 'google_css_integration_error_title_2',
    message: 'google_css_integration_error_message_2',
  },
  ['GOOGLE_ADS_ACCOUNT_NOT_FOUND']: {
    title: 'google_css_integration_error_title_3',
    message: 'google_css_integration_error_message_3',
  },
  ['DUPLICATE_CHILD_FOUND']: {
    title: 'google_css_integration_error_title_4',
    message: 'google_css_integration_error_message_4',
  },
  ['EMAIL_IS_ASSOCIATED_WITH_TOO_MANY_ACCOUNTS']: {
    title: 'google_css_integration_error_title_5',
    message: 'google_css_integration_error_message_5',
  },
  ['UNEXPECTED_ERROR']: {
    title: 'google_css_integration_error_title_6',
    message: 'google_css_integration_error_message_6',
  },
};

@Injectable()
export class GoogleCssIntegrationState
  implements
    CancelsIntegrationCommandPort,
    CreatesIntegrationCommandPort,
    EditsIntegrationCommandPort,
    EntersIntegrationDetailsEditModeCommandPort,
    GetsCobiroCssMetaQueryPort,
    GetsFormStatusQueryPort,
    GetsIntegrationQueryPort,
    GetsIntegrationStatusQueryPort,
    GetsIntegrationErrorQueryPort,
    GetsIntegrationLinkStatusQueryPort,
    GetsIntegrationPauseStatusQueryPort,
    GetsOwnedIntegrationPanelQueryPort,
    GetsSwitchedIntegrationPanelQueryPort,
    LinksIntegrationCommandPort,
    LoadsIntegrationCommandPort,
    SwitchesIntegrationCommandPort,
    IsCreatingIntegrationQueryPort,
    IsEditingIntegrationQueryPort,
    InitsStepperCommandPort,
    GetsInstalledAppsQueryPort,
    GetsFormErrorQueryPort,
    StartsIntegrationProcessCommandPort,
    UpgradesIntegrationQueryPort,
    GetsCssMerchantIdsQueryPort,
    SwitchesCssDomainCommandPort,
    IsStartPageShownQueryPort
{
  private readonly _isCreating$ = new BehaviorSubject<boolean>(false);
  private readonly _isEditing$ = new BehaviorSubject<boolean>(false);
  private readonly _isEditMode$ = new BehaviorSubject<boolean>(false);
  private readonly _integrationStatus$ = new BehaviorSubject<
    'pending' | 'success' | 'missing' | null
  >(null);

  readonly isCreating$ = this._isCreating$.asObservable();
  readonly isEditing$ = this._isEditing$.asObservable();
  private readonly _errorFields$ = new Subject<string[]>();
  private _stepper: Stepper;

  constructor(
    @Inject(CANCELS_INTEGRATION_DTO)
    private readonly _cancelsIntegrationDtoPort: CancelsIntegrationDtoPort,
    @Inject(CREATES_INTEGRATION_DTO)
    private readonly _createsIntegrationDtoPort: CreatesIntegrationDtoPort,
    @Inject(GETS_INTEGRATION_DTO)
    private readonly _getsIntegrationDtoPort: GetsIntegrationDtoPort,
    @Inject(GETS_INTEGRATION_ERROR_DTO)
    private readonly _getsIntegrationErrorDtoPort: GetsIntegrationErrorDtoPort,
    @Inject(GETS_INTEGRATION_LINK_STATUS_DTO)
    private readonly _getsIntegrationLinkStatusDtoPort: GetsIntegrationLinkStatusDtoPort,
    @Inject(LINKS_INTEGRATION_DTO)
    private readonly _linksIntegrationDtoPort: LinksIntegrationDtoPort,
    @Inject(PATCHES_INTEGRATION_CONTEXT)
    private readonly _patchesIntegrationContextPort: PatchesIntegrationContextPort,
    @Inject(SELECTS_INTEGRATION_CONTEXT)
    private readonly _selectsIntegrationContextPort: SelectsIntegrationContextPort,
    @Inject(STORAGE)
    private readonly _storage: Storage,
    @Inject(SWITCHES_INTEGRATION_DTO)
    private readonly _switchesIntegrationDtoPort: SwitchesIntegrationDtoPort,
    @Inject(UPDATES_INTEGRATION_DTO)
    private readonly _updatesIntegrationDtoPort: UpdatesIntegrationDtoPort,
    private readonly _alert: HuiAlert,
    private readonly _dialog: MatDialog,
    @Inject(GETS_APPS_META_DTO_PORT)
    private readonly _getAppsMetaDtoPort: GetsAppsMetaDtoPort,
    @Inject(GETS_INSTALLED_APPS_IDS_DTO_PORT)
    private readonly _getInstalledAppsIdsDtoPort: GetsInstalledAppsIdsDtoPort,
    @Inject(INTEGRATION_ID_CHANGED_DISPATCHER)
    private readonly _integrationIdChangedDispatcher: IntegrationIdChangedDispatcherPort,
    @Inject(GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT)
    private readonly _getsClientSubsciptionsDtoPort: GetsClientSubsciptionsDtoPort,
    @Inject(WORKSPACE_ID_GETTER) private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    @Inject(UPGRADES_INTEGRATION_DTO)
    private readonly _upgradesIntegrationDtoPort: UpgradesIntegrationDtoPort,
    @Inject(GETS_CSS_MERCHANT_IDS_DTO_PORT)
    private readonly _getsCssMerchantIdsDtoPort: GetsCssMerchantIdsDtoPort,
    @Inject(SWITCHES_CSS_DOMAIN_DTO)
    private readonly _switchCssDomainDtoPort: SwitchCssDomainDtoPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
    private readonly _router: Router,
  ) {}

  isStartPageShown(clientId: string): Observable<boolean> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();

    return combineLatest([
      this._getsClientSubsciptionsDtoPort.getSubscriptions({
        workspaceId: workspaceId,
        clientId: clientId,
      }),
      this._getInstalledAppsIdsDtoPort.getAll(this._getSiteId()),
    ]).pipe(
      map(([subscriptions, apps]: [ClientSubscriptionDto[], string[]]) =>
        apps.includes('cobiro_css')
          ? false
          : !subscriptions.some(subscription => subscription.productName === 'css'),
      ),
    );
  }

  switchCssDomain(command: SwitchesCssDomainCommand): Observable<void> {
    return this._switchCssDomainDtoPort
      .switchCssDomain({
        siteId: this._getSiteId(),
        cssDomainId: command.cssDomainId,
      })
      .pipe(
        tap({
          next: () => this._alert.open('success', 'cobiro_css_upgrade_integration_success'),
          error: () => this._alert.open('error', '_something_went_wrong'),
        }),
      );
  }

  getCssMerchantIds(): Observable<CssMerchantQuery[]> {
    return this._getsCssMerchantIdsDtoPort
      .getCssMerchantIds({
        teamId: this._teamIdGetter.getTeamId(),
      })
      .pipe(
        map(res =>
          res.map(
            cssMerchant => new CssMerchantQuery(cssMerchant.cssDomainId, cssMerchant.cssDomainName),
          ),
        ),
      );
  }

  upgradeIntegration(query: UpgradesIntegrationQuery): Observable<boolean> {
    return this._upgradesIntegrationDtoPort
      .upgradeIntegration({ integrationId: query.integrationId })
      .pipe(
        tap({
          next: () => this._alert.open('success', 'cobiro_css_upgrade_integration_success'),
          error: () => this._alert.open('error', '_something_went_wrong'),
        }),
      );
  }

  startIntegration(command: StartsIntegrationProcessCommand): Observable<boolean> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return this._getsClientSubsciptionsDtoPort
      .getSubscriptions({ workspaceId: workspaceId, clientId: command.clientId })
      .pipe(
        map(subscriptions =>
          subscriptions.some(subscription => subscription.productName === 'css'),
        ),
        tap((res: boolean) => {
          if (!res) {
            this._applicationBus.dispatch(new PaymentFlowStartedEvent(command.clientId, 'css'));
            this._router.navigate([
              '/',
              'connect',
              this._teamIdGetter.getTeamId(),
              'clients',
              'setup',
            ]);
          }
        }),
      );
  }

  getInstalledApps(): Observable<string[]> {
    return this._getInstalledAppsIdsDtoPort.getAll(this._getSiteId());
  }

  init(stepper: HuiStepperComponent): void {
    this._stepper = stepper;
  }

  isCreating(): Observable<boolean> {
    return this._isCreating$.asObservable();
  }

  isEditing(): Observable<boolean> {
    return this._isEditing$.asObservable();
  }

  getAppMeta(): Observable<AppMetadataQuery> {
    return this._getAppsMetaDtoPort
      .getAppsMeta()
      .pipe(
        map((apps: AppMetadataDto[]) =>
          AppMetadataQuery.fromDto(apps.find(app => app.identifier === 'google-css')),
        ),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cancelIntegration(command: CancelsIntegrationCommand): Observable<void> {
    return this._dialog
      .open(ConfirmIntegrationCancelDialogComponent)
      .afterClosed()
      .pipe(
        take(1),
        filter(result => !!result),
        tap(res => {
          this._isEditing$.next(true);
        }),
        switchMap(() => this._cancelsIntegrationDtoPort.cancelIntegration(this._getSiteId())),
        tap(() => {
          this._stepper?.previous();
          this._integrationStatus$.next('missing');
          this._alert.open('success', 'cobiro_css_cancel_integration_success');
        }),
        finalize(() => {
          this._isEditing$.next(false);
        }),
      );
  }

  createIntegration({
    websiteName,
    adsId,
    managerEmail,
    cssDomainId,
  }: CreatesIntegrationCommand): Observable<void> {
    this._isCreating$.next(true);
    const createData: IntegrationCreateData = {
      adsId: adsId,
      siteId: this._getSiteId(),
      managerEmail: managerEmail,
      websiteName: websiteName,
      cssDomainId: cssDomainId,
    };

    return this._createsIntegrationDtoPort.createIntegration(createData).pipe(
      catchError(err => {
        if (err.status === 422) {
          const failedFieldNames = this._matToFormFields(
            err.error.errors.map(e => e.source.pointer),
          );
          this._errorFields$.next(failedFieldNames);
        }
        if (err instanceof HttpErrorResponse && err.status === 409) {
          this._alert.open('error', err.error.errors[0].detail);
        }
        return throwError(err);
      }),
      switchMap(() => this.loadIntegration(new LoadsIntegrationCommand())),
      tap(() => this._stepper.next()),
      tap(() => this._isEditMode$.next(false)),
      finalize(() => this._isCreating$.next(false)),
    );
  }

  editIntegration({
    websiteName,
    adsId,
    managerEmail,
    cssDomainId,
    merchantId,
  }: EditsIntegrationCommand): Observable<void> {
    this._isEditing$.next(true);

    return this._getsIntegrationDtoPort.getIntegration(this._getSiteId()).pipe(
      tap({
        next: (integration: IntegrationDto) =>
          this._integrationIdChangedDispatcher.dispatch({
            integration: {
              id: integration.integrationId,
              type: integration.integrationType,
              isErrors: false,
            },
          }),
        error: () => this._alert.open('error', '_something_went_wrong'),
      }),
      switchMap(({ id, integrationType }) => {
        const updateData: IntegrationUpdateDataDto = {
          id,
          adsId: adsId,
          siteId: this._getSiteId(),
          websiteName: websiteName,
          managerEmail: managerEmail,
          merchantId: merchantId,
          integrationType: integrationType,
          cssDomainId: cssDomainId,
        };

        return this._updatesIntegrationDtoPort.updateIntegration(updateData).pipe(
          catchError(err => {
            if (err.status === 422) {
              const failedFieldNames = this._matToFormFields(
                err.error.errors.map(e => e.source.pointer),
              );
              this._errorFields$.next(failedFieldNames);
            }
            return throwError(err);
          }),
          switchMap(() => this._selectsIntegrationContextPort.select()),
          take(1),
          switchMap(context =>
            this._patchesIntegrationContextPort.patch({
              dto: {
                ...context.dto,
                ...updateData,
                email: managerEmail,
                businessName: websiteName,
              },
            }),
          ),
        );
      }),
      map(() => void 0),
      tap(() => this._stepper.next()),
      tap(() => this._isEditMode$.next(false)),
      finalize(() => this._isEditing$.next(false)),
    );
  }

  enterEditMode(command: EntersIntegrationDetailsEditModeCommand): Observable<void> {
    this._isEditMode$.next(true);
    this._stepper.goToStep(0);
    return of(void 0);
  }

  getFormStatusQuery(): Observable<FormStatusQuery> {
    return this._isEditMode$
      .asObservable()
      .pipe(map(isEditMode => new FormStatusQuery(isEditMode)));
  }

  getIntegrationStatus(): Observable<IntegrationStatusQuery> {
    return this._integrationStatus$
      .asObservable()
      .pipe(map(status => new IntegrationStatusQuery(status)));
  }

  getIntegrationQuery(): Observable<IntegrationQuery> {
    return this._selectsIntegrationContextPort.select().pipe(
      filter(context => !!context.dto),
      map(context => context.dto),
      map(
        dto =>
          new IntegrationQuery(
            dto.cssDomainId,
            dto.adsId,
            dto.integrationId,
            dto.businessName,
            dto.email,
            dto.merchantId,
            dto.integrationType,
          ),
      ),
    );
  }

  getIntegrationErrorQuery(): Observable<IntegrationErrorQuery | null> {
    return this._getsIntegrationErrorDtoPort.getIntegrationError(this._getSiteId()).pipe(
      take(1),
      map(dto =>
        dto.code
          ? new IntegrationErrorQuery(
              GOOGLE_CSS_INTEGRATION_ERROR_MAP[dto.code]?.title ?? 'Unknown error',
              GOOGLE_CSS_INTEGRATION_ERROR_MAP[dto.code]?.message ?? 'Unknown error message',
            )
          : null,
      ),
    );
  }

  getIntegrationLinkStatus(): Observable<IntegrationLinkStatusQuery> {
    return this._getsIntegrationLinkStatusDtoPort.getIntegrationLinkStatus(this._getSiteId()).pipe(
      take(1),
      map(({ isLinked }) => new IntegrationLinkStatusQuery(!isLinked)),
    );
  }

  getIntegrationPauseStatus(): Observable<boolean> {
    return this._selectsIntegrationContextPort.select().pipe(
      filter(context => !!context?.dto),
      map(context => context.dto),
      map(dto => dto.isPaused),
    );
  }

  isOwnedExpanded(): Observable<boolean> {
    return this.getIntegrationQuery().pipe(
      map(query => query.integrationType === 'owned' && query.integrationId !== null),
    );
  }

  isSwitchedExpanded(): Observable<boolean> {
    return this.getIntegrationQuery().pipe(
      map(
        query =>
          query.integrationType === 'switched' ||
          query.integrationId === null ||
          query.integrationType === null,
      ),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  linkIntegration(command: LinksIntegrationCommand): Observable<void> {
    return this._linksIntegrationDtoPort.linkIntegration(this._getSiteId());
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadIntegration(command: LoadsIntegrationCommand): Observable<void> {
    return this._getsIntegrationDtoPort.getIntegration(this._getSiteId()).pipe(
      take(1),
      tap(dto => {
        this._integrationIdChangedDispatcher.dispatch({
          integration: { id: dto.integrationId, type: dto.integrationType, isErrors: false },
        });
        this._integrationStatus$.next(dto.status);
      }),
      switchMap(dto => this._patchesIntegrationContextPort.patch({ dto })),
    );
  }

  switchIntegration(command: SwitchesIntegrationCommand): Observable<void> {
    const { websiteName, adsId, managerEmail, merchantId, cssDomainId } = command;
    return this._switchesIntegrationDtoPort
      .switchAccount({
        siteId: this._getSiteId(),
        websiteName,
        adsId,
        managerEmail,
        merchantId,
        cssDomainId,
      })
      .pipe(
        switchMap(() => this.loadIntegration(new LoadsIntegrationCommand())),
        tap(() => this._stepper.next()),
        tap(() => this._isEditMode$.next(false)),
      );
  }

  getFormErrorQuery(): Observable<string[]> {
    return this._errorFields$.asObservable();
  }

  private _getSiteId(): string {
    return String(this._storage.getItem('cobiro-pro-current-client'));
  }

  private _matToFormFields(errors: string[]): string[] {
    return errors.map(e => {
      const arr = e.split('/');
      return arr.at(arr.length - 1);
    });
  }
}
