import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[libDataSelector]',
})
export class DataSelectorDirective implements AfterViewInit {
  @Input() libDataSelector: string;
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.libDataSelector) {
      this.renderer.setAttribute(this.elRef.nativeElement, 'data-selector', this.libDataSelector);
    }
  }
}
