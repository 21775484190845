import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { take } from 'rxjs';
import {
  ShowConfigStepperQueryPort,
  SHOW_CONFIG_STEPPER_QUERY_PORT,
} from '../../../../application/ports/primary/shows-config-stepper.query-port';

@Component({
  selector: 'lib-label-manager-integration',
  templateUrl: './label-manager-integration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerIntegrationComponent implements OnInit {
  constructor(
    @Inject(SHOW_CONFIG_STEPPER_QUERY_PORT)
    private readonly _showConfigStepperCommandPort: ShowConfigStepperQueryPort,
  ) {}

  ngOnInit(): void {
    this._showConfigStepperCommandPort.showStepper().pipe(take(1)).subscribe();
  }
}
