import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hui-avatar',
  templateUrl: './hui-avatar.component.html',
  styleUrls: ['hui-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HuiAvatarComponent {
  @Input() readonly avatarUrl?: string;
  @Input() readonly name: string;
  @Input() readonly shape: 'round' | 'rounded' = 'rounded';
  @Input() readonly size: 'regular' | 'small' | 'xxl' = 'regular';
  @Input() readonly customClasses?: string[];
}
