/* eslint-disable max-lines-per-function */
import { Component, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Subject, switchMap, takeUntil } from 'rxjs';
import {
  GetsInstalledAppsQueryPort,
  GETS_INSTALLED_APPS_QUERY_PORT,
} from '../../../../application/ports/primary/gets-installed_apps.query-port';
import { IntegrationInfoQuery } from '../../../../application/ports/primary/integration-info.query';
import {
  SetsIntegrationQueryPort,
  SETS_INTEGRATION_QUERY,
} from '../../../../application/ports/primary/sets-integration.query-port';
import {
  ShowStartPageQueryPort,
  SHOW_START_PAGE_QUERY_PORT,
} from '../../../../application/ports/primary/shows-start-page.query-port';
import {
  StartsIntegrationProcessCommandPort,
  STARTS_INTEGRATION_PROCESS_COMMAND,
} from '../../../../application/ports/primary/starts-integration-process.command-port';

@Component({
  selector: 'lib-label-manager-dashboard',
  templateUrl: './label-manager-dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerDashboardComponent implements OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  readonly showStartPage$ = this._showStartPageQueryPort.showStartPage();

  constructor(
    @Inject(SETS_INTEGRATION_QUERY)
    private readonly _setIntegrationQueryPort: SetsIntegrationQueryPort,
    @Inject(SHOW_START_PAGE_QUERY_PORT)
    private readonly _showStartPageQueryPort: ShowStartPageQueryPort,
    @Inject(STARTS_INTEGRATION_PROCESS_COMMAND)
    private readonly _startsIntegrationProcessCommandPort: StartsIntegrationProcessCommandPort,
    @Inject(GETS_INSTALLED_APPS_QUERY_PORT)
    private readonly _getsInstalledAppsQueryPort: GetsInstalledAppsQueryPort,
    private readonly _activatedRoute: ActivatedRoute,
  ) {
    this._activatedRoute.data
      .pipe(
        map(res => res.integration),
        switchMap((integration: IntegrationInfoQuery) =>
          this._setIntegrationQueryPort.setIntegration(integration),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();

    this._getsInstalledAppsQueryPort
      .getInstalledApps()
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }

  startIntegration(): void {
    const id = this._activatedRoute.snapshot.paramMap.get('shopId');
    this._startsIntegrationProcessCommandPort
      .startIntegration({ clientId: id })
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}
