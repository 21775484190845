import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FindDomainClickedEvent } from '@app.cobiro.com/domains';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { TOP_BAR_STATE_GETTER, TopBarStateGetter } from '@app.cobiro.com/topbar';

@Injectable()
export class FindDomainClickedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [FindDomainClickedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    private readonly _router: Router,
    @Inject(TOP_BAR_STATE_GETTER) private readonly _topBarStateGetter: TopBarStateGetter,
  ) {}

  handle([event]: [FindDomainClickedEvent]): void {
    this._router.navigate(['/', 'sites', this._topBarStateGetter.siteId, 'find']);
  }
}
