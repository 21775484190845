import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import {
  CreatesNonCssIntegrationQueryPort,
  CREATES_NON_CSS_INTEGRATION_QUERY,
} from '../../../../../application/ports/primary/creates-non-css-integration.query-port';
import { EMPTY, Subject, takeUntil } from 'rxjs';
import {
  googleAdsIdValidator,
  WRONG_ADS_ID_ERROR_KEY,
} from './google-ads-id-validator/google-ads-id.validator';
import {
  merchantIdValidator,
  WRONG_MERCHANT_ID_ERROR_KEY,
} from './merchant-id-validator/merchant-id.validator';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-label-manager-non-css-integration',
  templateUrl: './label-manager-non-css-integration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerNonCssIntegrationComponent implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();

  readonly form: UntypedFormGroup = this._formBuilder.group({
    websiteName: ['', Validators.required],
    adsId: ['', [Validators.required, googleAdsIdValidator]],
    managerEmail: ['', [Validators.required, Validators.email]],
    merchantId: ['', [Validators.required, merchantIdValidator]],
  });

  readonly wrongAdsIdErrorKey = WRONG_ADS_ID_ERROR_KEY;
  readonly wrongMerchantIdErrorKey = WRONG_MERCHANT_ID_ERROR_KEY;

  constructor(
    @Inject(CREATES_NON_CSS_INTEGRATION_QUERY)
    private readonly _createsNonCssIntegrationQueryPort: CreatesNonCssIntegrationQueryPort,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _matDialogRef: MatDialogRef<LabelManagerNonCssIntegrationComponent>,
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onFormSubmit(): void {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsDirty());
      this.form.markAllAsTouched();
      return;
    }

    const { websiteName, adsId, managerEmail, merchantId } = this.form.value;

    this._createsNonCssIntegrationQueryPort
      .createIntegration({
        websiteName,
        adsId,
        managerEmail,
        merchantId,
      })
      .pipe(takeUntil(this._destroyed$))
      .subscribe(res => (res ? this._matDialogRef.close() : EMPTY));
  }
}
