import { Injectable } from '@angular/core';
import {
  GetsUserMenuOptionsQueryPort,
  UserMenuOptionQuery,
} from '@app.cobiro.com/cobiro-pro-rewrite/layout';
import { Observable, of } from 'rxjs';

@Injectable()
export class GetsUserMenuOptionsService implements GetsUserMenuOptionsQueryPort {
  getUserMenuOptions(): Observable<UserMenuOptionQuery[]> {
    return of([
      {
        routerLink: ['/my-profile'],
        translationKey: 'nav_top_bar_user_menu_my_profile',
      },
      {
        routerLink: ['/user/logout'],
        translationKey: 'nav_top_bar_user_menu_logout',
      },
    ]);
  }
}
