import { Inject, Injectable } from '@angular/core';
import { TeamIdGetter, TEAM_ID_GETTER } from '@app.cobiro.com/cobiro-pro/context';
import { FrontendPagination, PaginationQuery, SORT_ORDER } from '@app.cobiro.com/core/pagination';
import { map, Observable, tap } from 'rxjs';
import { GetsLatestUsersPaginationQueryPort } from '../ports/primary/gets-latest-users-pagination.query-port';
import { GetsLatestUsersQueryPort } from '../ports/primary/gets-latest-users.query-port';
import { LoadLatestUsersCommandPort } from '../ports/primary/load-latest-users.command-port';
import { UserQuery } from '../ports/primary/user.query';
import {
  SelectsUsersContextStoragePort,
  SELECTS_USERS_CONTEXT_STORAGE,
} from '../ports/secondary/context/selects-users-context.storage-port';
import {
  USERS_LIST_CHANGED_DISPATCHER,
  UsersListChangedDispatcherPort,
} from '../ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import {
  GetsAllUsersDtoPort,
  GETS_ALL_USERS_DTO,
} from '../ports/secondary/dto/gets-all-users.dto-port';
import { UserDTO } from '../ports/secondary/dto/user.dto';

@Injectable()
export class LatestUsersState
  implements
    GetsLatestUsersQueryPort,
    GetsLatestUsersPaginationQueryPort,
    LoadLatestUsersCommandPort
{
  private readonly _pagination: FrontendPagination<UserDTO> = FrontendPagination.fromRaw(
    this._selectsUsersContextStorage.select().pipe(map(usersContext => usersContext.list)),
    0,
    5,
    ['createdAt', SORT_ORDER.DESC],
    {},
  );

  constructor(
    @Inject(GETS_ALL_USERS_DTO)
    private readonly _getAllUsersDTO: GetsAllUsersDtoPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(SELECTS_USERS_CONTEXT_STORAGE)
    private readonly _selectsUsersContextStorage: SelectsUsersContextStoragePort,
    @Inject(USERS_LIST_CHANGED_DISPATCHER)
    private readonly _usersListChangedDispatcher: UsersListChangedDispatcherPort,
  ) {}

  getLatestUsersQuery(): Observable<UserQuery[]> {
    return this._pagination.getPaginatedList().pipe(
      map(users => {
        return this._mapUsersDtoToQuery(users);
      }),
    );
  }

  getUserListPagination(): Observable<PaginationQuery> {
    return this._pagination.getPaginationQuery();
  }

  loadUsers(): Observable<UserDTO[]> {
    const teamId = this._teamIdGetter.getTeamId();
    return this._getAllUsersDTO.getAll({ teamId }).pipe(
      tap(users => {
        this._usersListChangedDispatcher.dispatch({ updatedUsers: users });
      }),
    );
  }

  private _mapUsersDtoToQuery(users: UserDTO[]): UserQuery[] {
    return users.map(userDto => UserQuery.fromUserDTO(userDto));
  }
}
