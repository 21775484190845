import { NgModule } from '@angular/core';
import { ClientsHistoryManager } from './clients-history-manager.state';
import { GET_SELECTED_CLIENT_QUERY } from '../ports/primary/gets-selected-client.query-port';
import { SELECTS_CLIENT_COMMAND } from '../ports/primary/selects-client.command-port';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { ClientsHistoryClientSelectedEventHandler } from '../../adapters/primary/event-handlers/clients-history-client-selected.event-handler';
import { MANAGER_BACK_TO_WORKSPACE_COMMAND } from '../ports/primary/manager-back-to-workspace.command-port';
import { MANAGES_VISITED_CLIENTS_DTO_PORT } from '../ports/secondary/manages-visited-clients.dto-port';
import { LocalStorageVisitedClientsService } from '../../adapters/secondary/local-storage-visited-clients.service';
import { GETS_VISITED_CLIENTS_QUERY } from '../ports/primary/gets-visited-clients.query-port';
import { LocalStorageSelectedClientService } from '../../adapters/secondary/local-storage-selected-client.service';
import { SETS_SELECTED_CLIENT_DTO } from '../ports/secondary/sets-selected-client.dto-port';
import { GET_SELECTED_CLIENT_DTO } from '../ports/secondary/gets-selected-client.dto-port';
import { CHANGES_SELECTED_CLIENT_COMMAND } from '../ports/primary/changes-selected-client.command-port';
import { DESELECTS_CLIENTS_COMMAND_PORT } from '../ports/primary/deselects-client.command-port';
import { EnterDashboardEventHandler } from '../../adapters/primary/event-handlers/enter-dashboard.event-handler';
import { CLEARS_CLIENTS_HISTORY_COMMAND_PORT } from '../ports/primary/clears-clients-history.command-port';
import { ClientsHistoryUserSignedOutEventHandler } from '../../adapters/primary/event-handlers/clients-history-user-signed-out.event-handler';

@NgModule({
  providers: [
    ClientsHistoryManager,
    LocalStorageVisitedClientsService,
    LocalStorageSelectedClientService,
    {
      provide: GET_SELECTED_CLIENT_QUERY,
      useExisting: ClientsHistoryManager,
    },
    {
      provide: SELECTS_CLIENT_COMMAND,
      useExisting: ClientsHistoryManager,
    },
    {
      provide: MANAGER_BACK_TO_WORKSPACE_COMMAND,
      useExisting: ClientsHistoryManager,
    },
    {
      provide: GETS_VISITED_CLIENTS_QUERY,
      useExisting: ClientsHistoryManager,
    },
    {
      provide: CHANGES_SELECTED_CLIENT_COMMAND,
      useExisting: ClientsHistoryManager,
    },
    {
      provide: DESELECTS_CLIENTS_COMMAND_PORT,
      useExisting: ClientsHistoryManager,
    },
    {
      provide: CLEARS_CLIENTS_HISTORY_COMMAND_PORT,
      useExisting: ClientsHistoryManager,
    },
    {
      provide: MANAGES_VISITED_CLIENTS_DTO_PORT,
      useExisting: LocalStorageVisitedClientsService,
    },
    {
      provide: GET_SELECTED_CLIENT_DTO,
      useExisting: LocalStorageSelectedClientService,
    },
    {
      provide: SETS_SELECTED_CLIENT_DTO,
      useExisting: LocalStorageSelectedClientService,
    },
    provideApplicationEventsHandler(ClientsHistoryClientSelectedEventHandler),
    provideApplicationEventsHandler(EnterDashboardEventHandler),
    provideApplicationEventsHandler(ClientsHistoryUserSignedOutEventHandler),
  ],
})
export class ClientsHistoryManagerStateModule {}
