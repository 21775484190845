import { VisitedClientQuery } from './visited-client.query';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface GetsVisitedClientsQueryPort {
  getVisitedClients(): Observable<VisitedClientQuery[]>;
}

export const GETS_VISITED_CLIENTS_QUERY = new InjectionToken<GetsVisitedClientsQueryPort>(
  'GETS_VISITED_CLIENTS_QUERY',
);
