import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { CobiroProEnterDashboardEvent } from '@app.cobiro.com/core/events';
import { Inject, Injectable } from '@angular/core';
import {
  DESELECTS_CLIENTS_COMMAND_PORT,
  DeselectsClientCommandPort,
} from '../../../application/ports/primary/deselects-client.command-port';

@Injectable()
export class EnterDashboardEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProEnterDashboardEvent];

  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(DESELECTS_CLIENTS_COMMAND_PORT)
    private _deselectsClientCommandPort: DeselectsClientCommandPort,
  ) {}

  handle(events: CobiroProEnterDashboardEvent[]): void {
    this._deselectsClientCommandPort.deselectClient();
  }
}
