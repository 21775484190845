import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserState } from '@app.cobiro.com/auth';
import { UIState } from '@app.cobiro.com/shared/ui-state';
import { TrackingService } from '@app.cobiro.com/tracking';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteWithParams } from '../core/helpers/route-with-params';
import { SidebarItems } from './configs/sidebar-items.config';
import { LAYOUT_SIDENAV_MAP } from './layout-sidenav.config';
import { LAYOUT_BG_TRANSPARENT } from './layout-tokens';
import { LayoutService } from './layout.service';
import { LayoutState } from './layout.state';

interface SidebarItem {
  id: SidebarItems;
  icon: string;
  route: string[];
  active: boolean;
}

@Component({
  selector: 'lib-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'layout d-flex flex-column',
  },
})
export class LayoutComponent implements AfterViewInit, OnDestroy {
  @ViewChild('bodyContent') bodyContent: ElementRef;

  sidebarItems$ = combineLatest([
    this.layoutState.sidebarItems$,
    this.layoutState.activeSidebarItem$,
  ]).pipe(
    map(([items, active]: [SidebarItems[], SidebarItems]) => this.getSidebarItems(items, active)),
  );

  sidebarItemToLocale = new Map([
    [SidebarItems.home, '_home'],
    [SidebarItems.build, '_build'],
    [SidebarItems.promote, '_promote'],
    [SidebarItems.grow, '_grow'],
  ]);

  hasWarning$ = this.layoutState.hasWarning$;
  hasTopBar$ = this.layoutState.hasTopbar$;

  hasNavigation$ = this.layoutState.hasNavigation$;
  hasSidebar$ = this.layoutState.hasSidebar$;

  hasBackButton$ = this.layoutState.hasBackButton$;
  hasBottombar$ = this.layoutState.hasBottombar;
  layoutBgTransparent$: Observable<boolean> = this.layoutBgTransparent.select();
  private activeSiteId: string;

  private componentDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(LAYOUT_BG_TRANSPARENT) private readonly layoutBgTransparent: UIState<boolean>,
    private layoutService: LayoutService,
    private router: Router,
    private userState: UserState,
    private layoutState: LayoutState,
    private trackingService: TrackingService,
  ) {}

  ngAfterViewInit(): void {
    (this.bodyContent.nativeElement as Element).addEventListener('scroll', event => {
      this.layoutService.bodyScroll$.next(event);
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  goBackToTheApp() {
    this.router.navigate([`/site/${this.activeSiteId}`]);
  }

  onTopbarListItemClicked(route: string, title: string) {
    if (route) {
      this.trackingService.event('/HUB - User Menu - Breadcrumbs clicked', { route, title });
      this.router.navigate([new RouteWithParams(route, { siteId: this.activeSiteId }).value]);
    }
  }

  openMobileMenu() {
    this.layoutState.toggleMobileMenu(true);
  }

  onSidebarItemClicked(item: SidebarItem) {
    this.trackingService.event(`/HUB - Navigation - ${item.id} clicked`);
    this.router.navigate(
      item.route.map((route: string) => route.replace(':activeSite', this.activeSiteId)),
    );
  }

  onLogoClicked() {
    this.trackingService.event(`/HUB - Navigation - Logo clicked`);
    this.router.navigate(['/site', this.activeSiteId]);
  }

  /**
   * Gets the sidebar items from the activated route and adds them to the sidebar.
   * It also detects which one is the current and sets that to active
   * @param data Activated route snapshot data
   */
  private getSidebarItems(items: SidebarItems[], active: SidebarItems): SidebarItem[] {
    return (items || [])
      .map(item => LAYOUT_SIDENAV_MAP.get(item))
      .map((item: SidebarItem) => {
        if (active) {
          return {
            ...item,
            active: item.id === LAYOUT_SIDENAV_MAP.get(active)?.id || false,
          };
        } else {
          return item;
        }
      });
  }
}
