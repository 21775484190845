import { InjectionToken } from '@angular/core';

export interface SidebarGroup {
  name: string;
  items: SidebarItem[] | null;
  url: string | null;
  collapsed: boolean;
  icon?: string;
  featureFlag: string[];
}

export interface SidebarItem {
  name: string;
  link: string;
  featureFlag?: string;
  source?: string;
  icon?: string;
}

export const SIDEBAR_ITEMS: SidebarGroup[] = [
  {
    name: '_dashboard',
    items: null,
    url: `site/{{siteId}}`,
    collapsed: true,
    icon: 'icon-home-hollow',
    featureFlag: [],
  },
  {
    name: '_build',
    items: [
      {
        name: 'app_website_builder_name',
        link: `site/{{siteId}}/edit-website`,
        icon: 'icon-builder',
      },
      {
        name: '_online_store',
        link: `site/{{siteId}}/ecommerce`,
        icon: 'icon-ecommerce',
      },
      {
        name: '_domains',
        link: `site/{{siteId}}/domains`,
        icon: 'icon-domain',
      },
      {
        name: '_git_and_cdn',
        link: `site/{{siteId}}/git-builder`,
        featureFlag: 'hub_jamstack_publishing',
        source: 'git-builder',
        icon: 'icon-git-builder',
      },
    ],
    url: null,
    collapsed: false,
    featureFlag: ['google_search_and_website_builder_shutdown'],
  },
  {
    name: '_promote',
    items: [
      // For now only Cobiro CSS is available
      {
        name: 'app_google_search_name',
        link: `site/{{siteId}}/store/app/google-search`,
        icon: 'icon-google-search',
        featureFlag: 'google_search_and_website_builder_shutdown',
      },
      {
        name: 'app_google_css_name',
        link: `site/{{siteId}}/store/app/google-css`,
        icon: 'icon-google-css',
      },
      // TODO: Uncomment what Facebook is actually set up - without a valid HTML to show, we break the UI
      // {
      //   name: 'app_facebook_name',
      //   link: `site/{{siteId}}/store/app/facebook`,
      //   icon: 'icon-facebook-ads',
      //   featureFlag: 'facebook_fake_door_test',
      // },
    ],
    url: null,
    collapsed: false,
    featureFlag: [],
  },
  {
    name: '_connect',
    items: [
      {
        name: 'app_zapier_name',
        link: `site/{{siteId}}/connect/zapier`,
        icon: 'icon-zapier',
        featureFlag: 'zapier_fake_door_test',
      },
    ],
    url: null,
    collapsed: false,
    featureFlag: ['google_search_and_website_builder_shutdown'],
  },
];
export const SIDEBAR_ITEMS_DATA = new InjectionToken<SidebarGroup[]>('SIDEBAR_ITEMS_DATA');
