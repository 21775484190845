<div
  class="d-flex justify-content-between cs-btn cs-btn-ghost cs-ph-15 cs-pv-10 w-100"
  (click)="elementClicked.emit(notification)"
>
  <div
    class="d-flex justify-content-start align-items-center notification-icon-and-title-container"
  >
    <mat-icon [svgIcon]="iconMap.get(notification.type)" class="cs-size-sm"></mat-icon>
    <div class="d-flex flex-column notification-title-container cs-ml-15">
      <p class="notification-title cs-text-2 cs-c-shade-80">
        {{ notification.title }}
      </p>
      <small class="cs-text-2 cs-c-shade-80">{{
        notification.date | date: 'dd.MM.YY HH:mm'
      }}</small>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center cs-pl-20 flex-shrink-0">
    <span class="unread-dot" [class.active]="!notification.isRead"></span>
  </div>
</div>
