import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PUBLIC_CDN_URL } from '@app.cobiro.com/cobiro-legacy';
import { Observable } from 'rxjs';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { GetsPlanFeatures } from '../../domain/gets-plan-features';
import { HasDataCollection } from '@cobiro/jsonapi';

interface PlanFeaturesAttributes {
  name: string;
  features: { name: string; limit: number | null }[];
}

@Injectable()
export class HttpGetsPlanFeatures implements GetsPlanFeatures {
  headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(private _client: HttpClient, @Inject(PUBLIC_CDN_URL) private _baseUrl: string) {}

  get(): Observable<Map<string, Map<string, number | null>>> {
    return this._client
      .get<HasDataCollection<PlanFeaturesAttributes>>(
        `${this._baseUrl}/v1/payments/cobiro/plan-features/features.json`,
        {
          params: new HttpParams({ fromObject: { timestamp: String(new Date().getTime()) } }),
          headers: this.headers,
        },
      )
      .pipe(
        map(
          response =>
            new Map(
              response.data.map(featureDTO => [
                featureDTO.attributes.name,
                new Map(
                  featureDTO.attributes.features.map(feature => [feature.name, feature.limit]),
                ),
              ]),
            ),
        ),
      );
  }
}
