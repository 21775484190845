import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, take } from 'rxjs';
import {
  LoadsWorkspacesQueryPort,
  LOADS_WORKSPACES_QUERY,
} from '../../../application/ports/primary/loads-workspaces.query.port';

@Injectable()
export class LoadWorkspacesResolver  {
  constructor(
    @Inject(LOADS_WORKSPACES_QUERY) private readonly _loadWorkspaces: LoadsWorkspacesQueryPort,
  ) {}

  resolve(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this._loadWorkspaces.loadWorkspaces(snapshot.paramMap.get('teamId')).pipe(take(1));
  }
}
