import { Inject, Injectable } from '@angular/core';
import { UpgradeRequestedEvent } from '@app.cobiro.com/core/events';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { UpgradePlanRequestedDispatcherPort } from '../../../application/ports/secondary/upgrade-plan-requested.dispatcher-port';

@Injectable()
export class ApplicationBusCobiroProUpgradePlanRequestedDispatcher
  implements UpgradePlanRequestedDispatcherPort
{
  constructor(@Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus) {}
  dispatch() {
    this._applicationBus.dispatch(new UpgradeRequestedEvent('PAY_DISPLAY-PLAN'));
  }
}
