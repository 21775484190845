import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ServiceUrls } from '@cbr/services';
import { HasDataCollection, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { PUBLIC_CDN_URL } from '@app.cobiro.com/cobiro-legacy';
import { GetsPaymentPlans } from '../../domain/gets-payment-plans';
import { map } from 'rxjs/operators';
import { PaymentPlanData } from '../../domain/payment-plan';
import { PlanTypeCollection } from '../../domain/payment-plan-prices.vo';

export interface PaymentPlanDataAttributes {
  name: string;
  goal: string;
  prices: { [key: string]: number };
  features: { id: string; value: string; additionalInformation?: boolean }[];
}

@Injectable()
export class HttpGetsPaymentPlanService implements GetsPaymentPlans {
  headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(private _http: HttpClient, @Inject(PUBLIC_CDN_URL) private _cdnUrl: ServiceUrls) {}

  getPaymentPlans(): Observable<PaymentPlanData[]> {
    return this._http
      .get<HasDataCollection<PaymentPlanDataAttributes>>(
        `${this._cdnUrl}/json/v2/payment-plans.json?timestamp=${new Date().valueOf()}`,
        {
          headers: this.headers,
        },
      )
      .pipe(
        map(response =>
          response.data.map(({ attributes }) => ({
            name: attributes.name,
            goal: attributes.goal,
            features: attributes.features.map(feature => ({
              id: feature.id,
              value: feature.value,
              additionalInformation: feature.additionalInformation || false,
            })),
            prices: attributes.prices as PlanTypeCollection<number>,
          })),
        ),
      );
  }
}
