/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Inject, Injectable } from '@angular/core';
import { UserProfileUpdatedEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { HuiStepperComponent } from '@app.cobiro.com/shared/hui/stepper';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { BehaviorSubject, mapTo, Observable, of, startWith, Subject, take, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgencyWithWorkspaceCreatedEvent } from '../events/agency-with-workspace-created.event';
import { CompanyNameQuery } from '../ports/primary/company-name.query';
import { CompanyTypeQuery } from '../ports/primary/company-type.query';
import { CountryQuery } from '../ports/primary/country.query';
import { CreateProfileProgressQuery } from '../ports/primary/create-profile-progress.query';
import { CreatesAgencyWithWorkspaceCommand } from '../ports/primary/creates-agency-with-workspace.command';
import { CreatesAgencyWithWorkspaceCommandPort } from '../ports/primary/creates-agency-with-workspace.command-port';
import { FiltersCountriesCommand } from '../ports/primary/filters-countries.command';
import { FiltersCountriesCommandPort } from '../ports/primary/filters-countries.command-port';
import { GetsCompanyTypesQueryPort } from '../ports/primary/gets-company-types.query-port';
import { GetsCreateProfileProgressQueryPort } from '../ports/primary/gets-create-profile-progress.query-port';
import { GetsCurrentCompanyNameQueryPort } from '../ports/primary/gets-current-company-name.query-port';
import { GetsFilteredCountriesQueryPort } from '../ports/primary/gets-filtered-countries.query-port';
import { GetsIsLastStepVisibleQueryPort } from '../ports/primary/gets-is-last-step-visible.query-port';
import { GetsIsValidCountryQueryPort } from '../ports/primary/gets-is-valid-country.query-port';
import { GetsNumberOfClientsQueryPort } from '../ports/primary/gets-number-of-clients.query-port';
import { GetsNumberOfEmployeesQueryPort } from '../ports/primary/gets-number-of-employees.query-port';
import { InitsCreateProfileFlowCommand } from '../ports/primary/inits-create-profile-flow.command';
import { InitsCreateProfileFlowCommandPort } from '../ports/primary/inits-create-profile-flow.command-port';
import { IsInvitedUserQueryPort } from '../ports/primary/is-invited-user.query-port';
import { IsLastStepVisibleQuery } from '../ports/primary/is-last-step-visible.query';
import { IsValidCountryQuery } from '../ports/primary/is-valid-country.query';
import { LeavesCompanyDetailsStepCommand } from '../ports/primary/leaves-company-details-step.command';
import { LeavesCompanyDetailsStepCommandPort } from '../ports/primary/leaves-company-details-step.command-port';
import { LeavesWorkspaceInfoStepCommand } from '../ports/primary/leaves-workspace-info-step.command';
import { LeavesWorkspaceInfoStepCommandPort } from '../ports/primary/leaves-workspace-info-step.command-port';
import { NumberOfClientsQuery } from '../ports/primary/number-of-clients.query';
import { NumberOfEmployeesQuery } from '../ports/primary/number-of-employees.query';
import { SetsIsInvitedUserQueryPort } from '../ports/primary/sets-is-invited-user.query-port';
import { SkipsCompanyDetailsStepCommand } from '../ports/primary/skips-company-details-step.command';
import { SkipsCompanyDetailsStepCommandPort } from '../ports/primary/skips-company-details-step.command-port';
import { SubmitsCompanyBaseInfoStepCommand } from '../ports/primary/submits-company-base-info-step.command';
import { SubmitsCompanyBaseInfoStepCommandPort } from '../ports/primary/submits-company-base-info-step.command-port';
import { SubmitsCompanyDetailsStepCommand } from '../ports/primary/submits-company-details-step.command';
import { SubmitsCompanyDetailsStepCommandPort } from '../ports/primary/submits-company-details-step.command-port';
import { SubmitsWorkspaceInfoStepCommand } from '../ports/primary/submits-workspace-info-step.command';
import { SubmitsWorkspaceInfoStepCommandPort } from '../ports/primary/submits-workspace-info-step.command-port';
import { CountryDto } from '../ports/secondary/country.dto';
import {
  CREATES_AGENCY_WITH_WORKSPACE_DTO,
  CreatesAgencyWithWorkspaceDtoPort,
} from '../ports/secondary/creates-agency-with-workspace.dto-port';
import {
  GETS_COUNTRIES_DTO,
  GetsCountriesDtoPort,
} from '../ports/secondary/gets-countries.dto-port';
import {
  UpdatesUserDetailsDtoPort,
  UPDATES_USER_DETAILS_DTO,
} from '../ports/secondary/updates-user-details.dto-port';

enum CompanyType {
  Agency = 'agency',
  Individual = 'individual',
  EcommerceMerchant = 'ecommerce_merchant',
}

interface CompanyBaseInfo {
  owner: { firstName: string; lastName: string };
  country: string;
  phoneNumber: string;
}

interface CompanyDetailsInfo {
  companyName: string;
  companyType: string;
  numberOfEmployees: number | null;
  numberOfClients: number | null;
}

interface WorkspaceInfo {
  workspaceName: string;
}

const LAST_STEP_INDEX = 3;

@Injectable()
export class CreateProfileState
  implements
    CreatesAgencyWithWorkspaceCommandPort,
    FiltersCountriesCommandPort,
    GetsCompanyTypesQueryPort,
    GetsCreateProfileProgressQueryPort,
    GetsCurrentCompanyNameQueryPort,
    GetsFilteredCountriesQueryPort,
    GetsIsLastStepVisibleQueryPort,
    GetsIsValidCountryQueryPort,
    GetsNumberOfClientsQueryPort,
    GetsNumberOfEmployeesQueryPort,
    InitsCreateProfileFlowCommandPort,
    LeavesCompanyDetailsStepCommandPort,
    LeavesWorkspaceInfoStepCommandPort,
    SkipsCompanyDetailsStepCommandPort,
    SubmitsCompanyBaseInfoStepCommandPort,
    SubmitsCompanyDetailsStepCommandPort,
    SubmitsWorkspaceInfoStepCommandPort,
    SetsIsInvitedUserQueryPort,
    IsInvitedUserQueryPort
{
  private _companyBaseInfo: CompanyBaseInfo | null = null;
  private _companyDetailsInfo: CompanyDetailsInfo | null = null;
  private _workspaceInfo: WorkspaceInfo | null = null;

  private _stepper: HuiStepperComponent | null = null;
  private readonly _countries$ = new BehaviorSubject<CountryDto[]>([]);
  private readonly _filteredCountries$ = new BehaviorSubject<CountryDto[]>([]);
  private readonly _currentStepIndex$ = new BehaviorSubject<number>(0);
  private readonly _currentCompanyName$ = new Subject<string>();
  private readonly _isInvitedUser$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    @Inject(CREATES_AGENCY_WITH_WORKSPACE_DTO)
    private readonly _createsAgencyWithWorkspaceDto: CreatesAgencyWithWorkspaceDtoPort,
    @Inject(GETS_COUNTRIES_DTO) private readonly _getsCountriesDto: GetsCountriesDtoPort,
    private readonly _alert: HuiAlert,
    @Inject(UPDATES_USER_DETAILS_DTO)
    private readonly _updateUserDetails: UpdatesUserDetailsDtoPort,
  ) {
    this._getsCountriesDto
      .getAllCountries()
      .pipe(take(1))
      .subscribe(countries => this._countries$.next(countries));
  }

  createAgencyWithWorkspace(_command: CreatesAgencyWithWorkspaceCommand): Observable<void> {
    if (!this._companyBaseInfo || !this._companyDetailsInfo || !this._workspaceInfo) {
      return of(void 0);
    }

    return this._createsAgencyWithWorkspaceDto
      .createAgencyWithWorkspace({
        owner: {
          ...this._companyBaseInfo.owner,
          companyName: this._companyDetailsInfo.companyName,
          country: this._companyBaseInfo.country,
          phoneNumber: this._companyBaseInfo.phoneNumber ?? null,
        },
        workspaceName: this._workspaceInfo.workspaceName,
        companyType: this._companyDetailsInfo.companyType,
        numberOfEmployees: this._companyDetailsInfo.numberOfEmployees,
        numberOfClients: this._companyDetailsInfo.numberOfClients,
      })
      .pipe(
        tap({
          next: () => {
            this._alert.open('success', 'cobiro_pro_create_agency_with_profile_success');
            this._applicationBus.dispatch(new AgencyWithWorkspaceCreatedEvent());
          },
          error: () => {
            this._alert.open('error', 'cobiro_pro_create_agency_with_profile_failed');
            this.goToPreviousStep();
          },
        }),
        mapTo(void 0),
      );
  }

  filterCountries({ query }: FiltersCountriesCommand): Observable<void> {
    const allCountries = this._countries$.getValue();

    this._filteredCountries$.next(
      allCountries.filter(
        country =>
          country.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
          country.code.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
      ),
    );

    return of(void 0);
  }

  getAvailableCompanyTypes(): Observable<CompanyTypeQuery[]> {
    return of([
      new CompanyTypeQuery('cobiro_pro_company_details_type_agency', CompanyType.Agency),
      new CompanyTypeQuery('cobiro_pro_company_details_type_freelancer', CompanyType.Individual),
      new CompanyTypeQuery(
        'cobiro_pro_company_details_type_merchant',
        CompanyType.EcommerceMerchant,
      ),
    ]);
  }

  getAvailableNumberOfClients(): Observable<NumberOfClientsQuery[]> {
    return of([
      new NumberOfClientsQuery('1', 1),
      new NumberOfClientsQuery('2-5', 5),
      new NumberOfClientsQuery('6-10', 10),
      new NumberOfClientsQuery('11-20', 20),
      new NumberOfClientsQuery('> 21', 21),
    ]);
  }

  getAvailableNumberOfEmployees(): Observable<NumberOfEmployeesQuery[]> {
    return of([
      new NumberOfEmployeesQuery('1-10', 10),
      new NumberOfEmployeesQuery('11-25', 25),
      new NumberOfEmployeesQuery('26-50', 50),
      new NumberOfEmployeesQuery('51-100', 100),
    ]);
  }

  getCreateProfileProgress(): Observable<CreateProfileProgressQuery> {
    return this._currentStepIndex$
      .asObservable()
      .pipe(map(index => new CreateProfileProgressQuery(index >= 1, index >= 2, index === 3)));
  }

  getCurrentCompanyName(): Observable<CompanyNameQuery> {
    return this._currentCompanyName$.asObservable().pipe(
      startWith(''),
      map(name => new CompanyNameQuery(name)),
    );
  }

  getFilteredCountries(): Observable<CountryQuery[]> {
    return this._filteredCountries$
      .asObservable()
      .pipe(map(dtos => dtos.map(dto => new CountryQuery(dto.code, dto.name))));
  }

  setIsInvitedUser(value: boolean): Observable<void> {
    return of(this._isInvitedUser$.next(value));
  }

  isInvitedUser(): Observable<boolean> {
    return this._isInvitedUser$.asObservable();
  }

  getIsLastStepVisible(): Observable<IsLastStepVisibleQuery> {
    return this._currentStepIndex$
      .asObservable()
      .pipe(map(index => new IsLastStepVisibleQuery(index === LAST_STEP_INDEX)));
  }

  getIsValidCountry(country: string): Observable<IsValidCountryQuery> {
    const validCountries = this._countries$.getValue();

    const existingCountry = validCountries.find(
      validCountry =>
        validCountry.code.toLocaleLowerCase().includes(country.toLocaleLowerCase()) ||
        validCountry.name.toLocaleLowerCase().includes(country.toLocaleLowerCase()),
    );

    return of(new IsValidCountryQuery(!!existingCountry, existingCountry));
  }

  initCreateProfileFlow({ stepper }: InitsCreateProfileFlowCommand): Observable<void> {
    this._stepper = stepper;
    return of(void 0);
  }

  leaveCompanyDetailsStep(_command: LeavesCompanyDetailsStepCommand): Observable<void> {
    this.goToPreviousStep();
    return of(void 0);
  }

  leaveWorkspaceInfoStep(_command: LeavesWorkspaceInfoStepCommand): Observable<void> {
    this.goToPreviousStep();
    return of(void 0);
  }

  skipCompanyDetailsStep(_command: SkipsCompanyDetailsStepCommand): Observable<void> {
    this.goToNextStep();
    return of(void 0);
  }

  submitCompanyBaseInfoStep({
    userId,
    firstName,
    lastName,
    country,
    phoneNumber,
  }: SubmitsCompanyBaseInfoStepCommand): Observable<void> {
    this._companyBaseInfo = {
      owner: { firstName, lastName },
      country,
      phoneNumber,
    };
    if (this._isInvitedUser$.getValue()) {
      return this._updateUserDetails
        .update(
          {
            first_name: firstName,
            last_name: lastName,
            country: country,
            phone_number: phoneNumber,
          },
          userId,
        )
        .pipe(
          tap({
            next: details => {
              this._alert.open('success', '_save_changes_success');
              this._applicationBus.dispatch(
                new UserProfileUpdatedEvent({
                  firstName: firstName,
                  lastName: lastName,
                  avatar: details.avatar,
                  country: details.country,
                  phoneNumber: details.phoneNumber,
                }),
              );
              this._applicationBus.dispatch(new AgencyWithWorkspaceCreatedEvent());
            },
            error: () => {
              this._alert.open('error', '_save_changes_error');
            },
          }),
          map(() => void 0),
        );
    }
    this.goToNextStep();
    return of(void 0);
  }

  submitCompanyDetailsStep({
    companyName,
    companyType,
    numberOfEmployees,
    numberOfClients,
  }: SubmitsCompanyDetailsStepCommand): Observable<void> {
    this._companyDetailsInfo = {
      companyName,
      companyType,
      numberOfEmployees,
      numberOfClients,
    };
    this._currentCompanyName$.next(companyName);
    this.goToNextStep();
    return of(void 0);
  }

  submitWorkspaceInfoStep({ workspaceName }: SubmitsWorkspaceInfoStepCommand): Observable<void> {
    this._workspaceInfo = {
      workspaceName,
    };
    this.goToNextStep();
    return of(void 0);
  }

  private goToPreviousStep(): void {
    this._stepper.previous();
    this._currentStepIndex$.next(this._stepper.selectedIndex);
  }

  private goToNextStep(): void {
    this._stepper.next();
    this._currentStepIndex$.next(this._stepper.selectedIndex);
  }
}
