<lib-sidebar-icons></lib-sidebar-icons>

<aside
  *ngIf="siteId"
  class="d-none d-md-flex cs-br-1 cs-b-shade-10 align-items-stretch flex-column justify-content-between cs-sidebar cs-line-height-20 cs-pt-30 cs-pr-0 cs-pb-40 cs-ml-0 h-100"
>
  <nav *ngIf="items$ | async as items; else loader">
    <ul class="sidebar-list cs-m-0 cs-p-0">
      <li *ngFor="let item of items" [class.collapsed]="item.collapsed" class="font-weight-bold">
        <ng-container *ngIf="shouldDisplayGroup(item.featureFlag) | async">
          <ng-container *ngIf="item.items">
            <span
              class="cs-ph-10 cs-pv-5 cs-mv-0 cs-mh-20 cs-text-2 d-block text-transform-uppercase font-weight-normal mt-3"
            >
              {{ item.name | language }}
            </span>

            <ul class="group cs-m-0 cs-p-0">
              <ng-container *ngFor="let groupItem of item.items">
                <li *libFeatureFlags="[groupItem.featureFlag]" class="cs-height-40-xs cs-mt-2">
                  <a
                    [routerLinkActiveOptions]=""
                    [routerLink]="groupItem.link"
                    class="d-flex align-items-center group-link cs-title-8 sidebar-menu-item cs-pv-5 cs-ph-10 cs-mv-0 cs-mh-20 cs-cursor-pointer cs-height-40-xs"
                    routerLinkActive="active"
                  >
                    <svg
                      class="sidebar-icon cs-width-20-xs cs-height-20-xs mr-2"
                      viewBox="0 0 18 18"
                    >
                      <use [attr.href]="'#' + groupItem.icon"></use>
                    </svg>

                    <span>{{ groupItem.name | language }}</span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="item.url">
          <a
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="item.url"
            class="d-flex group-link align-items-center cs-title-8 sidebar-menu-item cs-ph-10 cs-mv-0 cs-mh-20 cs-height-40-xs"
            routerLinkActive="active"
          >
            <svg
              class="sidebar-icon align-self-center cs-width-20-xs cs-height-20-xs mr-2 cs-mt-2"
              viewBox="0 0 20 20"
            >
              <use attr.href="{{ '#' + item.icon }}"></use>
            </svg>
            <span>{{ item.name | language }}</span>
          </a>
        </ng-container>
      </li>
    </ul>
  </nav>
  <ng-content></ng-content>
</aside>

<ng-template #loader>
  <div class="d-flex align-items-center justify-content-center h-100">
    <span [huiLoading]="true"></span>
  </div>
</ng-template>
