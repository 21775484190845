import { UserImpersonateLogoutEvent } from '@app.cobiro.com/core/events';
import { UserImpersonationState } from '../application/user-impersonation.state';
import { Injectable } from '@angular/core';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class UserImpersonateLogoutEventHandler implements ApplicationEventHandler {
  eventClass = UserImpersonateLogoutEvent;

  constructor(private _state: UserImpersonationState) {}

  handle(event: UserImpersonateLogoutEvent) {
    this._state.clearImpersonation();
  }
}
