import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableDebugTools } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { Environment } from '../../../environments/environment.config';
import { provideWindowConfig } from '../../../libs/core/utils/src/lib/config/window-config.service';

const environment: Environment = provideWindowConfig();

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://e5020f71538b4f19b18f41235efe8a44@o129251.ingest.sentry.io/1326863',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  environment: environment.sentryEnv,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/app\.staging-cobiro\.com/,
    /^https:\/\/app\.cobiro\.com/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (!environment.production) {
  bootstrap().then(moduleRef => {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const appComponent = applicationRef.components[0];
    enableDebugTools(appComponent);
  });
} else {
  bootstrap().catch(err => console.log(err));
}
