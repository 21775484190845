<section class="app-main">
  <lib-layout>
    <ng-container topBar>
      <ng-template #cobiroTopBar>
        <lib-top-bar
          *ngIf="topBarVisible$ | async"
          [class.cs-bb-none]="topBarBorderHidden$ | async"
        ></lib-top-bar>
      </ng-template>
      <lib-cobiro-pro-top-bar
        *ngIf="proModeTopbarEnabled$ | async; else cobiroTopBar"
      ></lib-cobiro-pro-top-bar>
    </ng-container>
    <router-outlet routerOutlet (activate)="onRouteActivated()"></router-outlet>
  </lib-layout>
</section>

<!-- The app-preloader class is there for a reason - please DO NOT remove it  -->
<div class="app-preloader" *ngIf="preloaderVisible$ | async">
  <span [huiLoading]="true"></span>
</div>

<ngx-guided-tour
  skipText="Skip tour"
  [progressIndicatorLocation]="guidedTourProgressIndicatorLocation"
  [progressIndicator]="guidedTourProgressIndicator"
  data-selector="lib-tour"
  lib-tour
></ngx-guided-tour>
<ng-template
  #guidedTourProgressIndicator
  let-currentStepNumber="currentStepNumber"
  let-totalSteps="totalSteps"
>
  Step {{ currentStepNumber }} of {{ totalSteps }}
</ng-template>
