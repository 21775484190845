import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { CREATES_NON_CSS_INTEGRATION_DTO } from '../../../../application/ports/secondary/creates-non-css-integration.dto-port';
import { GETS_GOOGLE_ADS_LINKING_STATUS_DTO_PORT } from '../../../../application/ports/secondary/gets-google-ads-linking-status.dto-port';
import { GETS_INTEGRATION_DTO } from '../../../../application/ports/secondary/gets-integration.dto-port';
import { LINKS_GOOGLE_ADS_ACCOUNT_DTO_PORT } from '../../../../application/ports/secondary/links-google-ads-account.dto-port';
import { HttpComlynIntegrationService } from './http-comlyn-integration.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpComlynIntegrationService,
    {
      provide: GETS_GOOGLE_ADS_LINKING_STATUS_DTO_PORT,
      useExisting: HttpComlynIntegrationService,
    },
    {
      provide: LINKS_GOOGLE_ADS_ACCOUNT_DTO_PORT,
      useExisting: HttpComlynIntegrationService,
    },
    {
      provide: CREATES_NON_CSS_INTEGRATION_DTO,
      useExisting: HttpComlynIntegrationService,
    },
    {
      provide: GETS_INTEGRATION_DTO,
      useExisting: HttpComlynIntegrationService,
    },
  ],
})
export class HttpComlynIntegrationServiceModule {}
