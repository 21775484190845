import { InjectionToken } from '@angular/core';
import { WorkspaceDto } from '../secondary/workspace.dto';

export interface SetsWorkspacesCommandPort {
  setWorkspaces(items: WorkspaceDto[]);
}

export const SETS_WORKSPACES_COMMAND = new InjectionToken<SetsWorkspacesCommandPort>(
  'SETS_WORKSPACES_COMMAND',
);
