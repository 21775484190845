import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { TeamSelectedEvent } from '@app.cobiro.com/core/events';
import { take } from 'rxjs';

@Injectable()
export class TeamSelectedEventHandler {
  eventsClasses = [TeamSelectedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _router: Router, private readonly _activatedRoute: ActivatedRoute) {}

  handle([event]: [TeamSelectedEvent]): void {
    this._activatedRoute.queryParams
      .pipe(take(1))
      .subscribe(res =>
        this._router.navigate(['/', 'connect', event.teamId, 'home'], { queryParams: res }),
      );
  }
}
