import { SignInFailedEvent } from '@app.cobiro.com/core/events';
import { UserLoginState } from '../application/state/user-login.state';
import { Injectable } from '@angular/core';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class UserSignInFailedEventHandler implements ApplicationEventHandler {
  eventClass = SignInFailedEvent;
  constructor(private _userLoginState: UserLoginState) {}
  handle(event: SignInFailedEvent) {
    this._userLoginState.finishLoginWithError(event.errorMessage);
  }
}
