/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDeleteUserModalComponentModule } from './confirm-delete-user-modal/confirm-delete-user-modal.component-module';
import { InviteUserModalComponentModule } from './invite-user-modal/invite-user-modal.component-module';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    ConfirmDeleteUserModalComponentModule,
    InviteUserModalComponentModule,
  ],
})
export class UserModalsModule {}
