import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsState } from '../../../application/notifications.state';
import { NotificationListElementQuery } from '../../../query/notification-list-element.query';
import { combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'lib-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notificationsList$ = this._state.notificationsList$;

  constructor(private _route: ActivatedRoute, private _state: NotificationsState) {}

  ngOnInit() {
    combineLatest([this.notificationsList$, this._route.paramMap])
      .pipe(
        filter(
          ([list, paramMap]) =>
            list.length > 0 &&
            paramMap.has('id') &&
            !!list.find(notification => notification.id === paramMap.get('id')),
        ),
        take(1),
        tap(([list, paramMap]) => {
          this._openNotification(paramMap.get('id'));
        }),
      )
      .subscribe();
  }

  trackById(element: NotificationListElementQuery): string {
    return element.id;
  }

  onElementClicked(notification: NotificationListElementQuery): void {
    this._openNotification(notification.id);
  }

  private _openNotification(id: string): void {
    this._state.openNotification(id);
  }
}
