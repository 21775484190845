import { InjectionToken } from '@angular/core';

export interface SetsUserDataCommandPort {
  setUserData(userData: {
    firstName?: string;
    lastName?: string;
    email: string;
    avatar?: string;
  }): void;
}

export const SETS_USER_DATA_COMMAND = new InjectionToken<SetsUserDataCommandPort>(
  'SETS_USER_DATA_COMMAND',
);
