<ng-container *ngIf="siteId$ | async as siteId">
  <button
    class="cs-btn cs-btn-ghost cs-ph-15 d-flex justify-content-center align-items-center position-relative"
    [libAnalytics]="['notification-widget', 'notification-bell']"
    [mat-menu-trigger-for]="notificationsList"
  >
    <mat-icon svgIcon="bell"></mat-icon>
    <span
      *ngIf="unreadNotificationsCount$ | async as unreadNotificationsCount"
      class="notification-widget-dot cs-b-1 cs-b-shade-0"
    >
      {{ unreadNotificationsCount }}
    </span>
  </button>
  <mat-menu
    #notificationsList="matMenu"
    xPosition="before"
    yPosition="below"
    backdropClass="cs-mat-menu-backdrop"
    class="cs-mat-menu"
  >
    <p class="cs-text-2 cs-p-15">
      {{ '_notifications' | language | uppercase }}
    </p>
    <ng-container *ngIf="notificationsList$ | async as notificationsList">
      <ul
        class="ul-reset w-100 notification-widget-list"
        *ngIf="notificationsList.length; else noNotifications"
      >
        <li *ngFor="let notification of notificationsList; trackBy: trackByFn" class="cs-p-0">
          <lib-notification-list-element
            [libAnalytics]="['notification-widget', 'message-open']"
            [notification]="notification"
            (elementClicked)="openNotification($event)"
          ></lib-notification-list-element>
        </li>
      </ul>
      <hr class="cs-divider cs-mt-10" />
      <a
        class="d-flex justify-content-center align-item-center cs-pv-15 cs-cursor-pointer"
        [routerLink]="['site', siteId, 'notifications']"
        [libAnalytics]="['notifications-widget', 'see-all-notifications']"
      >
        <span class="cs-link"> {{ 'notifications_see_all' | language }}</span>
      </a>
      <ng-template #noNotifications>
        <p class="cs-text-2 cs-ph-30 cs-pv-30 text-center">
          {{ 'notifications_no_notifications' | language }}
        </p>
      </ng-template>
    </ng-container>
  </mat-menu>
</ng-container>
