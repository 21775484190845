import { Component, Inject } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { NavigationItemQuery } from '../../../query/navigation-item.query';
import { map } from 'rxjs/operators';
import { TOP_BAR_STATE_GETTER, TopBarStateGetter } from '../../../application/top-bar.state';

@Component({
  selector: 'lib-navigation-dropdown',
  templateUrl: './navigation-dropdown.component.html',
  styleUrls: ['./navigation-dropdown.component.scss'],
  host: { class: 'd-block' },
})
export class NavigationDropdownComponent {
  navigationItems$: Observable<NavigationItemQuery[]> = combineLatest([
    this._topBarState.navigationItems$,
    this._topBarState.siteId$,
  ]).pipe(
    map(([navigationItems, siteId]) => {
      return navigationItems
        .map(navigationItem => NavigationItemQuery.fromNavigationItem(navigationItem, siteId))
        .filter(navigationItem => Boolean(navigationItem.url));
    }),
  );

  hasUpgradeButton$: Observable<boolean> = this._topBarState.hasUpgradeButton$;

  constructor(@Inject(TOP_BAR_STATE_GETTER) private _topBarState: TopBarStateGetter) {}

  onUpgradeButtonClicked(): void {
    this._topBarState.updatePlan();
  }
}
