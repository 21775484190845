import { Inject, Injectable } from '@angular/core';
import { CobiroProClientSelectedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import {
  SELECTS_CLIENT_COMMAND,
  SelectsClientCommandPort,
} from '../../../application/ports/primary/selects-client.command-port';

@Injectable()
export class ClientsHistoryClientSelectedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProClientSelectedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SELECTS_CLIENT_COMMAND) private readonly _selectsClient: SelectsClientCommandPort,
  ) {}

  handle([event]: [CobiroProClientSelectedEvent]): void {
    this._selectsClient
      .selectClient(
        event.payload
          ? {
              id: event.payload.id,
              sitePublicId: event.payload.sitePublicId,
              siteId: event.payload.siteId,
              name: event.payload.name,
              avatar: event.payload.avatar,
              teamId: event.payload.teamId,
            }
          : null,
      )
      .subscribe();
  }
}
