import { Inject, Injectable } from '@angular/core';
import { TeamSelectedEvent } from '@app.cobiro.com/core/events';
import { CreatesTeamCommandPort } from '../ports/primary/creates-team-command.port';
import { ADDS_TEAM_DTO, AddsTeamDtoPort } from '../ports/secondary/adds-team-dto.port';
import {
  CobiroProTeamCreatedEvent,
  CobiroProTeamsLoadedEvent,
  UUID,
} from '@app.cobiro.com/core/events';
import { BehaviorSubject, mapTo, Observable, of } from 'rxjs';
import { GetsAllTeamQueryPort } from '../ports/primary/gets-all-team-query.port';
import { TeamQuery } from '../ports/primary/team.query';
import {
  concatMap,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { TeamDTO, USER_ROLE } from '../ports/secondary/team.dto';
import { GETS_ALL_TEAMS_DTO, GetsAllTeamsDtoPort } from '../ports/secondary/gets-all-team-dto.port';
import { HasTeamQueryPort } from '../ports/primary/has-team-query.port';
import { SetCurrentTeamCommand } from '../ports/primary/set-current-team.command';
import { SetCurrentTeamCommandPort } from '../ports/primary/set-current-team-command.port';
import { GetsCurrentTeamQueryPort } from '../ports/primary/gets-current-team-query.port';
import { APPLICATION_BUS, ApplicationBus, Dispatcher } from '@cobiro/eda';
import { LoadTeamsCommandPort } from '../ports/primary/load-teams-command.port';
import { IsTeamListLoadingQueryPort } from '../ports/primary/is-team-list-loading-query.port';
import { BackToCurrentTeamCommandPort } from '../ports/primary/back-to-current-team-command.port';
import { Router } from '@angular/router';
import {
  CobiroProContextQuery,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
} from '@app.cobiro.com/cobiro-pro/context';
import { UpdatesTeamCommandPort } from '../ports/primary/updates-team.command-port';
import {
  CobiroProTeamsLoadedDispatcherPort,
  TEAMS_LOADED_DISPATCHER,
} from '../ports/secondary/cobiro-pro-teams-loaded.dispatcher-port';
import { COBIRO_PRO_TEAM_CREATED_DISPATCHER } from '../ports/secondary/cobiro-pro-team-created.dispatcher-port';

@Injectable()
export class TeamsState
  implements
    CreatesTeamCommandPort,
    GetsAllTeamQueryPort,
    HasTeamQueryPort,
    SetCurrentTeamCommandPort,
    GetsCurrentTeamQueryPort,
    GetsCurrentTeamQueryPort,
    LoadTeamsCommandPort,
    IsTeamListLoadingQueryPort,
    BackToCurrentTeamCommandPort,
    UpdatesTeamCommandPort
{
  private _allTeamsSubject$: BehaviorSubject<TeamDTO[] | null> = new BehaviorSubject(null);
  private _selectedTeamSubject$: BehaviorSubject<TeamDTO | null> = new BehaviorSubject(null);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  private _allTeams$ = this._allTeamsSubject$.asObservable();

  readonly isLoading$ = this._isLoading$.asObservable();

  constructor(
    @Inject(ADDS_TEAM_DTO) private _addsTeam: AddsTeamDtoPort,
    @Inject(GETS_ALL_TEAMS_DTO) private _getAllTeam: GetsAllTeamsDtoPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    // TODO: (PRO-DEBT) query used in state
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private _setsSelectedTeamCommand: SetsSelectedTeamCommandPort,
    @Inject(TEAMS_LOADED_DISPATCHER)
    private readonly _teamsLoadedDispatcher: CobiroProTeamsLoadedDispatcherPort,
    @Inject(COBIRO_PRO_TEAM_CREATED_DISPATCHER)
    private readonly _cobiroProTeamCreatedDispatcherPort: Dispatcher<CobiroProTeamCreatedEvent>,
    private readonly _router: Router,
  ) {}

  updateTeam(id: string, name: string, avatar: string): void {
    this._allTeams$
      .pipe(
        take(1),
        tap((allTeams: TeamDTO[]) => {
          const updatedTeams = allTeams.map(team => {
            if (team.id !== id) {
              return team;
            }
            return { ...team, name, avatar };
          });
          this._allTeamsSubject$.next(updatedTeams);
          this._dispatchTeamsLoadedEvent(updatedTeams);
        }),
        withLatestFrom(this._getsCobiroProContextQuery.getContext()),
        concatMap(([allTeams, context]: [TeamDTO[], CobiroProContextQuery]) => {
          if (id === context.selectedTeamId) {
            const updatedTeam = allTeams.find(team => id === team.id);
            return this._setsSelectedTeamCommand.setSelectedTeam(
              id,
              name,
              updatedTeam.role,
              avatar,
            );
          }

          return of(false);
        }),
      )
      .subscribe();
  }

  loadAll() {
    this._load();
  }

  loadByCriteria(criteria: { [p: string]: number | string }): void {
    this._load(criteria);
  }

  createTeam(name: string): void {
    this._addsTeam
      .add({
        id: new UUID().value,
        name,
        role: USER_ROLE.owner,
        avatar: null,
        membersCount: 1,
      })
      .pipe(
        tap(() => {
          this._cobiroProTeamCreatedDispatcherPort.dispatch(new CobiroProTeamCreatedEvent(name));
          this._router.navigate(['/pro']);
        }),
      )
      .subscribe();
  }

  setCurrentTeam(command: SetCurrentTeamCommand): void {
    const currentTeam = this._selectedTeamSubject$.getValue();

    if (currentTeam?.id === command.teamId) {
      return;
    }

    this._allTeams$
      .pipe(
        filter(t => !!t),
        take(1),
        switchMap((teams: TeamDTO[]) => {
          const selectedTeam = teams.find(team => team.id === command.teamId);
          this._selectedTeamSubject$.next(selectedTeam);
          return this._setsSelectedTeamCommand
            .setSelectedTeam(
              selectedTeam.id,
              selectedTeam.name,
              selectedTeam.role,
              selectedTeam.avatar,
            )
            .pipe(mapTo(selectedTeam.id));
        }),
        tap(selectedTeamId => this._applicationBus.dispatch(new TeamSelectedEvent(selectedTeamId))),
        mapTo(void 0),
      )
      .subscribe();
  }

  hasTeam(): Observable<boolean> {
    return this._allTeams$.pipe(map(teams => teams && teams.length > 0));
  }

  getAllTeamQuery(): Observable<TeamQuery[]> {
    return this._allTeams$.pipe(
      map(teams => teams && teams.map(team => TeamQuery.fromTeamDTO(team))),
    );
  }

  getCurrentTeamQuery(): Observable<TeamQuery> {
    return this._getsCobiroProContextQuery
      .getContext()
      .pipe(
        map(context =>
          context.selectedTeamId
            ? new TeamQuery(
                context.selectedTeamId,
                context.selectedTeamName,
                context.selectedTeamAvatar,
              )
            : undefined,
        ),
      );
  }

  back(): void {
    this._getsCobiroProContextQuery
      .getContext()
      .pipe(
        take(1),
        filter(context => !!context?.selectedTeamId),
        tap(context => {
          this._router.navigate(['/pro', context.selectedTeamId, 'clients']);
        }),
      )
      .subscribe();
  }

  private _load(criteria = {}): void {
    this._getAllTeam
      .getsAll(criteria)
      .pipe(finalize(() => this._isLoading$.next(false)))
      .subscribe((teams: TeamDTO[]) => {
        this._allTeamsSubject$.next(teams);
        this._dispatchTeamsLoadedEvent(teams);
      });
  }

  private _dispatchTeamsLoadedEvent(teamsDto: TeamDTO[]) {
    this._teamsLoadedDispatcher.dispatch(
      new CobiroProTeamsLoadedEvent(
        teamsDto.map(teamDto => ({
          id: teamDto.id,
          name: teamDto.name,
          avatar: teamDto.avatar,
          role: teamDto.role,
          membersCount: teamDto.membersCount,
        })),
      ),
    );
  }
}
