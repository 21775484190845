<div class="row cs-ph-10 cs-mt-20">
  <div class="w-100">
    <h3 class="cs-title-5 cs-mb-15">
      {{ 'cobiro_pro_faq_css_title' | language }}
    </h3>
    <div class="w-100" *huiLoaderContainer="questions$ | async as questions">
      <mat-accordion class="cs-mat-accordion">
        <mat-expansion-panel
          *ngFor="let question of questions"
          class="cs-pv-0 cs-mb-0 cs-mat-expansion-panel"
          hideToggle
          (opened)="question.isOpened = true"
          (closed)="question.isOpened = false"
        >
          <mat-expansion-panel-header
            class="d-flex align-items-center cs-mat-expansion-panel-header br-0"
          >
            <mat-panel-title
              class="font-weight-normal cs-ph-15 cs-cursor-pointer d-flex align-items-center cs-mr-0"
            >
              {{ question.title }}
            </mat-panel-title>
            <mat-panel-description>
              <button class="cs-btn cs-btn-sm cs-btn-flat" type="button">
                {{
                  (question.isOpened ? 'cobiro_pro_faq_btn_open' : 'cobiro_pro_faq_btn_close')
                    | language
                }}
              </button></mat-panel-description
            >
          </mat-expansion-panel-header>
          <p class="cs-ph-15 question-body" [innerHTML]="question.body"></p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
