import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { UsersListChangedEventHandler } from '../adapters/primary/handlers/users-list-changed.event-handler';
import { HttpUsersServiceModule } from '../adapters/secondary/infrastructure/http-users.service-module';
import { InMemoryUsersContextStorageModule } from '../adapters/secondary/infrastructure/in-memory-users-context.storage-module';
import { LatestUsersStateModule } from '../application/state/latest-users.state-module';
import { UserListStateModule } from '../application/state/user-list.state-module';
import { UsersStateModule } from '../application/state/user.state-module';

@NgModule({
  imports: [
    UsersStateModule,
    LatestUsersStateModule,
    UserListStateModule,
    HttpUsersServiceModule,
    InMemoryUsersContextStorageModule,
  ],
  providers: [provideApplicationEventsHandler(UsersListChangedEventHandler)],
})
export class CobiroProRewriteUserProvidersModule {}
