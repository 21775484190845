import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerIntegrationComponent } from './label-manager-integration.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LabelManagerResultsComponentModule } from '../label-manager-results/label-manager-results.component-module';
import { LabelManagerAttributesComponentModule } from '../label-manager-attributes/label-manager-attributes.component-module';
import { LabelManagerLinkingResultComponentModule } from '../label-manager-linking-result/label-manager-linking-result.component-module';
import { LabelManagerStatusComponentModule } from '../label-manager-status/label-manager-status.component-module';
import { LabelManagerSupportComponentModule } from '../label-manager-support/label-manager-support.component-module';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { LabelManagerRecommendationsComponentModule } from '../label-manager-recommendations/label-manager-recommendations.component-module';

@NgModule({
  declarations: [LabelManagerIntegrationComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    LabelManagerResultsComponentModule,
    LabelManagerLinkingResultComponentModule,
    LabelManagerAttributesComponentModule,
    LabelManagerStatusComponentModule,
    LabelManagerSupportComponentModule,
    LabelManagerRecommendationsComponentModule,
  ],
  exports: [LabelManagerIntegrationComponent],
})
export class LabelManagerIntegrationComponentModule {}
