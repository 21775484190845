import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { tap } from 'rxjs';
import {
  ClosesLMRecommendationQueryPort,
  CLOSES_LM_RECOMMENDATION_QUERY,
} from '../../../../application/ports/primary/closes-lm-recommendation.query-port';
import {
  GetsCurrentLMRecommendationQueryPort,
  GETS_CURRENT_LM_RECOMMENDATION_QUERY,
} from '../../../../application/ports/primary/gets-current-lm-recommendation.query-port';
import { LabelManagerRecommendationQuery } from '../../../../application/ports/primary/label-manager-recomendation.query';

@Component({
  selector: 'lib-cobiro-label-manager-recommendations',
  templateUrl: './label-manager-recommendations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerRecommendationsComponent {
  readonly messageList$ = this._getsCurrentLMRecommendationQueryPort
    .getCurrentLMRecommendation()
    .pipe(tap(res => (this.messageList = res)));

  messageList: LabelManagerRecommendationQuery[] = null;

  constructor(
    @Inject(GETS_CURRENT_LM_RECOMMENDATION_QUERY)
    private readonly _getsCurrentLMRecommendationQueryPort: GetsCurrentLMRecommendationQueryPort,
    @Inject(CLOSES_LM_RECOMMENDATION_QUERY)
    private readonly _closesLMRecommendationQueryPort: ClosesLMRecommendationQueryPort,
  ) {}

  onCloseBtnClicked(item: LabelManagerRecommendationQuery): void {
    const index = this.messageList.indexOf(item);
    const x = this.messageList.splice(index, 1);
    this._closesLMRecommendationQueryPort.closeLmRecommendation(item);
  }
}
