import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { TOP_BAR_STATE_GETTER, TopBarStateGetter } from '../../application/top-bar.state';
import { UserMenuDetails, UserMenuItem } from '@app.cobiro.com/shared/hui/user-menu';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopBarComponent {
  public readonly userMenuDetails$: Observable<UserMenuDetails> = this._topBarState.user$.pipe(
    map(userData => {
      return {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        avatar: userData.avatar,
      };
    }),
  );

  public readonly userMenuItems: UserMenuItem[] = [
    {
      routerLink: ['/sites'],
      translationKey: 'nav_top_bar_user_menu_my_sites',
    },
    {
      routerLink: ['/settings'],
      translationKey: 'nav_top_bar_user_menu_settings',
    },
    {
      routerLink: ['/user/logout'],
      translationKey: 'nav_top_bar_user_menu_logout',
    },
  ];

  constructor(@Inject(TOP_BAR_STATE_GETTER) private _topBarState: TopBarStateGetter) {}
}
