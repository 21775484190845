import { NgModule } from '@angular/core';
import { GETS_SELECTED_WORKSPACE_QUERY } from '../ports/primary/gets-selected-workspace.query-port';
import { GETS_WORKSPACES_QUERY } from '../ports/primary/gets-workspaces.query';
import { INCREMENTS_WORKSPACE_MEMBERS_COUNT_COMMAND } from '../ports/primary/increments-workspace-members-count-command.port';
import { LOADS_WORKSPACES_QUERY } from '../ports/primary/loads-workspaces.query.port';
import { SETS_WORKSPACES_COMMAND } from '../ports/primary/sets-workspaces-command.port';
import { WorkspaceSwitcherState } from './workspace-switcher.state';

@NgModule({
  providers: [
    WorkspaceSwitcherState,
    {
      provide: GETS_SELECTED_WORKSPACE_QUERY,
      useExisting: WorkspaceSwitcherState,
    },
    {
      provide: SETS_WORKSPACES_COMMAND,
      useExisting: WorkspaceSwitcherState,
    },
    {
      provide: GETS_WORKSPACES_QUERY,
      useExisting: WorkspaceSwitcherState,
    },
    {
      provide: INCREMENTS_WORKSPACE_MEMBERS_COUNT_COMMAND,
      useExisting: WorkspaceSwitcherState,
    },
    {
      provide: LOADS_WORKSPACES_QUERY,
      useExisting: WorkspaceSwitcherState,
    },
  ],
})
export class WorkspaceSwitcherStateModule {}
