import { Injectable } from '@angular/core';
import { PaymentPlansState } from '../../../application/state/payment-plans.state';
import { UpgradeRequestedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class PaymentPlanRequestedEventHandler implements ApplicationEventHandler {
  eventClass = UpgradeRequestedEvent;

  constructor(private _paymentPlansState: PaymentPlansState) {}

  handle(event: UpgradeRequestedEvent): void {
    this._paymentPlansState.startUpgradeFlow(event.featureId);
  }
}
