import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordChangedEvent, SignInCompletedEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class UserPasswordChangedEventHandler implements ApplicationEventHandler {
  eventClass = PasswordChangedEvent;

  constructor(private _router: Router, private _alert: HuiAlert) {}

  handle(event: SignInCompletedEvent) {
    this._router
      .navigate(['auth/login'])
      .then(() => this._alert.open('success', 'login_password_changed'));
  }
}
