import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GETS_INSTALLED_APPS_IDS } from '@app.cobiro.com/core/application/secondary-ports/installations';
import { InstallationsService } from './installations/installations.service';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: GETS_INSTALLED_APPS_IDS, useClass: InstallationsService }],
})
export class SharedSecondaryAdaptersModule {}
