import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface IncrementsWorkspaceMembersCountCommandPort {
  incrementWorkspaceMembersCount(workspaceId: string): Observable<void>;
}

export const INCREMENTS_WORKSPACE_MEMBERS_COUNT_COMMAND =
  new InjectionToken<IncrementsWorkspaceMembersCountCommandPort>(
    'INCREMENTS_WORKSPACE_MEMBERS_COUNT_COMMAND',
  );
