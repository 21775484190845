
import { Inject, Injectable } from '@angular/core';
import {
  LOAD_USER_ADDITIONAL_DATA_COMMAND,
  LoadUserAdditionalDataCommandPort,
} from '../../application/ports/primary/load-user-additional-data-command.port';

@Injectable()
export class LoadUserAdditionalDataResolver  {
  constructor(
    @Inject(LOAD_USER_ADDITIONAL_DATA_COMMAND)
    private readonly _loadUserAdditionalDataCommand: LoadUserAdditionalDataCommandPort,
  ) {}

  resolve(): void {
    this._loadUserAdditionalDataCommand.loadUserAdditionalData();
  }
}
