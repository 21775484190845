<div class="container cs-pt-40 h-100">
  <div class="notifications-container h-100 mx-auto d-grid cs-max-width-700-xs">
    <h2 class="cs-title-5 cs-pb-20">{{ '_notifications' | language }}</h2>
    <ng-container *ngIf="notificationsList$ | async as notifications">
      <div
        class="br-5 mb-auto cs-bg-shade-0 cs-b-1 cs-b-shade-10 cs-shadow-shade-10"
        [class.h-100]="notifications.length === 0"
      >
        <ng-container *ngIf="notifications.length > 0; else noElements">
          <lib-notification-list-element
            [notification]="element"
            (elementClicked)="onElementClicked($event)"
            *ngFor="let element of notifications; trackBy: trackById"
          >
          </lib-notification-list-element>
        </ng-container>
        <ng-template #noElements>
          <p class="cs-text-1 notifications-no-elements text-center">
            {{ 'notifications_no_notifications' | language }}
          </p>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
