import { UserChangedEvent } from '@app.cobiro.com/core/events';
import { Inject, Injectable } from '@angular/core';
import { TOP_BAR_STATE_SETTER, TopBarStateSetter } from '../application/top-bar.state';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class TopBarUserChangedEventHandler implements ApplicationEventHandler {
  eventClass = UserChangedEvent;

  constructor(@Inject(TOP_BAR_STATE_SETTER) private _topBarState: TopBarStateSetter) {}

  handle(event: UserChangedEvent) {
    this._topBarState.setUser({
      id: event.user.id,
      firstName: event.user.firstName,
      lastName: event.user.lastName,
      email: event.user.email,
      avatar: event.user.avatar,
    });
  }
}
