<ng-container *ngIf="messageList$ | async">
  <div class="cs-card cs-mb-10 d-flex flex-column cs-pt-5" *ngFor="let item of messageList">
    <mat-icon
      svgIcon="close"
      class="position-relative cs-top-0 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end cs-mb-10"
      data-selector="cobiro-pro-create-client-close-modal"
      (click)="onCloseBtnClicked(item)"
    ></mat-icon>
    <div class="d-flex justify-content-between align-items-start w-100 cs-mb-10">
      <h6 class="cs-title-6">
        {{ item.title | language }}
      </h6>
      <span
        *ngIf="item.type === 'alert'; else warning"
        data-selector="label-manager-recommendation"
        class="cs-badge status-badge cs-pv-9 cs-text-2 br-5 d-inline-block cs-badge-success"
        >{{ 'cobiro_pro_label_manager_recommendation' | language }}</span
      >

      <ng-template #warning>
        <span
          data-selector="label-manager-warning"
          class="cs-badge status-badge cs-pv-9 cs-text-2 br-5 d-inline-block cs-badge-error cs-ml-20"
          >{{ 'cobiro_pro_label_manager_warning' | language }}</span
        >
      </ng-template>
    </div>
    <p class="cs-text-2 flex-1">
      {{ item.description | language : (item.descriptionValue ? item.descriptionValue : '') }}
    </p>
  </div>
</ng-container>
