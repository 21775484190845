import { ManagesVisitedClientsDtoPort } from '../../application/ports/secondary/manages-visited-clients.dto-port';
import { VisitedClientDto } from '../../application/ports/secondary/visited-client.dto';
import { Inject, Injectable } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';

@Injectable()
export class LocalStorageVisitedClientsService implements ManagesVisitedClientsDtoPort {
  private readonly _recentClientsKey = 'cobiro-pro-recent-clients';

  constructor(@Inject(STORAGE) private readonly _localStorage: Storage) {}

  setClients(dtos: VisitedClientDto[]) {
    this._localStorage.setItem(this._recentClientsKey, JSON.stringify(dtos));
  }

  getClients(): VisitedClientDto[] {
    const clients = JSON.parse(this._localStorage.getItem('cobiro-pro-recent-clients')) || [];
    return clients.map(client => ({ ...client, dateAdded: new Date(client.dateAdded) }));
  }

  clear(): void {
    this._localStorage.removeItem(this._recentClientsKey);
  }
}
