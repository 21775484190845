import { NgModule } from '@angular/core';
import { LOAD_USERS_COMMAND } from '../ports/primary/load-users.command-port';
import { UserListState } from './user-list.state';
import { USERS_LIST_CHANGED_DISPATCHER } from '../ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import { UsersListChangedEventDispatcher } from '../../adapters/secondary/dispatchers/users-list-changed.event-dispatcher';
import { GETS_USERS_LIST_QUERY } from '../ports/primary/gets-users-list.query-port';
import { SETS_USERS_LIST_SEARCH_PHRASE_COMMAND_PORT } from '../ports/primary/sets-search-phrase.command-port';

@NgModule({
  providers: [
    UserListState,
    { provide: LOAD_USERS_COMMAND, useExisting: UserListState },
    { provide: GETS_USERS_LIST_QUERY, useExisting: UserListState },
    { provide: SETS_USERS_LIST_SEARCH_PHRASE_COMMAND_PORT, useExisting: UserListState },
    { provide: USERS_LIST_CHANGED_DISPATCHER, useClass: UsersListChangedEventDispatcher },
  ],
})
export class UserListStateModule {}
