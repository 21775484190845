<ng-container *ngIf="teamSwitcherItems$ | async as teamSwitcherItems">
  <button
    class="cs-mat-menu-trigger"
    data-selector="teams-switcher-widget-opener"
    *ngIf="teamSwitcherItems.length > 0"
    [class.cs-bg-shade-6]="isWidgetOpened"
    [matMenuTriggerFor]="menu"
    (menuOpened)="isWidgetOpened = true"
    (menuClosed)="isWidgetOpened = false"
  >
    <mat-icon
      class="cs-transition-regular-ease"
      svgIcon="chevron-down"
      [class.cs-rotate-180]="isWidgetOpened"
    >
    </mat-icon>
  </button>

  <mat-menu
    #menu="matMenu"
    xPosition="after"
    yPosition="below"
    backdropClass="cs-mat-menu-backdrop"
    class="cs-mat-menu"
  >
    <div
      mat-menu-item
      (click)="onPersonalWorkspaceClicked()"
      class="cs-mat-menu-item-adjustable-height d-flex cs-cursor-pointer align-items-center justify-content-between cs-min-width-240-xs"
    >
      <div class="d-flex align-items-center">
        <div class="cs-avatar cs-avatar-rounded">
          <div class="cs-size-md cs-asset-cobiro-logo cs-bg-size-cover"></div>
        </div>
        <div class="cs-ml-10">
          <p class="cs-title-8">Cobiro</p>
          <p class="cs-text-2 cs-c-shade-60">
            {{ 'cbr_pro_personal_space' | language }}
          </p>
        </div>
      </div>
      <mat-icon *ngIf="isInPersonalSpace$ | async" svgIcon="check-green" class="mr-0"> </mat-icon>
    </div>
    <a
      mat-menu-item
      class="cs-mat-menu-item-adjustable-height pr-0 d-flex align-items-center cs-min-width-240-xs cs-transition-regular-ease"
      *ngFor="let item of teamSwitcherItems; trackBy: trackById"
      [routerLink]="item.navigationUrl"
      (click)="onItemClicked(item)"
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <hui-avatar [name]="item.name" [avatarUrl]="item.avatar"> </hui-avatar>
          <div class="cs-ml-10">
            <p class="cs-title-8 cs-mr-auto">
              {{ item.name }}
            </p>
            <p class="cs-text-2 cs-c-shade-60">
              {{ '_cobiro_pro' | language }} - {{ item.membersCount }}
              {{
                (item.membersCount === 0 || item.membersCount > 1 ? '_members' : '_member')
                  | language
              }}
            </p>
          </div>
        </div>
        <mat-icon *ngIf="item.selected" svgIcon="check-green" class="mr-0"> </mat-icon>
      </div>
    </a>
  </mat-menu>
</ng-container>
