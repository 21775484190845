import { Component, ViewEncapsulation } from '@angular/core';
import { NotificationsState } from '../../application/notifications.state';
import { Observable } from 'rxjs';
import { NotificationListElementQuery } from '../../query/notification-list-element.query';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-notifications-widget',
  templateUrl: './notifications-widget.component.html',
  styleUrls: ['./notifications-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsWidgetComponent {
  unreadNotificationsCount$: Observable<number> = this._state.unreadNotificationsCount$;
  notificationsList$: Observable<NotificationListElementQuery[]> = this._state.notificationsList$;

  siteId$ = this._state.siteId$;

  constructor(private _state: NotificationsState, private _router: Router) {}

  trackByFn(notification: NotificationListElementQuery): string {
    return notification.id;
  }

  openNotification(notification: NotificationListElementQuery) {
    this._state.openNotification(notification.id);
  }
}
