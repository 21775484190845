import { NgModule } from '@angular/core';
import { LoadIntegrationResolver } from '../../../../cobiro-css/src/lib/adapters/primary/resolvers/load-integration.resolver';
import { HttpAppsCdnServiceModule } from '../adapters/secondary/infrastructure/http-apps-cdn/http-apps-cdn.service-module';
import { HttpClientsProductServiceModule } from '../adapters/secondary/infrastructure/http-clients-products/http-clients-products.service-module';
import { HttpComlynIntegrationServiceModule } from '../adapters/secondary/infrastructure/http-comlyn-integration/http-comlyn-integration.service-module';
import { HttpInstalledAppsServiceModule } from '../adapters/secondary/infrastructure/http-installed-apps/http-installed-apps.service-module';
import { HttpLabelManagerConfigServiceModule } from '../adapters/secondary/infrastructure/http-label-manager-config/http-label-manager-config.service-module';
import { HttpLabelManagerReportServiceModule } from '../adapters/secondary/infrastructure/http-label-manager-report/http-label-manager-report.service-module';
import { HttpLabelManagerSimulationServiceModule } from '../adapters/secondary/infrastructure/http-label-manager-simulation/http-label-manager-simulation.service-module';
import { LabelManagerIntegrationStateModule } from '../application/state/label-manager-integration.state-module';

@NgModule({
  imports: [
    LabelManagerIntegrationStateModule,
    HttpInstalledAppsServiceModule,
    HttpComlynIntegrationServiceModule,
    HttpAppsCdnServiceModule,
    HttpLabelManagerConfigServiceModule,
    HttpLabelManagerReportServiceModule,
    HttpClientsProductServiceModule,
    HttpLabelManagerSimulationServiceModule,
  ],
  providers: [LoadIntegrationResolver],
})
export class CobiroProRewriteLabelManagerProvidersModule {}
