import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RolesState } from '../core/application/roles.state';

@Directive({
  selector: '[libRoles]',
})
export class RolesDirective implements OnInit, OnDestroy {
  protected destroy$ = new Subject<void>();
  private elementViewRef: EmbeddedViewRef<never>;
  private elseTemplate: TemplateRef<never>;

  @Input() libRoles: string[];

  @Input()
  set libRolesElse(templateRef: TemplateRef<never> | null) {
    this.elseTemplate = templateRef;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<never>,
    private rolesState: RolesState,
  ) {}

  private show(): void {
    if (!this.elementViewRef) {
      this.elementViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  private hide(): void {
    this.viewContainerRef.clear();
    this.elementViewRef = null;
    if (this.elseTemplate) {
      this.elementViewRef = this.viewContainerRef.createEmbeddedView(this.elseTemplate);
    }
  }

  ngOnInit(): void {
    if (this.libRoles[0] === undefined) {
      this.show();
      return;
    }

    this.rolesState
      .hasRoles(this.libRoles)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hasRoles => {
        hasRoles ? this.show() : this.hide();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
