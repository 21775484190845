import { ActivatedRouteSnapshot, Data, Router, UrlTree } from '@angular/router';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GETS_FEATURE_USAGE, GetsFeatureUsage } from '../application/gets-feature-usage';
import { FeaturesState } from '../application/features.state';
import { concatMap, map } from 'rxjs/operators';

/**
 * Using this guard requires providing `featureId` and `backUrl` fields in the route data properties
 * Additional you need to provide a usage getter via GETS_FEATURE_USAGE token if the feature has limits
 */
@Injectable() // todo fix it for google-search tests
export class FeatureGuard  {
  constructor(
    @Optional() @Inject(GETS_FEATURE_USAGE) private _getsFeatureUsage: GetsFeatureUsage[],
    private _featuresState: FeaturesState,
    private _router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this._validateRouteData(route.data, 'featureId');
    this._validateRouteData(route.data, 'backUrl');
    const featureId = route.data.featureId.toString();
    const siteId = route.params.siteId;
    const redirectUrl = route.data.backUrl.toString().replace('{siteId}', siteId);
    const usage$ =
      this._getsFeatureUsage?.find(getter => getter.featureId === featureId)?.get(route) ||
      of(null);
    return usage$.pipe(
      concatMap(usage => this._featuresState.hasFeature$(featureId, usage)),
      map(hasFeature => (hasFeature ? true : this._router.createUrlTree([redirectUrl]))),
    );
  }

  private _validateRouteData(data: Data, key: string): boolean | never {
    if (!data[key]) {
      throw Error(`Route data param ${key} is missing`);
    }

    return true;
  }
}
