import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Injectable } from '@angular/core';
import { CobiroProLogoutEvent } from '@app.cobiro.com/core/events';
import { Router } from '@angular/router';

@Injectable()
export class CobiroProLogoutEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProLogoutEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _router: Router) {}

  handle([event]: [CobiroProLogoutEvent]) {
    this._router.navigate(['/sites']);
  }
}
