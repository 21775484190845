import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelManagerRecommendationQuery } from './label-manager-recomendation.query';

export const GETS_CURRENT_LM_RECOMMENDATION_QUERY =
  new InjectionToken<GetsCurrentLMRecommendationQueryPort>('GETS_CURRENT_LM_RECOMMENDATION_QUERY');

export interface GetsCurrentLMRecommendationQueryPort {
  getCurrentLMRecommendation(): Observable<LabelManagerRecommendationQuery[]>;
}
