/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  GetsSideBarOptionsQueryPort,
  SideBarOptionQuery,
} from '@app.cobiro.com/cobiro-pro-rewrite/layout';
import { Observable } from 'rxjs';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
} from '@app.cobiro.com/cobiro-pro/context';
import { map } from 'rxjs/operators';

@Injectable()
export class GetsSideBarOptionsService implements GetsSideBarOptionsQueryPort {
  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
  ) {}

  getSideBarOptions(): Observable<SideBarOptionQuery[]> {
    return this._getsCobiroProContextQuery.getContext().pipe(
      map(context => context?.selectedTeamId),
      map((teamId: string | null) => [
        {
          icon: 'home',
          type: 'internal',
          name: 'cobiro_pro_home',
          selector: 'home',
          path: `/connect/${teamId}/home`,
          exactMatch: false,
        },
        {
          icon: 'briefcase',
          type: 'internal',
          name: 'cobiro_pro_shops',
          selector: 'clients',
          path: `/connect/${teamId}/clients`,
          exactMatch: true,
        },
        {
          icon: 'users',
          type: 'internal',
          name: 'cobiro_pro_users',
          selector: 'members',
          path: `/connect/${teamId}/members`,
          exactMatch: true,
        },
        {
          icon: 'settings',
          type: 'internal',
          name: '_settings',
          selector: 'settings',
          path: `/connect/${teamId}/settings`,
          exactMatch: false,
        },
        {
          icon: 'cashback',
          type: 'internal',
          name: 'cobiro_pro_partner_program',
          selector: 'partner_program',
          path: `/connect/${teamId}/partner-program`,
          exactMatch: true,
        },
        {
          icon: 'tooltip-question-mark',
          type: 'internal',
          name: 'cobiro_pro_faq',
          selector: 'faq',
          path: `/connect/${teamId}/faq`,
          featureFlag: 'cobiro_pro_css_faq',
          exactMatch: false,
        },
        {
          icon: 'message',
          type: 'external',
          name: 'cobiro_pro_request_feature',
          selector: 'request_feature',
          path: `https://feedback.cobiro.com/b/feature-requests/`,
        },
      ]),
    );
  }
}
