<a
  class="hamburger d-block cs-width-50-xs cs-pv-5 cs-ph-15 cs-cursor-pointer"
  [matMenuTriggerFor]="menu"
  data-selector="nav-dropdown-trigger"
>
  <span class="line"></span>
</a>
<mat-menu
  #menu="matMenu"
  xPosition="before"
  yPosition="below"
  backdropClass="navigation-dropdown-backdrop"
  [overlapTrigger]="true"
>
  <ng-container *ngFor="let navItem of navigationItems$ | async">
    <a
      mat-menu-item
      *ngIf="navItem.isExternal"
      [href]="navItem.url"
      target="_blank"
      rel="noopener noreferrer"
      [libDataSelector]="'nav-dropdown-item-' + navItem.name"
    >
      {{ navItem.name | language }}
    </a>
    <a
      mat-menu-item
      *ngIf="!navItem.isExternal"
      [routerLink]="[navItem.url]"
      [libDataSelector]="'nav-dropdown-item-' + navItem.name"
    >
      {{ navItem.name | language }}
    </a>
  </ng-container>
  <a
    mat-menu-item
    *ngIf="hasUpgradeButton$ | async"
    data-selector="nav-dropdown-upgrade-button"
    (click)="onUpgradeButtonClicked()"
  >
    <mat-icon [svgIcon]="'crown'" class="cs-size-xs mr-2"></mat-icon>
    {{ '_upgrade' | language }}
  </a>
</mat-menu>
