import { InjectionToken, Provider } from '@angular/core';
import { PHRASE } from '../../../common/language/src/lib/application/port/phrase';
import { SERVICE_URLS } from '@cbr/services';
import { CONFIG_KEYS } from '../../../core/utils/src/lib/config/tokens';
import { WindowEnvConfig } from '../../../core/utils/src/lib/config/window-config.service';
import { TagManagerEnv } from '../../../../environments/environment.config';
import { HUB_URL } from '../../../common/hub/src/lib/hub';
import { GATEWAY_URL } from '../../../common/gateway/src/lib/gateway.token';

// TODO: (Kamil) Deprecated - All of these tokens are deprecated please start using ENV_CONFIG
export const CHARGEBEE_ENV = new InjectionToken<{ site: string; publishableKey: string }>(
  'CHARGEBEE_ENV',
);

export const FONTS_API_URL = new InjectionToken<string>('FONTS_API_URL');
export const CDN_URL = new InjectionToken<string>('CDN_URL');
export const PUBLIC_CDN_URL = new InjectionToken<string>('PUBLIC_CDN_URL');
export const ENV_NAME = new InjectionToken<string>('ENV_NAME');
export const IS_PROD = new InjectionToken<string>('IS_PROD');

export const ENV_PROVIDERS: Provider[] = [
  {
    provide: IS_PROD,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('production'),
    deps: [WindowEnvConfig],
  },
  {
    provide: ENV_NAME,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('name'),
    deps: [WindowEnvConfig],
  },
  {
    provide: HUB_URL,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('hub'),
    deps: [WindowEnvConfig],
  },
  {
    provide: GATEWAY_URL,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('gateway'),
    deps: [WindowEnvConfig],
  },
  {
    provide: CDN_URL,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('cdn'),
    deps: [WindowEnvConfig],
  },
  {
    provide: PUBLIC_CDN_URL,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('publicCdn'),
    deps: [WindowEnvConfig],
  },
  {
    provide: PHRASE,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('phrase'),
    deps: [WindowEnvConfig],
  },
  {
    provide: CHARGEBEE_ENV,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('chargebee'),
    deps: [WindowEnvConfig],
  },
  {
    provide: 'TAG_MANAGER_ENABLED',
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get<TagManagerEnv>('tagManager').enabled,
    deps: [WindowEnvConfig],
  },
  {
    provide: 'TAG_MANAGER_KEY',
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get<TagManagerEnv>('tagManager').key,
    deps: [WindowEnvConfig],
  },
  {
    provide: 'DATA_LAYER_CONTAINER',
    useValue: window,
  },
  {
    provide: FONTS_API_URL,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.get('googleEnv'),
    deps: [WindowEnvConfig],
  },
  {
    provide: SERVICE_URLS,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.services,
    deps: [WindowEnvConfig],
  },
  {
    provide: CONFIG_KEYS,
    useFactory: (envConfig: WindowEnvConfig) => envConfig.keys,
    deps: [WindowEnvConfig],
  },
];
