import { NgModule } from '@angular/core';
import { GETS_COUNTRIES_QUERY } from '../ports/primary/gets-countries.query-port';
import { GETS_USER_DETAILS_QUERY } from '../ports/primary/gets-user-details.query-port';
import { UPDATES_USER_DETAILS_QUERY } from '../ports/primary/updates-user-details.query-port';
import { UserDetailsState } from './user-details.state';

@NgModule({
  providers: [
    UserDetailsState,
    { provide: GETS_USER_DETAILS_QUERY, useExisting: UserDetailsState },
    { provide: UPDATES_USER_DETAILS_QUERY, useExisting: UserDetailsState },
    { provide: GETS_COUNTRIES_QUERY, useExisting: UserDetailsState },
  ],
})
export class UserDetailsStateModule {}
