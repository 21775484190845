import { Injectable } from '@angular/core';
import { UserState } from '../application/user.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RolesService {
  constructor(private userState: UserState) {}

  public getAll(): Observable<Set<string>> {
    return this.userState.user$.pipe(map(user => new Set([user.group])));
  }
}
