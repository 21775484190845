import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { GetsInstalledAppsIds } from '@app.cobiro.com/core/application/secondary-ports/installations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InstallationModel, InstallationModelAttributes } from './installations.model';

@Injectable()
export class InstallationsService implements GetsInstalledAppsIds {
  constructor(private readonly _client: GatewayClient) {}

  getAll(siteId: number): Observable<string[]> {
    return this._client
      .get(`v2/app-registry/${siteId}/installations`)
      .pipe(
        map(({ data }: { data: InstallationModel[] }) => this.getInstallationsFromModels(data)),
      );
  }

  private getInstallationsFromModels(installationModels: InstallationModel[]): string[] {
    return installationModels.map(
      ({ attributes }: { attributes: InstallationModelAttributes }) => attributes.app_identifier,
    );
  }
}
