export enum SidebarItems {
  home = 'home',
  build = 'build',
  promote = 'promote',
  grow = 'grow',
  store = 'store',
  profile = 'profile',
  notifications = 'notifications',
  billing = 'billing',
}
