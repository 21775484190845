/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  AddPaymentMethodCommand,
  AddsPaymentMethodCommandPort,
} from '../ports/primary/adds-payment-method.command-port';
import { WORKSPACE_ID_GETTER, WorkspaceIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import {
  catchError,
  concatMap,
  finalize,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  CREATES_PAYMENT_INTENT_DTO_PORT,
  CreatesPaymentIntentDtoPort,
} from '../ports/secondary/creates-payment-intent.dto-port';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { PaymentIntentDto } from '../ports/secondary/payment-intent.dto';
import {
  CONFIRMS_PAYMENT_METHOD_DTO_PORT,
  ConfirmsPaymentMethodDtoPort,
} from '../ports/secondary/confirms-payment-method.dto-port';
import { ConfirmPaymentMethodDto } from '../ports/secondary/confirm-payment-method.dto';
import { GetsPaymentMethodProcessingQueryPort } from '../ports/primary/gets-payment-method-processing.query-port';
import { GetsAddPaymentMethodSucceedQueryPort } from '../ports/primary/gets-add-payment-method-succeed.query-port';
import {
  CREATES_PAYMENT_SOURCE_DTO_PORT,
  CreatesPaymentSourceDtoPort,
} from '../ports/secondary/creates-payment-source.dto-port';
import { PAYMENT_SOURCE_STORAGE_DTO_PORT } from '../ports/secondary/payment-source-storage.dto-port';
import { InMemoryReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { PaymentSourceStorageItemDto } from '../ports/secondary/payment-source-storage-item.dto';
import { LoadsPaymentSourcesCommandPort } from '../ports/primary/loads-payment-sources.command-port';
import { GetsPaymentSourcesLoadingQueryPort } from '../ports/primary/gets-payment-sources-loading.query-port';
import { GetsPaymentSourcesQueryPort } from '../ports/primary/gets-payment-sources.query-port';
import { PaymentSourceQuery } from '../ports/primary/payment-source.query';
import { PaymentSourceCreatedDto } from '../ports/secondary/payment-source-created.dto';
import {
  GETS_PAYMENTS_SOURCES_DTO_PORT,
  GetsPaymentSourcesDtoPort,
} from '../ports/secondary/gets-payment-sources.dto-port';
import { PaymentSourceDto } from '../ports/secondary/payment-source.dto';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { GetsCardValidationQueryPort } from '../ports/primary/gets-card-validation-error.query-port';
import { PaymentMethodEvent } from '@app.cobiro.com/core/events';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { ConfirmReplaceCardMessageCommandPort } from '../ports/primary/confirm-replace-card-message-command.port';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmReplaceCardModalComponent } from '../../adapters/primary/ui/confirm-replace-card-modal/confirm-replace-card-modal.component';
import {
  ReplacePaymentMethodCommand,
  ReplacesPaymentMethodCommandPort,
} from '../ports/primary/replaces-payment-method.command-port';
import {
  ReplacesPaymentSourceDtoPort,
  REPLACES_PAYMENT_SOURCE_DTO_PORT,
} from '../ports/secondary/replaces-payment-source.dto-port';

interface PaymentIntent {
  id: string;
  status: string;
  amount: number;
  currencyCode: string;
  gatewayAccountId: string;
  gateway: string;
}

@Injectable()
export class PaymentMethodsState
  implements
    AddsPaymentMethodCommandPort,
    ReplacesPaymentMethodCommandPort,
    GetsPaymentMethodProcessingQueryPort,
    GetsAddPaymentMethodSucceedQueryPort,
    LoadsPaymentSourcesCommandPort,
    GetsPaymentSourcesLoadingQueryPort,
    GetsPaymentSourcesQueryPort,
    GetsCardValidationQueryPort,
    ConfirmReplaceCardMessageCommandPort
{
  private readonly _errorFields$ = new Subject<string>();
  private readonly _isProcessing$ = new BehaviorSubject<boolean>(false);
  private readonly _isPaymentSourcesLoading = new BehaviorSubject<boolean>(false);
  private readonly _isSuccess$ = new Subject<void>();

  constructor(
    @Inject(CREATES_PAYMENT_INTENT_DTO_PORT)
    private readonly _createPaymentIntentDto: CreatesPaymentIntentDtoPort,
    @Inject(CONFIRMS_PAYMENT_METHOD_DTO_PORT)
    private readonly _confirmsPaymentMethod: ConfirmsPaymentMethodDtoPort,
    @Inject(REPLACES_PAYMENT_SOURCE_DTO_PORT)
    private readonly _replacesPaymentSourceDtoPort: ReplacesPaymentSourceDtoPort,
    @Inject(CREATES_PAYMENT_SOURCE_DTO_PORT)
    private readonly _createsPaymentSourceDtoPort: CreatesPaymentSourceDtoPort,
    @Inject(PAYMENT_SOURCE_STORAGE_DTO_PORT)
    private readonly _paymentSourceStorageDtoPort: InMemoryReactiveSingleValueStorage<
      PaymentSourceStorageItemDto[]
    >,
    @Inject(GETS_PAYMENTS_SOURCES_DTO_PORT)
    private readonly _getsPaymentSourcesDtoPort: GetsPaymentSourcesDtoPort,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    private readonly _alert: HuiAlert,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    private readonly _matDialog: MatDialog,
  ) {}

  replacePaymentMethod(command: ReplacePaymentMethodCommand): Observable<boolean> {
    this._isProcessing$.next(true);
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return this._createPaymentIntent(workspaceId).pipe(
      map(paymentIntent => ({ paymentIntent, workspaceId })),
      concatMap(({ paymentIntent, workspaceId }) =>
        this._confirmPaymentMethod(command, paymentIntent).pipe(
          switchMap(success =>
            typeof success === 'boolean'
              ? this._replacesPaymentSourceDtoPort
                  .replacePaymentSource({
                    workspaceId,
                    paymentIntentId: paymentIntent.id,
                  })
                  .pipe(map(res => [res, paymentIntent.id]))
              : throwError(new Error(success)),
          ),
        ),
      ),
      tap({
        next: ([createdPaymentSourceDto, paymentIntentId]: [PaymentSourceCreatedDto, string]) => {
          const paymentSourceStorageItem: PaymentSourceStorageItemDto = {
            id: createdPaymentSourceDto.id,
            brand: createdPaymentSourceDto.cardBrand,
            lastDigits: createdPaymentSourceDto.lastDigits,
            cardOwner: command.cardOwner,
          };
          this._paymentSourceStorageDtoPort.save(Array.of(paymentSourceStorageItem));
          this._isSuccess$.next(void 0);
          this._isProcessing$.next(false);
          this._alert.open('success', 'cobiro_pro_settings_replace_payment_method_succeed');
        },
        error: (e: Error) => {
          this._isProcessing$.next(false);
          this._alert.open('error', e.message);
        },
      }),
      map(() => true),
      catchError((e: Error) => {
        let failedFieldNames: string;
        const validationFieldsConfig: [string, string[]][] = [
          ['cvv', ['cvv', 'cvc']],
          ['cardNumber', ['card']],
        ];
        validationFieldsConfig.forEach((validationFieldConfig: [string, string[]]) => {
          const searchExp = new RegExp(
            '\\b(?:' + validationFieldConfig[1].join('|') + ')\\b',
            'gi',
          );
          if (searchExp.test(e.message.toLowerCase())) {
            failedFieldNames = validationFieldConfig[0];
          }
        });
        this._errorFields$.next(failedFieldNames);
        return of(false);
      }),
    );
  }

  open(): Observable<boolean> {
    return this._matDialog
      .open(ConfirmReplaceCardModalComponent, {
        minWidth: '500px',
        maxWidth: '700px',
        panelClass: 'cs-mat-dialog',
      })
      .afterClosed();
  }

  getErrors(): Observable<string> {
    return this._errorFields$.asObservable();
  }

  loadPaymentSources(): void {
    this._isPaymentSourcesLoading.next(true);
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    this._applicationBus.dispatch(new PaymentMethodEvent(workspaceId, null));
    this._getsPaymentSourcesDtoPort
      .getPaymentSources(workspaceId)
      .pipe(
        tap((paymentSourceDtos: PaymentSourceDto[]) => {
          this._savePaymentSources(paymentSourceDtos);
        }),
        finalize(() => {
          this._isPaymentSourcesLoading.next(false);
        }),
      )
      .subscribe();
  }

  getPaymentSourcesQuery(): Observable<PaymentSourceQuery[]> {
    return this._paymentSourceStorageDtoPort
      .select()
      .pipe(map((dtos: PaymentSourceStorageItemDto[]) => dtos && this._mapDtosToQuery(dtos)));
  }

  getPaymentSourcesLoadingQuery(): Observable<boolean> {
    return this._isPaymentSourcesLoading.asObservable().pipe(shareReplay(1));
  }

  getAddPaymentMethodSucceedQuery(): Observable<void> {
    return this._isSuccess$.asObservable();
  }

  getPaymentMethodProcessingQuery(): Observable<boolean> {
    return this._isProcessing$.asObservable().pipe(shareReplay(1));
  }

  addPaymentMethod(command: AddPaymentMethodCommand): Observable<boolean> {
    this._isProcessing$.next(true);
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return this._createPaymentIntent(workspaceId).pipe(
      map(paymentIntent => ({ paymentIntent, workspaceId })),
      concatMap(({ paymentIntent, workspaceId }) =>
        this._confirmPaymentMethod(command, paymentIntent).pipe(
          switchMap(success =>
            typeof success === 'boolean'
              ? this._createsPaymentSourceDtoPort
                  .createPaymentSource({
                    workspaceId,
                    paymentIntentId: paymentIntent.id,
                  })
                  .pipe(map(res => [res, paymentIntent.id]))
              : throwError(new Error(success)),
          ),
        ),
      ),
      tap({
        next: ([createdPaymentSourceDto, paymentIntentId]: [PaymentSourceCreatedDto, string]) => {
          this._updatePaymentSourcesList(createdPaymentSourceDto, command);
          this._isSuccess$.next(void 0);
          this._isProcessing$.next(false);
          this._alert.open('success', 'cobiro_pro_settings_add_payment_method_succeed');
        },
        error: (e: Error) => {
          this._isProcessing$.next(false);
          this._alert.open('error', e.message);
        },
      }),
      map(() => true),
      catchError((e: Error) => {
        let failedFieldNames: string;
        const validationFieldsConfig: [string, string[]][] = [
          ['cvv', ['cvv', 'cvc']],
          ['cardNumber', ['card']],
        ];
        validationFieldsConfig.forEach((validationFieldConfig: [string, string[]]) => {
          const searchExp = new RegExp(
            '\\b(?:' + validationFieldConfig[1].join('|') + ')\\b',
            'gi',
          );
          if (searchExp.test(e.message.toLowerCase())) {
            failedFieldNames = validationFieldConfig[0];
          }
        });
        this._errorFields$.next(failedFieldNames);
        return of(false);
      }),
    );
  }

  private _createPaymentIntent(workspaceId: string): Observable<PaymentIntent> {
    return this._createPaymentIntentDto.createPaymentIntent(workspaceId).pipe(
      map((paymentIntentDto: PaymentIntentDto) => ({
        id: paymentIntentDto.id,
        status: paymentIntentDto.status,
        amount: paymentIntentDto.amount,
        currencyCode: paymentIntentDto.currencyCode,
        gatewayAccountId: paymentIntentDto.gatewayAccountId,
        gateway: paymentIntentDto.gateway,
      })),
    );
  }

  private _updatePaymentSourcesList(
    dto: PaymentSourceCreatedDto,
    command: AddPaymentMethodCommand,
  ): void {
    const paymentSourceStorageItem: PaymentSourceStorageItemDto = {
      id: dto.id,
      brand: dto.cardBrand,
      lastDigits: dto.lastDigits,
      cardOwner: command.cardOwner,
    };
    const updatedList = [...this._paymentSourceStorageDtoPort.get(), paymentSourceStorageItem];
    this._paymentSourceStorageDtoPort.save(updatedList);
  }

  private _confirmPaymentMethod(
    command: AddPaymentMethodCommand,
    paymentIntent: PaymentIntent,
  ): Observable<string | boolean> {
    const dto: ConfirmPaymentMethodDto = {
      card: {
        number: command.number,
        cvv: command.cvv,
        expiryMonth: command.expiryMonth,
        expiryYear: command.expiryYear,
        owner: command.cardOwner,
      },
      paymentIntent: {
        id: paymentIntent.id,
        status: paymentIntent.status,
        amount: paymentIntent.amount,
        currencyCode: paymentIntent.currencyCode,
        gatewayAccountId: paymentIntent.gatewayAccountId,
        gateway: paymentIntent.gateway,
      },
    };
    return this._confirmsPaymentMethod.confirm(dto);
  }

  private _savePaymentSources(paymentSourceDtos: PaymentSourceDto[]): void {
    const storageDtos: PaymentSourceStorageItemDto[] = paymentSourceDtos.map(paymentSource => ({
      id: paymentSource.id,
      brand: paymentSource.brand,
      cardOwner: paymentSource.cardOwner,
      lastDigits: paymentSource.lastDigits,
    }));
    this._paymentSourceStorageDtoPort.save(storageDtos);
  }

  private _mapDtosToQuery(dtos: PaymentSourceStorageItemDto[]): PaymentSourceQuery[] {
    return dtos.map(
      dto =>
        new PaymentSourceQuery(
          dto.id,
          `assets/payment-sources-logos/${dto.brand}.svg`,
          dto.cardOwner,
          `XXXX-XXXX-XXXX-${dto.lastDigits}`,
        ),
    );
  }
}
