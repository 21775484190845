import { NgModule } from '@angular/core';
import { HttpChangesPasswordServiceModule } from '../adapters/secondary/infrastructure/http-changes-password/http-changes-password.service-module';
import { HttpDeletesUserServiceModule } from '../adapters/secondary/infrastructure/http-delete-user/http-deletes-user.service-module';
import { HttpGetsCountriesServiceModule } from '../adapters/secondary/infrastructure/http-gets-countries/http-gets-countries.service-module';
import { HttpUserDetailsServiceModule } from '../adapters/secondary/infrastructure/http-user-details/http-user-details.service-module';
import { PasswordChangeStateModule } from '../application/state/password-change.state-module';
import { UserDeletionStateModule } from '../application/state/user-deletion.state-module';
import { UserDetailsStateModule } from '../application/state/user-details.state-module';

@NgModule({
  imports: [
    HttpUserDetailsServiceModule,
    HttpChangesPasswordServiceModule,
    HttpDeletesUserServiceModule,
    HttpGetsCountriesServiceModule,
    UserDetailsStateModule,
    PasswordChangeStateModule,
    UserDeletionStateModule,
  ],
  providers: [],
})
export class CobiroProRewriteMyProfileProvidersModule {}
