import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerStartPageComponent } from './label-manager-start-page.component';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { LabelManagerResultsComponentModule } from '../label-manager-results/label-manager-results.component-module';

@NgModule({
  declarations: [LabelManagerStartPageComponent],
  imports: [
    CommonModule,
    HuiLoaderContainerModule,
    FeatureFlagsModule,
    LanguageModule,
    LabelManagerResultsComponentModule,
  ],
  exports: [LabelManagerStartPageComponent],
})
export class LabelManagerStartPageModule {}
