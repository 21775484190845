import { FeaturesLoadedEvent } from '@app.cobiro.com/core/payments-features';
import { Inject, Injectable } from '@angular/core';
import { TOP_BAR_STATE_SETTER, TopBarStateSetter } from '../application/top-bar.state';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class TopBarFeaturesLoadedEventHandler implements ApplicationEventHandler {
  eventClass = FeaturesLoadedEvent;
  constructor(@Inject(TOP_BAR_STATE_SETTER) private _topBarState: TopBarStateSetter) {}
  handle(event: FeaturesLoadedEvent) {
    this._topBarState.setHasUpgradeButton(event.features.has('PAY_SHOW-UPGRADE'));
  }
}
