import { UnverifiedUserSignedInEvent } from '@app.cobiro.com/core/events';
import { Injectable } from '@angular/core';
import { UserRegisterState } from '@app.cobiro.com/user/core';
import { Router } from '@angular/router';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class UnverifiedUserSignedInEventHandler implements ApplicationEventHandler {
  eventClass = UnverifiedUserSignedInEvent;

  constructor(private _router: Router, private _userRegisterState: UserRegisterState) {}

  handle(event: UnverifiedUserSignedInEvent) {
    this._router.navigate(['/auth/register/verify']).then(() => {
      this._userRegisterState.setCredentials(event.email, event.password);
    });
  }
}
