import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CobiroProBackToWorkspaceEvent,
  CobiroProClientSelectedEvent,
} from '@app.cobiro.com/core/events';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangesSelectedClientCommandPort } from '../ports/primary/changes-selected-client.command-port';
import { ClearsClientsHistoryCommandPort } from '../ports/primary/clears-clients-history.command-port';
import { DeselectsClientCommandPort } from '../ports/primary/deselects-client.command-port';
import { GetsSelectedClientQuery } from '../ports/primary/gets-selected-client.query-port';
import { GetsVisitedClientsQueryPort } from '../ports/primary/gets-visited-clients.query-port';
import { ManagerBackToWorkspaceCommandPort } from '../ports/primary/manager-back-to-workspace.command-port';
import { SelectedClientQuery } from '../ports/primary/selected-client.query';
import { SelectsClientCommand } from '../ports/primary/selects-client.command';
import { SelectsClientCommandPort } from '../ports/primary/selects-client.command-port';
import { VisitedClientQuery } from '../ports/primary/visited-client.query';
import { CobiroProSelectedClientDto } from '../ports/secondary/cobiro-pro-selected-client.dto';
import {
  GET_SELECTED_CLIENT_DTO,
  GetsSelectedClientDtoPort,
} from '../ports/secondary/gets-selected-client.dto-port';
import {
  MANAGES_VISITED_CLIENTS_DTO_PORT,
  ManagesVisitedClientsDtoPort,
} from '../ports/secondary/manages-visited-clients.dto-port';
import {
  SETS_SELECTED_CLIENT_DTO,
  SetsSelectedClientDtoPort,
} from '../ports/secondary/sets-selected-client.dto-port';
import { VisitedClientDto } from '../ports/secondary/visited-client.dto';

type ClientsMapItem = Omit<VisitedClientDto, 'id'>;

@Injectable()
export class ClientsHistoryManager
  implements
    SelectsClientCommandPort,
    GetsSelectedClientQuery,
    ManagerBackToWorkspaceCommandPort,
    GetsVisitedClientsQueryPort,
    ChangesSelectedClientCommandPort,
    DeselectsClientCommandPort,
    ClearsClientsHistoryCommandPort
{
  private readonly clientHistoryLimit = 5;

  // TODO (PRO-DEBT): Storage should keep dtos not queries
  private readonly _selectedClient$ = new BehaviorSubject<SelectedClientQuery | null>(null);
  private readonly _visitedClients$ = new BehaviorSubject<VisitedClientDto[]>([]);

  private readonly _clientMap: Map<string, ClientsMapItem>;

  constructor(
    // TODO (PRO-DEBT): It should be a token for a DispatcherPort and it shouldn't use @cobiro/eda dispatcher
    @Inject(APPLICATION_BUS)
    private readonly _dispatcher: Dispatcher<CobiroProBackToWorkspaceEvent>,
    @Inject(MANAGES_VISITED_CLIENTS_DTO_PORT)
    private readonly _managesVisitedClientsDtoPort: ManagesVisitedClientsDtoPort,
    @Inject(GET_SELECTED_CLIENT_DTO) private readonly _getSelectedClient: GetsSelectedClientDtoPort,
    @Inject(SETS_SELECTED_CLIENT_DTO)
    private readonly _setSelectedClient: SetsSelectedClientDtoPort,
    private readonly _router: Router,
  ) {
    const visitedClients: VisitedClientDto[] = this._managesVisitedClientsDtoPort.getClients();
    this._visitedClients$.next(visitedClients);
    this._clientMap = this._visitedClientsToMap(visitedClients);
  }

  getSelectedClient(): Observable<SelectedClientQuery | null> {
    return this._selectedClient$.asObservable();
  }

  selectClient(clientCommand: SelectsClientCommand | null): Observable<void> {
    this._setSelectedClient.setSelectedClient(clientCommand?.siteId);
    this._saveClientAsVisited({
      id: clientCommand?.id,
      sitePublicId: clientCommand?.sitePublicId,
      siteId: clientCommand?.siteId,
      name: clientCommand?.name,
      avatar: clientCommand?.avatar,
      teamId: clientCommand?.teamId,
    });
    this._selectedClient$.next(clientCommand && SelectedClientQuery.fromClientDto(clientCommand));
    return of(void 0);
  }

  backToWorkspace(): void {
    const clientId = this._selectedClient$.value.id;
    if (this._shouldBackToPro(clientId)) {
      return this._backToPro();
    }

    this._goToDashboard(this._selectedClient$.value.id);
  }

  changeClient(clientId: string): void {
    this._setSelectedClient.setSelectedClient(clientId);
    const client = this._clientMap.get(clientId);
    this._selectedClient$.next(
      new SelectedClientQuery(clientId, client.name, client.avatar, client.sitePublicId),
    );
    this._dispatcher.dispatch(new CobiroProClientSelectedEvent({ ...client, id: clientId }));
    this._goToDashboard(clientId);
  }

  getVisitedClients(): Observable<VisitedClientQuery[]> {
    return this._visitedClients$.asObservable().pipe(
      map((visitedClients: VisitedClientDto[]) => visitedClients.sort(this._sortByDateDescending)),
      map((sortedVisitedClients: VisitedClientDto[]) =>
        sortedVisitedClients.map(VisitedClientQuery.fromDto),
      ),
    );
  }

  deselectClient(): void {
    this._selectedClient$.next(null);
    this._setSelectedClient.setSelectedClient(null);
  }

  clear(): void {
    this._selectedClient$.next(null);
    this._managesVisitedClientsDtoPort.clear();
    this._visitedClients$.next([]);
  }

  // TODO: Router should not be used here
  // We should have adapter for it
  // For now every usage is in private method
  private _shouldBackToPro(clientId): boolean {
    const siteId = this._clientMap.get(clientId).siteId;
    // TODO: SiteId is checked because of empty siteId for brex site
    return /site\/[0-9]+\/?$/i.test(this._router.url) || !siteId;
  }

  private _backToPro(): void {
    this.deselectClient();
    this._dispatcher.dispatch(new CobiroProBackToWorkspaceEvent());
  }

  private _goToDashboard(clientId: string): void {
    const siteId = this._clientMap.get(clientId).siteId;
    if (!siteId) {
      this._router.navigate(['create-site'], {
        queryParams: {
          siteId: this._clientMap.get(clientId).sitePublicId,
          websiteCreationContext: 'COBIRO_PRO',
        },
      });
      return;
    }
    this._router.navigate(['/site', siteId]);
  }

  private _saveClientAsVisited(clientDto: CobiroProSelectedClientDto): void {
    // TODO: Think about removing this check
    // siteId should be refreshed when brex site is created
    if (this._clientMap.has(clientDto.id) && !!this._clientMap.get(clientDto.id).siteId) {
      return;
    }

    this._removeOldestClientRecordIfLimitExtended();

    this._clientMap.set(clientDto.id, {
      siteId: clientDto.siteId,
      teamId: clientDto.teamId,
      dateAdded: new Date(),
      name: clientDto.name,
      avatar: clientDto.avatar,
      sitePublicId: clientDto.sitePublicId,
    });

    const visitedClientsDto: VisitedClientDto[] = this._getVisitedClientsFromMap();
    this._visitedClients$.next(visitedClientsDto);
    this._managesVisitedClientsDtoPort.setClients(visitedClientsDto);
  }

  private _removeOldestClientRecordIfLimitExtended(): void {
    if (this._clientMap.size < this.clientHistoryLimit) {
      return;
    }

    const clientToRemove = this._getClientIdWithLatestDate();
    this._clientMap.delete(clientToRemove);
  }

  private _getClientIdWithLatestDate(): string {
    let oldestClientId: string | null = null;
    let currentOldestDate: Date | null = null;

    for (const [clientId, { dateAdded }] of this._clientMap.entries()) {
      if (!oldestClientId) {
        oldestClientId = clientId;
        currentOldestDate = dateAdded;
        continue;
      }

      if (currentOldestDate > dateAdded) {
        oldestClientId = clientId;
        currentOldestDate = dateAdded;
      }
    }

    return oldestClientId;
  }

  private _getVisitedClientsFromMap(): VisitedClientDto[] {
    return Array.from(this._clientMap.entries()).map(
      ([id, mapItem]: [string, ClientsMapItem]) =>
        <VisitedClientDto>{
          id,
          teamId: mapItem.teamId,
          dateAdded: mapItem.dateAdded,
          name: mapItem.name,
          siteId: mapItem.siteId,
          avatar: mapItem.avatar,
          sitePublicId: mapItem.sitePublicId,
        },
    );
  }

  private _visitedClientsToMap(visitedClients: VisitedClientDto[]): Map<string, ClientsMapItem> {
    return new Map(
      visitedClients.map(client => [
        client.id,
        {
          name: client.name,
          siteId: client.siteId,
          teamId: client.teamId,
          dateAdded: client.dateAdded,
          avatar: client.avatar,
          sitePublicId: client.sitePublicId,
        },
      ]),
    );
  }

  private _sortByDateDescending(a: VisitedClientDto, b: VisitedClientDto): number {
    return b.dateAdded.getTime() - a.dateAdded.getTime();
  }
}
