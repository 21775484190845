import { Observable } from 'rxjs';
import { UserDataQuery } from './user-data.query';
import { InjectionToken } from '@angular/core';

export interface GetsUserDataQueryPort {
  getUserData(): Observable<UserDataQuery>;
}

export const GETS_USER_DATA_QUERY = new InjectionToken<GetsUserDataQueryPort>(
  'GETS_USER_DATA_QUERY_PORT',
);
