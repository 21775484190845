export class User {
  constructor(
    public readonly id: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string,
    public readonly group: string,
    public readonly weeklySummary: boolean,
    public readonly interest: string,
    public readonly createdAt: string,
    public readonly avatar: string,
  ) {}

  static fromRaw(rawUser): User {
    return new User(
      rawUser.id,
      rawUser.firstName,
      rawUser.lastName,
      rawUser.email,
      rawUser.group,
      rawUser.weeklySummary,
      rawUser.interest,
      rawUser.createdAt,
      rawUser.avatar,
    );
  }

  get isGuest(): boolean {
    return this.email.includes('@guest.cobiro.com');
  }
}
