import { NgModule } from '@angular/core';
import { GETS_ALL_WORKSPACES_DTO } from '../../application/ports/secondary/gets-all-workspaces.dto-port';
import { HttpWorkspacesService } from './http-workspaces.service';

@NgModule({
  providers: [
    HttpWorkspacesService,
    { provide: GETS_ALL_WORKSPACES_DTO, useExisting: HttpWorkspacesService },
  ],
})
export class HttpWorkspacesServiceModule {}
