import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SignsUpByGoogleCommand } from '../../application/ports/primary/signs-up-by-google.command';
import {
  SIGNS_UP_BY_GOOGLE_COMMAND,
  SignsUpByGoogleCommandPort,
} from '../../application/ports/primary/signs-up-by-google.command-port';

@Component({
  selector: 'lib-sign-up-by-google',
  templateUrl: './sign-up-by-google.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SignUpByGoogleComponent {
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(SIGNS_UP_BY_GOOGLE_COMMAND)
    private readonly _signsUpByGoogleCommand: SignsUpByGoogleCommandPort,
    private _router: Router,
  ) {}

  onSignUpByGoogleClicked(): void {
    this.isLoading$.next(true);
    this._signsUpByGoogleCommand
      .signUpByGoogle(new SignsUpByGoogleCommand(this._router.url))
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe();
  }
}
