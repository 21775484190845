import { PaymentPlanPriceVO, PlanTypeCollection } from '../../../domain/payment-plan-prices.vo';
import {
  PaymentPlan,
  PaymentPlanCurrency,
  PaymentPlanPeriod,
} from '../../../domain/payment-plan';
import { PaymentPlanCardsMapper, USER_PLAN } from './payment-plan-cards-mapper';

export class PaymentPlanCardQuery {
  constructor(
    public readonly name: string,
    public readonly description: string,
    public readonly subscriptionPeriods: PlanTypeCollection<number>,
    public readonly featureHeader: string,
    public readonly featureDescriptions: string[],
    public readonly isRecommended: boolean,
  ) {}

  static fromDomain(model: PaymentPlan): PaymentPlanCardQuery {
    return new PaymentPlanCardQuery(
      model.name,
      model.goal,
      model.pricedPeriods,
      PaymentPlanCardsMapper.planTypeToFeatureDescriptionHeader.get(model.name),
      PaymentPlanCardsMapper.planTypeToFeatureDescriptions.get(model.name),
      model.name === USER_PLAN.BUSINESS,
    );
  }

  getPriceForPeriod(period: PaymentPlanPeriod): PaymentPlanPriceVO {
    return new PaymentPlanPriceVO(this.subscriptionPeriods, period, PaymentPlanCurrency.USD);
  }
}
