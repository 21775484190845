import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GetsInstalledAppsIds {
  getAll(siteId?: number): Observable<string[]>;
}

export const GETS_INSTALLED_APPS_IDS = new InjectionToken<GetsInstalledAppsIds>(
  'GETS_INSTALLED_APPS_IDS',
);
