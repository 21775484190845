import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import { from, Observable } from 'rxjs';
import { concatMap, map, take, toArray } from 'rxjs/operators';
import { SIDEBAR_ITEMS, SIDEBAR_ITEMS_DATA, SidebarGroup } from './sidebar-items';

// Used in multiple components
@Component({
  selector: 'lib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SIDEBAR_ITEMS_DATA, useValue: SIDEBAR_ITEMS }],
})
export class SidebarComponent {
  items$: Observable<SidebarGroup[]> = null;

  constructor(private _featureFlagState: FeatureFlagsState) {}

  @Input() set site(site: any) {
    if (site) {
      this._siteId = site.id;
    } else {
      this._siteId = null;
    }
  }

  private _siteId: string;

  get siteId(): string {
    return this._siteId;
  }

  shouldDisplayGroup(featureFlag: string[]): Observable<boolean> {
    return from(featureFlag).pipe(
      concatMap(flag => this._featureFlagState.hasFlags([flag]).pipe(take(1))),
      toArray(),
      map((results: boolean[]) => (results.length > 0 ? results.includes(true) : true)),
    );
  }
}
