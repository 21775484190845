import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HuiKeyboardBlockModule } from '@app.cobiro.com/shared/hui/keyboard-block';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { CompleteUserProfileDirective } from './ui/complete-user-profile/complete-user-profile.directive';
import { CoreUserCompleteProfileModule } from './core-user-complete-profile.module';
import { CompleteUserProfileModalComponent } from './ui/complete-user-profile-modal/complete-user-profile-modal.component';
import { UserCoreModule } from '@app.cobiro.com/user/core';
import { CompleteProfileRequestedEventHandler } from './adapters/modal-open-requested/complete-profile-requested.event-handler';
import { CompleteProfileUserRegisteredEventHandler } from './adapters/user-registered/complete-profile-user-registered.event-handler';
import { GuestModeDirectivesControllerDirective } from './ui/guest-mode-directive-controller/guest-mode-directives-controller.directive';
import { provideApplicationEventHandler } from '@cobiro/eda';

const declarations = [
  CompleteUserProfileDirective,
  GuestModeDirectivesControllerDirective,
  CompleteUserProfileModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    LanguageModule,
    MatFormFieldModule,
    MatInputModule,
    HuiKeyboardBlockModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    UserCoreModule.forChild(),
    CoreUserCompleteProfileModule.forRoot(),
    HuiLoadingModule,
    MatSnackBarModule,
  ],
  declarations,
  exports: declarations,
  providers: [
    provideApplicationEventHandler(CompleteProfileRequestedEventHandler),
    provideApplicationEventHandler(CompleteProfileUserRegisteredEventHandler),
  ],
})
export class UserCompleteProfileModule {}
