import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface LoadsWorkspacesQueryPort {
  loadWorkspaces(teamId: string): Observable<boolean>;
}

export const LOADS_WORKSPACES_QUERY = new InjectionToken<LoadsWorkspacesQueryPort>(
  'LOADS_WORKSPACES_QUERY',
);
