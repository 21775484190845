import { SidebarItems } from './configs/sidebar-items.config';

export const LAYOUT_SIDENAV_MAP = new Map([
  [
    SidebarItems.home,
    {
      id: SidebarItems.home,
      icon: 'home-hollow',
      route: ['/site', ':activeSite'],
      active: true,
    },
  ],
  [
    SidebarItems.build,
    {
      id: SidebarItems.build,
      icon: 'old-website-builder-hollow',
      route: ['/site', ':activeSite', 'build'],
      active: false,
    },
  ],
  [
    SidebarItems.grow,
    {
      id: SidebarItems.grow,
      icon: 'grow',
      route: ['/site', ':activeSite', 'grow'],
      active: false,
    },
  ],
  [
    SidebarItems.promote,
    {
      id: SidebarItems.promote,
      icon: 'megaphone-hollow',
      route: ['/site', ':activeSite', 'promote'],
      active: false,
    },
  ],
]);
