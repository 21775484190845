import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  SIGN_UP_PROVIDERS,
  SignsUpBy,
  SocialAuth,
  SocialMissingEmailError,
  SocialUnexpectedError,
} from '../domain/signs-up-by';
import { DomainError } from '@app.cobiro.com/core/state';
import { HasData, HasErrorsResponse } from '@cobiro/jsonapi';
import { GATEWAY_URL } from '../../../../../common/gateway/src/lib/gateway.token';

export interface AuthAttributes {
  userId: number;
  tokenType: string;
  accessToken: string;
  refreshToken: string;
}

export interface SocialLoginAttributes {
  providerName: string;
  oauthToken: string;
}

@Injectable()
export class HttpSignUpByService implements SignsUpBy {
  private _errors: Map<string, DomainError> = new Map([
    [SocialMissingEmailError.code, new SocialMissingEmailError()],
  ]);
  private _headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(private _client: HttpClient, @Inject(GATEWAY_URL) private _baseUrl: string) {}

  signUpBy(provider: SIGN_UP_PROVIDERS, oauthToken: string): Observable<SocialAuth | null> {
    const body: HasData<SocialLoginAttributes> = {
      data: {
        type: 'social-login',
        attributes: {
          providerName: provider,
          oauthToken: oauthToken,
        },
      },
    };

    return this._client
      .post<HasData<AuthAttributes>>(this._baseUrl + '/v1/users/social-logins', body, {
        headers: this._headers,
      })
      .pipe(
        map(response => response.data.attributes),
        map(
          attributes =>
            new SocialAuth(
              attributes.userId,
              attributes.tokenType,
              attributes.accessToken,
              attributes.refreshToken,
            ),
        ),
        catchError((error: HasErrorsResponse) => {
          const errorCode = error?.errors[0]?.code;
          if (!errorCode) {
            throw new SocialUnexpectedError();
          }

          throw this._errors.get(errorCode) ?? new SocialUnexpectedError();
        }),
      );
  }
}
