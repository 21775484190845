import { NgModule } from '@angular/core';
import { CompleteProfileState } from './application/complete-profile.state';
import { CompleteProfileUserChangedEventHandler } from './adapters/user-changed/complete-profile-user-changed.event-handler';
import { provideApplicationEventHandler } from '@cobiro/eda';

@NgModule({
  providers: [
    CompleteProfileState,
    provideApplicationEventHandler(CompleteProfileUserChangedEventHandler),
  ],
})
export class UserCompleteProfileHandlersModule {}
