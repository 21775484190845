import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesDirective } from './directives/roles.directive';
import { RolesGuard } from './guards/roles.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [RolesDirective],
  exports: [RolesDirective],
  providers: [RolesGuard],
})
export class RolesModule {}
