import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { HasUpgradeButtonQuery } from './has-upgrade-button.query';

export interface GetsHasUpgradeButtonQueryPort {
  getHasUpgradeButton(): Observable<HasUpgradeButtonQuery>;
}

export const GETS_HAS_UPGRADE_BUTTON_QUERY = new InjectionToken<GetsHasUpgradeButtonQueryPort>(
  'GETS_HAS_UPGRADE_BUTTON_QUERY',
);
