import { Inject, Injectable } from '@angular/core';
import { TeamIdGetter, TEAM_ID_GETTER } from '@app.cobiro.com/cobiro-pro/context';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { BehaviorSubject, filter, map, Observable, take, tap } from 'rxjs';
import { UsersListChangedEvent } from '../events/users-list-changed.event';
import { GetsUsersListQueryPort } from '../ports/primary/gets-users-list.query-port';
import { LoadUsersCommandPort } from '../ports/primary/load-users.command-port';
import { SetsSearchPhraseCommand } from '../ports/primary/sets-search-phrase.command';
import { SetsSearchPhraseCommandPort } from '../ports/primary/sets-search-phrase.command-port';
import { UserQuery } from '../ports/primary/user.query';
import {
  SelectsUsersContextStoragePort,
  SELECTS_USERS_CONTEXT_STORAGE,
} from '../ports/secondary/context/selects-users-context.storage-port';
import { UsersContext } from '../ports/secondary/context/usersContext';
import {
  USERS_LIST_CHANGED_DISPATCHER,
  UsersListChangedDispatcherPort,
} from '../ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import {
  GetsAllUsersDtoPort,
  GETS_ALL_USERS_DTO,
} from '../ports/secondary/dto/gets-all-users.dto-port';
import { UserDTO } from '../ports/secondary/dto/user.dto';

@Injectable()
export class UserListState
  implements GetsUsersListQueryPort, LoadUsersCommandPort, SetsSearchPhraseCommandPort
{
  private readonly _userList$ = new BehaviorSubject<UserDTO[]>([]);

  constructor(
    @Inject(GETS_ALL_USERS_DTO)
    private readonly _getAllUsersDTO: GetsAllUsersDtoPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(SELECTS_USERS_CONTEXT_STORAGE)
    private readonly _selectsUsersContextStorage: SelectsUsersContextStoragePort,
    @Inject(USERS_LIST_CHANGED_DISPATCHER)
    private readonly _usersListChangedDispatcher: UsersListChangedDispatcherPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
  ) {
    this._applicationBus
      .on(UsersListChangedEvent)
      .pipe(tap((event: UsersListChangedEvent) => this._userList$.next(event.updatedUsers)))
      .subscribe();
  }

  setSearchPhrase(command: SetsSearchPhraseCommand): Observable<void> {
    return this._selectsUsersContextStorage.select().pipe(
      take(1),
      map((userContext: UsersContext) =>
        userContext.list.filter((user: UserDTO) =>
          user.email.toLowerCase().includes(command.phrase.toLowerCase()),
        ),
      ),
      tap((users: UserDTO[]) => this._userList$.next(users)),
      map(() => void 0),
    );
  }

  getUserListQuery(): Observable<UserQuery[]> {
    return this._userList$.asObservable().pipe(
      map(users => {
        return this._mapUsersDtoToQuery(users);
      }),
    );
  }

  loadUsers(): Observable<UserDTO[]> {
    const teamId = this._teamIdGetter.getTeamId();
    return this._getAllUsersDTO.getAll({ teamId }).pipe(
      tap(users => {
        this._userList$.next(users);
        this._usersListChangedDispatcher.dispatch({ updatedUsers: users });
      }),
    );
  }

  private _mapUsersDtoToQuery(users: UserDTO[]): UserQuery[] {
    return users.map(userDto => UserQuery.fromUserDTO(userDto));
  }
}
