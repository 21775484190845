import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
  CHECKS_APP_ACCESS_QUERY,
  ChecksAppAccessQueryPort,
} from '../../../application/ports/primary/checks-app-access-query.port';
import { concatMap, map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  GETS_SHUTDOWN_APPLICATIONS_QUERY,
  GetsShutdownApplicationsQueryPort,
} from '../../../application/ports/primary/gets-shutdown-applications.query-port';
import { NotAllowedModalComponent } from '../ui/not-allowed-modal/not-allowed-modal.component';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';

@Injectable()
export class CheckAccessToAppGuard  {
  constructor(
    @Inject(CHECKS_APP_ACCESS_QUERY) private _checksAppAccessQuery: ChecksAppAccessQueryPort,
    @Inject(GETS_SHUTDOWN_APPLICATIONS_QUERY)
    private _getsShutdownApplicationsQuery: GetsShutdownApplicationsQueryPort,
    private _dialog: MatDialog,
    private _featureFlagsState: FeatureFlagsState,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const siteId = route.params.siteId;
    const appIdentifier = route.data.appIdentifier || route.params.appIdentifier;
    return this._featureFlagsState.hasFlags(['pro_apps_guard']).pipe(
      concatMap(hasFlag => {
        return hasFlag
          ? this._checksAppAccessQuery.checkAppAccess(appIdentifier, siteId)
          : of(true);
      }),
      switchMap(hasAccess => {
        if (!hasAccess) {
          return of(false);
        }

        return this._getsShutdownApplicationsQuery
          .getShutdownApplications()
          .pipe(map(query => !query.isShutdown(appIdentifier)));
      }),
      tap(hasAccess => {
        if (!hasAccess) {
          this._dialog.open(NotAllowedModalComponent);
        }
      }),
    );
  }
}
