import { Inject, Injectable } from '@angular/core';

import { Observable, take } from 'rxjs';
import { UserDetailsQuery } from '../../../application/ports/primary/user-details.query';
import {
  GETS_USER_DETAILS_QUERY_PORT,
  GetsUserDetailsQueryPort,
} from '../../../application/ports/primary/gets-user-details.query-port';

@Injectable()
export class LoadUserDetailsResolver  {
  constructor(
    @Inject(GETS_USER_DETAILS_QUERY_PORT)
    private readonly _getsUserDetailsQueryPort: GetsUserDetailsQueryPort,
  ) {}

  resolve(): Observable<UserDetailsQuery> {
    return this._getsUserDetailsQueryPort.getUserDetails().pipe(take(1));
  }
}
