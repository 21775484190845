/* eslint-disable max-lines-per-function */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ServiceUrls } from '@cbr/services';
import { HasDataCollection, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsQuestionsDto } from '../../../../application/ports/secondary/gets-questions.dto';
import { GetsQuestionsDtoPort } from '../../../../application/ports/secondary/gets-questions.dto-port';
import { QuestionDto } from '../../../../application/ports/secondary/question.dto';

export interface PaymentPlanDataAttributes {
  name: string;
  goal: string;
  prices: { [key: string]: number };
  features: { id: string; value: string; additionalInformation?: boolean }[];
}

@Injectable()
export class HttpGetsQuestionService implements GetsQuestionsDtoPort {
  headers = new HttpHeaders(JSON_API_HEADERS);
  url = 'https://cobiro.zendesk.com/api/v2/help_center/en-us/articles';

  constructor(private readonly _http: HttpClient) {}

  getQuestions(dto: GetsQuestionsDto): Observable<QuestionDto[]> {
    return this._http
      .get<{ articles: QuestionDto[] }>(
        `${this.url}?label_names=${dto.productName}&sort_by=created_at&sort_order=asc`,
        {
          headers: this.headers,
        },
      )
      .pipe(
        map(({ articles }) =>
          articles.map(questions => ({
            id: questions.id,
            title: questions.title,
            body: questions.body.replace(/<a/g, "<a target='_blank'"),
          })),
        ),
      );
  }
}
