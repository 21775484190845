import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { HuiAlertModule } from '@app.cobiro.com/shared/hui/alert';
import { UserImpersonateLogoutEventHandler } from './adapters/user-impersonate-logout.event-handler';
import { ImpersonatesUserGuard } from './ui/guard/impersonates-user.guard';
import { ImpersonationWidgetComponent } from './ui/impersonation-widget/impersonation-widget.component';
import { ApplicationBusModule, provideApplicationEventHandler } from '@cobiro/eda';
import { UserImpersonationState } from './application/user-impersonation.state';

@NgModule({
  imports: [ApplicationBusModule, CommonModule, HuiAlertModule],
  providers: [
    provideApplicationEventHandler(UserImpersonateLogoutEventHandler),
    ImpersonatesUserGuard,
    UserImpersonationState,
    { provide: STORAGE, useValue: localStorage },
  ],
  declarations: [ImpersonationWidgetComponent],
  exports: [ImpersonationWidgetComponent],
})
export class UserImpersonationModule {}
