import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface UserMenuDetails {
  firstName?: string;
  lastName?: string;
  email: string;
  avatar?: string;
}

export interface UserMenuItem {
  translationKey: string;
  routerLink?: string[];
  callback?: () => void;
}

@Component({
  selector: 'hui-user-menu',
  templateUrl: 'user-menu.component.html',
  styleUrls: ['user-menu.component.scss'],
})
export class HuiUserMenuComponent {
  public readonly user$ = new BehaviorSubject<{
    displayName: string;
    email: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
  }>(null);

  @Input() set userDetails(data: UserMenuDetails) {
    if (data) {
      this.user$.next({
        displayName: this._getDisplayName(data),
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        avatar: data.avatar,
      });
    }
  }

  @Input() readonly menuItems: UserMenuItem[];

  private _getDisplayName(data: UserMenuDetails): string {
    return data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : data.email;
  }
}
