import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  VERIFY_LATEST_LOGIN_COMMAND,
  VerifyLatestLoginCommandPort,
} from '../../../application/ports/primary/verify-latest-login.command-port';

@Injectable()
export class VerifyLatestProLoginResolver  {
  constructor(
    @Inject(VERIFY_LATEST_LOGIN_COMMAND)
    private readonly _verifyLatestLoginCommand: VerifyLatestLoginCommandPort,
  ) {}

  resolve(snapshot: ActivatedRouteSnapshot) {
    this._verifyLatestLoginCommand.verifyLatestLogin(snapshot.queryParamMap.get('userId'));
  }
}
