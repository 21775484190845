import { ApplicationEvent } from '@cobiro/eda';

export class UserChangedEvent extends ApplicationEvent {
  constructor(
    public readonly user: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      isGuest: boolean;
      createdAt: Date;
      avatar: string;
    },
  ) {
    super();
  }
}
