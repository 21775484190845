import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UIState } from '@app.cobiro.com/shared/ui-state';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { LAYOUT_BG_TRANSPARENT, TOPBAR_BORDER_HIDDEN } from './layout-tokens';
import { LayoutComponent } from './layout.component';
import { SidebarIconsComponent } from './sidebar-icons/sidebar-icons.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserImpersonationModule } from '@app.cobiro.com/user/impersonation';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { MatIconModule } from '@angular/material/icon';
import { AttentionMessageWidgetModule } from './attention-message-widget/attention-message-widget.component-module';

@NgModule({
  declarations: [LayoutComponent, SidebarComponent, SidebarIconsComponent],
  imports: [
    CommonModule,
    LanguageModule,
    RouterModule,
    SharedAnalyticsModule,
    FeatureFlagsModule,
    UserImpersonationModule,
    AttentionMessageWidgetModule,
    HuiLoadingModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: TOPBAR_BORDER_HIDDEN,
      useFactory: () => new UIState<boolean>(false),
    },
    {
      provide: LAYOUT_BG_TRANSPARENT,
      useFactory: () => new UIState<boolean>(false),
    },
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
