import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserImpersonationState } from '../../application/user-impersonation.state';
import { INSTALLED_APPS_PATHS, NO_INSTALLED_APPS_PATHS } from '../../domain/redirect-paths';

@Injectable()
export class ImpersonatesUserGuard  {
  private _redirectMap = new Map<string, string[]>([['createSite', ['create-site']]]);

  private readonly _installedAppsRedirectPaths: Map<string, string[]> = INSTALLED_APPS_PATHS;
  private readonly _noInstalledAppsRedirectPaths: Map<string, string[]> = NO_INSTALLED_APPS_PATHS;

  constructor(
    private _router: Router,
    private _alert: HuiAlert,
    private _impersonatesUserState: UserImpersonationState,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = route.queryParams?.token;
    if (!token) {
      return this._router.createUrlTree(['/auth/login']);
    }

    return this._impersonatesUserState.impersonate(token).pipe(
      take(1),
      map((success: boolean) => {
        if (success) {
          this._alert.open('success', 'user_impersonate_success');
          return this._router.createUrlTree(this._getRedirectUrl(route));
        }

        this._alert.open('error', 'user_impersonate_fail');
        return this._router.createUrlTree(['/auth/login']);
      }),
    );
  }

  private _getRedirectUrl(route: ActivatedRouteSnapshot): (string | number)[] {
    if (route.queryParams.redirectTo) {
      return this._redirectMap.get(route.queryParams.redirectTo);
    }
    if (route.queryParams.redirectToApp && route.queryParams.siteId) {
      return this._redirectToApp(route);
    }
    return ['/connect'];
  }

  private _redirectToApp(route: ActivatedRouteSnapshot): (string | number)[] {
    const redirectMap =
      route.queryParams.hasAppInstalled === 'true'
        ? this._installedAppsRedirectPaths
        : this._noInstalledAppsRedirectPaths;
    return [
      'site',
      Number(route.queryParams.siteId),
      ...redirectMap.get(route.queryParams?.redirectToApp),
    ];
  }
}
