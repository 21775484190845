import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { UserDataQuery } from '../ports/primary/user-data.query';
import { GetsUserDataQueryPort } from '../ports/primary/gets-user-data.query-port';
import { SetsUserDataCommandPort } from '../ports/primary/sets-user-data.command-port';
import { SetsHasUpgradeButtonCommandPort } from '../ports/primary/sets-has-upgrade-button.command-port';
import { GetsHasUpgradeButtonQueryPort } from '../ports/primary/gets-has-upgrade-button.query-port';
import { HasUpgradeButtonQuery } from '../ports/primary/has-upgrade-button.query';
import { UpgradesPlanCommandPort } from '../ports/primary/upgrades-plan.command-port';
import {
  UPGRADE_PLAN_REQUESTED_DISPATCHER,
  UpgradePlanRequestedDispatcherPort,
} from '../ports/secondary/upgrade-plan-requested.dispatcher-port';

@Injectable()
export class CobiroProLayoutState
  implements
    GetsUserDataQueryPort,
    GetsHasUpgradeButtonQueryPort,
    SetsUserDataCommandPort,
    SetsHasUpgradeButtonCommandPort,
    UpgradesPlanCommandPort
{
  private _currentUser = new ReplaySubject<UserDataQuery>(1);
  private _hasUpgradeButton = new BehaviorSubject<HasUpgradeButtonQuery>({
    hasUpgradeButton: false,
  });

  constructor(
    @Inject(UPGRADE_PLAN_REQUESTED_DISPATCHER)
    private readonly _upgradePlanRequestedDispatcher: UpgradePlanRequestedDispatcherPort,
  ) {}

  getUserData(): Observable<UserDataQuery> {
    return this._currentUser.asObservable();
  }

  getHasUpgradeButton(): Observable<HasUpgradeButtonQuery> {
    return this._hasUpgradeButton.asObservable();
  }

  setUserData(userData: { firstName?: string; lastName?: string; email: string; avatar?: string }) {
    this._currentUser.next({
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      avatar: userData.avatar,
    });
  }

  setHasUpgradeButton(canUpgrade: boolean) {
    this._hasUpgradeButton.next({ hasUpgradeButton: canUpgrade });
  }

  upgradePlan(): void {
    this._upgradePlanRequestedDispatcher.dispatch();
  }
}
