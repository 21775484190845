<div
  class="position-relative d-flex flex-column cs-p-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
    <img
      class="align-self-center cs-width-60-xs cs-height-60-xs cs-mb-10"
      src="/assets/apps/icon/google_css.svg"
    />
    <div
      class="position-relative d-flex flex-column justify-content-between align-items-center cs-bg-shade-0"
    >
      <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
        <h5 class="align-self-center cs-title-5 cs-mb-10">
          {{ 'cobiro_pro_label_manager_non_css_integration_title' | language }}
        </h5>
        <p class="align-self-center text-center cs-width-440-sm cs-text-1">
          {{ 'cobiro_pro_label_manager_non_css_integration_subtitle' | language }}
        </p>
      </div>
    </div>
    <div class="d-flex align-items-start position-relative">
      <form
        class="cs-mt-20 w-100 cs-form"
        [formGroup]="form"
        (ngSubmit)="onFormSubmit()"
        data-selector="switch-integration-form"
      >
        <div class="cs-control-wrapper cs-mb-30">
          <label class="cs-label">{{
            'google_css_integration_google_ads_id_input_label' | language
          }}</label>
          <input
            type="text"
            class="cs-input"
            data-selector="switch-integration-form-ads-id-input"
            formControlName="adsId"
            [placeholder]="'google_css_integration_google_ads_id_input_placeholder' | language"
            huiInputError
          />
          <p
            *ngIf="form.get('adsId').touched && form.get('adsId').hasError(wrongAdsIdErrorKey)"
            class="cs-control-error"
          >
            Wrong Ads ID format. Allowed are: XXX-XXX-XXXX or 10-characters text, without dashes.
          </p>
        </div>

        <div class="cs-control-wrapper cs-mb-30">
          <label class="cs-label">{{ '_email' | language }}</label>
          <input
            type="text"
            formControlName="managerEmail"
            data-selector="switch-integration-form-manager-email-input"
            class="cs-input"
            [placeholder]="'_email' | language"
            huiInputError="managerEmail"
          />
          <p
            *ngIf="
              form.controls.managerEmail.invalid &&
              form.controls.managerEmail.touched &&
              form.controls.managerEmail.hasError('invalidField')
            "
            class="cs-control-error"
          >
            {{ '_invalid_email' | language }}
          </p>
        </div>

        <div class="cs-control-wrapper cs-mb-30">
          <label class="cs-label"
            >{{ 'google_css_integration_business_input_label' | language }}
          </label>
          <input
            type="text"
            class="cs-input"
            formControlName="websiteName"
            data-selector="switch-integration-form-website-name-input"
            huiInputError="websiteName"
          />
        </div>

        <div class="cs-control-wrapper cs-mb-30">
          <label class="cs-label"
            >{{ 'google_css_integration_merchant_id_input_label' | language }}
          </label>
          <input
            type="text"
            class="cs-input"
            formControlName="merchantId"
            [placeholder]="'google_css_integration_merchant_id_input_placeholder' | language"
            data-selector="switch-integration-form-merchant-id-input"
            huiInputError
          />
          <p
            *ngIf="
              form.get('merchantId').touched &&
              form.get('merchantId').hasError(wrongMerchantIdErrorKey)
            "
            class="cs-control-error"
          >
            Wrong merchant ID format. Allowed are only digids and total length must be 7-10 digits.
          </p>
        </div>

        <div class="d-flex justify-content-between cs-mt-20">
          <a
            class="cs-btn cs-btn-flat"
            href="https://support.cobiro.com/hc/en-us/requests/new"
            target="_blank"
          >
            {{ 'cobiro_pro_label_manager_config_window_help_button' | language }}
          </a>
          <button
            class="cs-btn cs-btn-primary cs-width-160-xs"
            data-selector="switch-integration-form-submit"
            type="submit"
          >
            {{ '_continue' | language }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
