import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideEventLogMonitor } from '../event-logger/domain/event-log-monitor';
import { DataLayerMonitor } from './application/data-layer.monitor';
import { DataLayerCobiroProCardsInterestRegisteredEventMapper } from './application/handlers/cobiro-pro/data-layer-cobiro-pro-cards-interest-registered.event-mapper';
import { DataLayerCobiroProCreditInterestRegisteredEventMapper } from './application/handlers/cobiro-pro/data-layer-cobiro-pro-credit-interest-registered.event-mapper';
import { DataLayerCobiroProTeamCreatedEventMapper } from './application/handlers/cobiro-pro/data-layer-cobiro-pro-team-created.event-mapper';
import { DataLayerLabelManagerSimulationRequestEventMapper } from './application/handlers/data-layer-label-manager-simulation-request.event-mapper';
import { DataLayerPartnerProgramRequestEventMapper } from './application/handlers/data-layer-partner-program-request.event-mapper';
import { DataLayerPaymentBillingEventMapper } from './application/handlers/data-layer-payment-billing.event-mapper';
import { DataLayerPaymentConfirmEventMapper } from './application/handlers/data-layer-payment-confirm.event-mapper';
import { DataLayerPaymentMethodEventMapper } from './application/handlers/data-layer-payment-method.event-mapper';
import { DataLayerPaymentPlanSelectedEventMapper } from './application/handlers/data-layer-payment-plan-selected.event-mapper';
import { DataLayerPlanPaymentCompletedEventMapper } from './application/handlers/data-layer-plan-payment-completed.event-mapper';
import { DataLayerPlanPaymentStartedEventMapper } from './application/handlers/data-layer-plan-payment-started.event-mapper';
import { DataLayerPlanSelectedEventMapper } from './application/handlers/data-layer-plan-selected.event-mapper';
import { DataLayerSignInCompletedEventMapper } from './application/handlers/data-layer-sign-in-completed.event-mapper';
import { DataLayerSocialSignedInEventMapper } from './application/handlers/data-layer-social-signed-in.event-mapper';
import { DataLayerUpgradeFlowStartedEventMapper } from './application/handlers/data-layer-upgrade-flow-started.event-mapper';
import { DataLayerUserInterestedInFeatureEventMapper } from './application/handlers/data-layer-user-interested-in-feature.event-mapper';
import { DataLayerUserRegisteredEventMapper } from './application/handlers/data-layer-user-registered.event-mapper';
import { provideDataLayerTrackingMapper } from './domain/data-layer-tracking-mapper';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideEventLogMonitor(DataLayerMonitor),
    provideDataLayerTrackingMapper(DataLayerPaymentPlanSelectedEventMapper),
    provideDataLayerTrackingMapper(DataLayerPaymentBillingEventMapper),
    provideDataLayerTrackingMapper(DataLayerPaymentMethodEventMapper),
    provideDataLayerTrackingMapper(DataLayerPaymentConfirmEventMapper),
    provideDataLayerTrackingMapper(DataLayerPlanSelectedEventMapper),
    provideDataLayerTrackingMapper(DataLayerUpgradeFlowStartedEventMapper),
    provideDataLayerTrackingMapper(DataLayerPlanPaymentStartedEventMapper),
    provideDataLayerTrackingMapper(DataLayerUserInterestedInFeatureEventMapper),
    provideDataLayerTrackingMapper(DataLayerPlanPaymentCompletedEventMapper),
    provideDataLayerTrackingMapper(DataLayerSignInCompletedEventMapper),
    provideDataLayerTrackingMapper(DataLayerUserRegisteredEventMapper),
    provideDataLayerTrackingMapper(DataLayerSocialSignedInEventMapper),
    provideDataLayerTrackingMapper(DataLayerPartnerProgramRequestEventMapper),
    provideDataLayerTrackingMapper(DataLayerLabelManagerSimulationRequestEventMapper),

    // Cobiro Pro
    provideDataLayerTrackingMapper(DataLayerCobiroProTeamCreatedEventMapper),
    provideDataLayerTrackingMapper(DataLayerCobiroProCreditInterestRegisteredEventMapper),
    provideDataLayerTrackingMapper(DataLayerCobiroProCardsInterestRegisteredEventMapper),
  ],
})
export class DataLayerMonitorModule {}
