import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignInStartedEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken } from '@app.cobiro.com/user/core';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserLatestLoginRedirectDto } from '../../infrastructure/user-latest-login-redirect.dto';
import { SavesUserLatestLoginRedirectCommandPort } from '../ports/primary/saves-user-latest-login-redirect.command-port';
import {
  GET_USER_LATEST_LOGIN_REDIRECT_DTO,
  GetsUserLatestLoginRedirectDtoPort,
} from '../ports/secondary/gets-user-latest-login-redirect.dto-port';
import {
  SAVE_USER_LATEST_LOGIN_REDIRECT_DTO,
  SavesUserLatestLoginRedirectDtoPort,
} from '../ports/secondary/saves-user-latest-login-redirect.dto-port';

@Injectable()
export class UserLoginState implements SavesUserLatestLoginRedirectCommandPort {
  private _isLoginRequestPending$ = new BehaviorSubject(false);

  constructor(
    @Inject(APPLICATION_BUS) private readonly _applicationBus: Dispatcher<SignInStartedEvent>,
    @Inject(GETS_USER_FROM_TOKEN) private readonly _getsUserFromToken: GetsUserFromToken,
    @Inject(SAVE_USER_LATEST_LOGIN_REDIRECT_DTO)
    private readonly _savesUserLatestLoginRedirectDtoPort: SavesUserLatestLoginRedirectDtoPort,
    @Inject(GET_USER_LATEST_LOGIN_REDIRECT_DTO)
    private readonly _getsUserLatestLoginRedirectDtoPort: GetsUserLatestLoginRedirectDtoPort,
    private readonly _alert: HuiAlert,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
  ) {}

  saveUserLatestLoginRedirect({ redirectUrl }: { redirectUrl: string }): void {
    this._savesUserLatestLoginRedirectDtoPort.saveUserLatestLoginRedirect({
      redirectUrl,
      userId: this._getsUserFromToken.get().userId,
    });
  }

  get isLoginRequestPending$(): Observable<boolean> {
    return this._isLoginRequestPending$.asObservable();
  }

  login(email: string, password: string): void {
    this._isLoginRequestPending$.next(true);
    this._applicationBus.dispatch(new SignInStartedEvent(email, password, 'v2'));
  }

  finishLogin(userId: string, hasTeams: boolean): void {
    const latestUserLogin: UserLatestLoginRedirectDto | null =
      this._getsUserLatestLoginRedirectDtoPort.getUserLatestLogin();

    if (userId === latestUserLogin?.userId && latestUserLogin?.redirectUrl) {
      this._router.navigate([this.routeToNewCobiroProIfNeeded(latestUserLogin.redirectUrl)], {
        queryParams: { userId },
      });
      return;
    }

    if (hasTeams) {
      this._router.navigate(['connect']);
      return;
    }

    this._router.navigate([
      this.routeToNewCobiroProIfNeeded(
        this._route.snapshot.queryParamMap.get('backUrl') || '/connect',
      ),
    ]);
  }

  finishLoginWithError(errorMessage: string): void {
    this._alert.open('error', errorMessage || 'login_failure');
    this._isLoginRequestPending$.next(false);
  }

  private routeToNewCobiroProIfNeeded(redirectUrl: string): string {
    if (redirectUrl.includes('pro') && !redirectUrl.includes('connect')) {
      return redirectUrl.replace('pro', 'connect');
    }

    return redirectUrl;
  }
}
