import { NgModule } from '@angular/core';
import { DELETES_USER_DTO } from '../../../application/ports/secondary/dto/deletes-user.dto-port';
import { HttpUsersService } from './http-users.service';
import { GETS_ALL_USERS_DTO } from '../../../application/ports/secondary/dto/gets-all-users.dto-port';
import { RESENDS_INVITATION_DTO } from '../../../application/ports/secondary/dto/resends-invitation.dto-port';
import { SENDS_INVITATION_DTO } from '../../../application/ports/secondary/dto/sends-invitation.dto-port';

@NgModule({
  providers: [
    HttpUsersService,
    { provide: DELETES_USER_DTO, useExisting: HttpUsersService },
    { provide: GETS_ALL_USERS_DTO, useExisting: HttpUsersService },
    { provide: SENDS_INVITATION_DTO, useExisting: HttpUsersService },
    { provide: RESENDS_INVITATION_DTO, useExisting: HttpUsersService },
  ],
})
export class HttpUsersServiceModule {}
