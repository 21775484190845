import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVICE_URLS, ServiceUrls } from '@cbr/services';
import { Observable } from 'rxjs';
import { Country, GetsCountries } from '../../domain/gets-countries';

@Injectable()
export class HttpGetsCountriesService implements GetsCountries {
  constructor(private client: HttpClient, @Inject(SERVICE_URLS) private serviceUrls: ServiceUrls) {}

  get(): Observable<Country[]> {
    return this.client.get<Country[]>(
      `${this.serviceUrls.cdn.url}/v1/contents/countries/index.json`,
    );
  }
}
