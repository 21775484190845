import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { WorkspacesSwitcherComponentModule } from '@app.cobiro.com/cobiro-pro-rewrite/workspace-switcher';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { NgModuleWithProviders } from 'ng-mocks';
import { GETS_SIDE_BAR_OPTIONS_QUERY } from '../../../../application/ports/primary/gets-side-bar-options.query-port';
import { SideBarComponent } from './side-bar.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatIconModule,
    RouterModule,
    FeatureFlagsModule,
    WorkspacesSwitcherComponentModule,
  ],
  declarations: [SideBarComponent],
  exports: [SideBarComponent],
})
export class SideBarComponentModule {
  static forRoot(
    getsSideBarOptionsQueryAdapter: object,
  ): NgModuleWithProviders<SideBarComponentModule> {
    return {
      ngModule: SideBarComponentModule,
      providers: [
        {
          provide: GETS_SIDE_BAR_OPTIONS_QUERY,
          useExisting: getsSideBarOptionsQueryAdapter,
        },
      ],
    };
  }
}
