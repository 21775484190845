import { CobiroProSelectedClientDto } from '../secondary/cobiro-pro-selected-client.dto';

export class SelectedClientQuery {
  constructor(
    readonly id: string,
    readonly clientName: string,
    readonly avatar: string | null,
    readonly sitePublicId: string,
  ) {}

  static fromClientDto(clientDto: CobiroProSelectedClientDto) {
    return new SelectedClientQuery(
      clientDto.id,
      clientDto.name,
      clientDto.avatar,
      clientDto.sitePublicId,
    );
  }
}
