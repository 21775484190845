import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GOES_TO_PERSONAL_MODE_COMMAND,
  GoesToPersonalModeCommandPort,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  NAVIGATE_TO_SELECTED_TEAM_HOME_COMMAND,
  NavigateToSelectedTeamHomeCommandPort,
} from '@app.cobiro.com/cobiro-pro/context';
import { map } from 'rxjs/operators';
import { UserMenuDetails, UserMenuItem } from '@app.cobiro.com/shared/hui/user-menu';
import {
  GETS_USER_DATA_QUERY,
  GetsUserDataQueryPort,
} from '../../../../application/ports/primary/gets-user-data.query-port';
import {
  GETS_HAS_UPGRADE_BUTTON_QUERY,
  GetsHasUpgradeButtonQueryPort,
} from '../../../../application/ports/primary/gets-has-upgrade-button.query-port';
import {
  UPGRADES_PLAN_COMMAND,
  UpgradesPlanCommandPort,
} from '../../../../application/ports/primary/upgrades-plan.command-port';

@Component({
  selector: 'lib-cobiro-pro-top-bar',
  templateUrl: './cobiro-pro-top-bar.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProTopBarComponent {
  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(GOES_TO_PERSONAL_MODE_COMMAND)
    private _goesToPersonalModeCommand: GoesToPersonalModeCommandPort,
    @Inject(GETS_USER_DATA_QUERY) private _getsUserDataQuery: GetsUserDataQueryPort,
    @Inject(GETS_HAS_UPGRADE_BUTTON_QUERY)
    private _getsHasUpgradeButtonQuery: GetsHasUpgradeButtonQueryPort,
    @Inject(NAVIGATE_TO_SELECTED_TEAM_HOME_COMMAND)
    private _navigateToHomeCommand: NavigateToSelectedTeamHomeCommandPort,
    @Inject(UPGRADES_PLAN_COMMAND)
    private _upgradesPlanCommand: UpgradesPlanCommandPort,
  ) {}

  public readonly hasUpgradeButton$: Observable<boolean> = this._getsHasUpgradeButtonQuery
    .getHasUpgradeButton()
    .pipe(map(query => query.hasUpgradeButton));

  public readonly userDetails$: Observable<UserMenuDetails> = this._getsUserDataQuery
    .getUserData()
    .pipe(
      map(query => {
        return {
          firstName: query.firstName,
          lastName: query.lastName,
          email: query.email,
          avatar: query.avatar,
        };
      }),
    );

  public readonly userMenuItems: UserMenuItem[] = [
    {
      callback: () => this._goesToPersonalModeCommand.goToPersonalMode().subscribe(),
      translationKey: 'nav_top_bar_user_menu_my_sites',
    },
    {
      routerLink: ['/settings'],
      translationKey: 'nav_top_bar_user_menu_settings',
    },
    {
      routerLink: ['/user/logout'],
      translationKey: 'nav_top_bar_user_menu_logout',
    },
  ];

  readonly currentTeam$: Observable<{
    id: string;
    name: string;
    avatar: string;
  }> = this._getsCobiroProContextQuery.getContext().pipe(
    map(context => ({
      id: context.selectedTeamId,
      name: context.selectedTeamName,
      avatar: context.selectedTeamAvatar,
    })),
  );

  onCurrentTeamClicked() {
    this._navigateToHomeCommand.navigateHome();
  }

  onUpgradeButtonClicked(): void {
    this._upgradesPlanCommand.upgradePlan();
  }
}
