import { UserChangedEvent } from '@app.cobiro.com/core/events';
import { Injectable } from '@angular/core';
import { CompleteProfileState } from '../../application/complete-profile.state';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class CompleteProfileUserChangedEventHandler implements ApplicationEventHandler {
  eventClass = UserChangedEvent;
  constructor(private _completeProfileState: CompleteProfileState) {}
  handle(event: UserChangedEvent) {
    this._completeProfileState.setGuestUser(event.user.isGuest);
  }
}
