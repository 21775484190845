import { NgModule } from '@angular/core';
import { UserModalsModule } from '../../adapters/primary/ui/modals/user-modals.module';
import { UsersListChangedEventDispatcher } from '../../adapters/secondary/dispatchers/users-list-changed.event-dispatcher';
import { CHECKS_USER_INVITATION_COMMAND } from '../ports/primary/checks-user-invitation.command-port';
import { DELETES_USER_COMMAND } from '../ports/primary/deletes-user.command-port';
import { INVITES_USER_COMMAND } from '../ports/primary/invites-user.command-port';
import { RESENDS_INVITATION_COMMAND } from '../ports/primary/resends-invitation.command-port';
import { USERS_LIST_CHANGED_DISPATCHER } from '../ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import { UsersState } from './user.state';

@NgModule({
  imports: [UserModalsModule],
  providers: [
    UsersState,
    { provide: CHECKS_USER_INVITATION_COMMAND, useExisting: UsersState },
    { provide: INVITES_USER_COMMAND, useExisting: UsersState },
    { provide: RESENDS_INVITATION_COMMAND, useExisting: UsersState },
    { provide: DELETES_USER_COMMAND, useExisting: UsersState },
    { provide: USERS_LIST_CHANGED_DISPATCHER, useClass: UsersListChangedEventDispatcher },
  ],
})
export class UsersStateModule {}
