import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserAdditionalDataChangedEvent } from '@app.cobiro.com/core/events';
import { TOP_BAR_STATE_SETTER, TopBarStateSetter } from '../application/top-bar.state';

@Injectable()
export class TopBarUserAdditionalDataChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserAdditionalDataChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(TOP_BAR_STATE_SETTER) private readonly _topbarStateSetter: TopBarStateSetter,
  ) {}

  handle([event]: [UserAdditionalDataChangedEvent]): void {
    this._topbarStateSetter.setHasTeams(event.hasTeams);
  }
}
