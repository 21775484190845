import { InjectionToken } from '@angular/core';

interface HasUrl {
  url: string;
}

/**
 * @deprecated
 */
export interface ServiceUrls {
  [key: string]: HasUrl;
}

/**
 * @deprecated
 */
export const SERVICE_URLS = new InjectionToken<ServiceUrls>('SERVICE_URLS');
