/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { GetsLabelManagerReportDto } from '../../../../application/ports/secondary/gets-label-manager-report.dto';
import {
  LabelManagerReportDto,
  LabelTitle,
} from '../../../../application/ports/secondary/label-manager-report.dto';
import { GetsLabelManagerReportDtoPort } from '../../../../application/ports/secondary/gets-label-manager-report.dto-port';

export interface LabelManagerReportAttributes {
  id?: string;
  status: string;
  error: null | { code: string };
  date: string;
  totalProducts: number;
  labels: Record<LabelTitle, { count: number }>;
  history: {
    date: string;
    totalProducts: number;
    labels: Record<LabelTitle, { count: number }>;
  }[];
}

interface LabelManagerReportModel {
  id: string;
  type: string;
  attributes: LabelManagerReportAttributes;
}

@Injectable()
export class HttpLabelManagerReportService implements GetsLabelManagerReportDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  getReport(dto: GetsLabelManagerReportDto): Observable<LabelManagerReportDto> {
    return this._client
      .get<{ data: LabelManagerReportModel }>(`v1/google-css/${dto.integrationId}/labelizer-report`)
      .pipe(map(({ data }: { data: LabelManagerReportModel }) => this._mapReportData(data)));
  }
  private _mapReportData(object: LabelManagerReportModel): LabelManagerReportDto {
    const history = object.attributes.history ? object.attributes.history : [];
    return {
      id: object.id,
      status: object.attributes.status,
      error: object.attributes.error,
      data: Array.of(
        {
          date: object.attributes.date,
          totalProducts: object.attributes.totalProducts,
          labels: object.attributes.labels,
        },
        ...history,
      ).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    };
  }
}
