<ul class="cs-p-0 cs-m-0 navigation-list d-flex align-items-center justify-content-center">
  <li *ngFor="let navItem of navigationItems$ | async" class="cs-mr-5">
    <a
      *ngIf="navItem.isExternal"
      [href]="navItem.url"
      target="_blank"
      rel="noopener noreferrer"
      class="cs-btn cs-btn-sm cs-btn-ghost"
      [libDataSelector]="'nav-list-item-' + navItem.name"
    >
      {{ navItem.name | language }}
    </a>
    <a
      *ngIf="!navItem.isExternal"
      [routerLink]="[navItem.url]"
      class="cs-btn cs-btn-sm cs-btn-ghost"
      [libDataSelector]="'nav-list-item-' + navItem.name"
    >
      {{ navItem.name | language }}
    </a>
  </li>
  <li *ngIf="hasUpgradeButton$ | async">
    <button
      class="cs-btn cs-btn-sm cs-btn-gradient"
      data-selector="nav-list-item-upgrade-plan-button"
      (click)="onUpgradeButtonClicked()"
    >
      <mat-icon [svgIcon]="'crown'" class="cs-btn-icon"></mat-icon>
      {{ '_upgrade' | language }}
    </button>
  </li>
  <li *libRoles="['admin']">
    <button
      [matMenuTriggerFor]="adminNavigationMenu"
      class="cs-btn cs-btn-sm cs-btn-ghost"
      data-selector="'nav-list-item-navigation_topbar_admin'"
    >
      {{ 'navigation_topbar_admin' | language }}
    </button>
    <mat-menu
      #adminNavigationMenu="matMenu"
      xPosition="after"
      yPosition="below"
      backdropClass="cs-mat-menu-backdrop"
      class="cs-mat-menu"
    >
      <ng-template matMenuContent>
        <a mat-menu-item [routerLink]="['/admin/templates']">
          {{ 'navigation_topbar_admin_templates' | language }}
        </a>
        <!-- TODO: wire navigation to categories  -->
        <a mat-menu-item [routerLink]="['/admin/categories']">
          {{ 'navigation_topbar_admin_categories' | language }}
        </a>
      </ng-template>
    </mat-menu>
  </li>
</ul>
