import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationInfoQuery } from './integration-info.query';

export const SETS_INTEGRATION_QUERY = new InjectionToken<SetsIntegrationQueryPort>(
  'SETS_INTEGRATION_QUERY',
);

export interface SetsIntegrationQueryPort {
  setIntegration(integration: IntegrationInfoQuery): Observable<boolean>;
}
