import { InjectionToken } from '@angular/core';
import { ServiceUrls } from '@cbr/services';
import { Environment } from '../../../../../../environments/environment.config';

export interface GetsConfig {
  // (Deprecated) Backward compatibility usage only
  services: ServiceUrls;

  // (Deprecated) Backward compatibility usage only
  keys: Map<string, string>;

  get(key: keyof Environment): string | {} | never;
  get<T extends Environment[keyof Environment]>(key: keyof Environment): T;
}

export const ENV_CONFIG = new InjectionToken<GetsConfig>('ENV_CONFIG');

export const envConfigStubProvider = (keys: Map<string, string>) => {
  return {
    provide: ENV_CONFIG,
    useFactory: () => {
      return {
        keys,
        get: key => keys.get(key),
      };
    },
  };
};
