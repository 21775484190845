import { FeaturesLoadedEvent } from '@app.cobiro.com/core/payments-features';
import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, mergeEventsOrchestrationStrategy } from '@cobiro/eda';
import {
  SETS_HAS_UPGRADE_BUTTON_COMMAND,
  SetsHasUpgradeButtonCommandPort,
} from '../../../application/ports/primary/sets-has-upgrade-button.command-port';
import { CobiroProEnterDashboardEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class CobiroProLayoutHasUpgradeButtonEventHandler implements ApplicationEventsHandler {
  eventsClasses = [FeaturesLoadedEvent, CobiroProEnterDashboardEvent];
  strategy = mergeEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_HAS_UPGRADE_BUTTON_COMMAND)
    private _setsHasUpgradeButtonCommand: SetsHasUpgradeButtonCommandPort,
  ) {}

  handle([event]: (FeaturesLoadedEvent | CobiroProEnterDashboardEvent)[]) {
    this._setsHasUpgradeButtonCommand.setHasUpgradeButton(this._canUpgrade(event));
  }

  private _canUpgrade(event: FeaturesLoadedEvent | CobiroProEnterDashboardEvent): boolean {
    if (event instanceof FeaturesLoadedEvent) {
      return (event as FeaturesLoadedEvent).features.has('PAY_SHOW-UPGRADE');
    }

    return false;
  }
}
