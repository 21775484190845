import { TrackingService } from '../tracking.service';
import { Injectable } from '@angular/core';
import { TrackingDataCollectedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class TrackingDataCollectedEventHandler implements ApplicationEventHandler {
  eventClass = TrackingDataCollectedEvent;

  constructor(private _trackingService: TrackingService) {}

  handle(event: TrackingDataCollectedEvent): void {
    this._trackingService.event(event.code, event.data);
  }
}
