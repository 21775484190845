import { Observable } from 'rxjs';
import { SelectedClientQuery } from './selected-client.query';
import { InjectionToken } from '@angular/core';

export interface GetsSelectedClientQuery {
  getSelectedClient(): Observable<SelectedClientQuery>;
}

export const GET_SELECTED_CLIENT_QUERY = new InjectionToken<GetsSelectedClientQuery>(
  'GET_SELECTED_CLIENT_QUERY',
);
