import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HUB_URL } from '@app.cobiro.com/common/hub';
import { HasData, HasDataCollection } from '@cobiro/jsonapi';
import { GoogleLocation } from '../domain/google-location';

export interface GoogleLocationAttributes {
  target_type: string;
  country_code: string;
  canonical_name: string;
  name: string;
  status: string;
  reach: number;
  latitude: number;
  longitude: number;
  created_at: Date;
  updated_at: Date;
}

@Injectable()
export class HttpLocationsApiService {
  constructor(private _client: HttpClient, @Inject(HUB_URL) private hub: string) {}

  getLocation(googleId: string): Observable<GoogleLocation> {
    return this._client
      .get<HasData<GoogleLocationAttributes>>(`${this.hub}/v1/google-locations/${googleId}`)
      .pipe(map(response => this._hydrateToGoogleLocation(response)));
  }

  getLocationsByName(
    canonicalName: string,
    pageSize: number = 10,
    countryCodesFilter?: string[],
  ): Observable<GoogleLocation[]> {
    return this._client
      .get<HasDataCollection<GoogleLocationAttributes>>(`${this.hub}/v1/google-locations`, {
        params: this._makeParams(canonicalName, pageSize, countryCodesFilter),
      })
      .pipe(
        map(locations =>
          locations.data.map(location => this._hydrateToGoogleLocation({ data: location })),
        ),
      );
  }

  private _makeParams(
    canonicalName: string,
    pageSize: number,
    countryCodesFilter?: string[],
  ): HttpParams {
    let params = new HttpParams()
      .append('filter[canonical_name]', canonicalName)
      .append('page[size]', pageSize.toString());

    if (countryCodesFilter && countryCodesFilter.length < pageSize) {
      countryCodesFilter.forEach(code => (params = params.append('filter[country_codes][]', code)));
    }

    return params;
  }

  private _hydrateToGoogleLocation(response: HasData<GoogleLocationAttributes>): GoogleLocation {
    const { id, attributes } = response.data;

    return {
      id: id,
      targetType: attributes.target_type,
      countryCode: attributes.country_code,
      name: attributes.canonical_name,
      status: attributes.status,
      reach: attributes.reach,
      latitude: attributes.latitude,
      longitude: attributes.longitude,
    };
  }
}
