import { Inject, Injectable } from '@angular/core';

export interface GtmEvent {
  event: string;
  user_id?: string;
  user_email?: string;
}

interface DataLayerContainer {
  dataLayer: any;
}

@Injectable({
  providedIn: 'root',
})
export class GtmTracker {
  constructor(
    @Inject('TAG_MANAGER_KEY') private tagManagerKey: string,
    @Inject('TAG_MANAGER_ENABLED') private tagManagerEnabled: boolean,
    @Inject('DATA_LAYER_CONTAINER') private dataLayerContainer: DataLayerContainer,
  ) {
    this.init();
  }

  track(eventData: GtmEvent) {
    if (!this.tagManagerEnabled) {
      return;
    }
    this.dataLayerContainer.dataLayer.push(eventData);
  }

  private init() {
    if (!this.tagManagerEnabled) {
      return;
    }

    const scriptTagName = 'script';
    const dataLayerKey = 'dataLayer';

    window[dataLayerKey] = window[dataLayerKey] || [];
    window[dataLayerKey].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const firstScriptElement = document.getElementsByTagName(scriptTagName)[0];
    const newScriptElement = document.createElement(scriptTagName);
    const dataLayerParam = '&l=' + dataLayerKey;
    newScriptElement.async = true;
    newScriptElement.src =
      'https://www.googletagmanager.com/gtm.js?id=' + this.tagManagerKey + dataLayerParam;
    firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement);
  }
}
