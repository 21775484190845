import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProTopBarComponent } from './cobiro-pro-top-bar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CobiroProClientsHistoryModule } from '@app.cobiro.com/cobiro-pro/clients-history';
import { HuiUserMenuModule } from '@app.cobiro.com/shared/hui/user-menu';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { TeamsSwitcherWidgetComponentModule } from '@app.cobiro.com/cobiro-pro-teams-switcher';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    HuiUserMenuModule,
    HuiAvatarModule,
    FeatureFlagsModule,
    CobiroProClientsHistoryModule,
    TeamsSwitcherWidgetComponentModule,
    // TODO: top-bar shouldn't know that it has a clients-history, decouple with ng-content in page?
  ],
  declarations: [CobiroProTopBarComponent],
  exports: [CobiroProTopBarComponent],
})
export class CobiroProTopBarComponentModule {}
