import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsHistoryManagerComponentModule } from './adapters/primary/ui/clients-history-manager/clients-history-manager.component.module';
import { ClientsHistoryManagerStateModule } from './application/state/clients-history-manager.state-module';

@NgModule({
  imports: [CommonModule, ClientsHistoryManagerComponentModule, ClientsHistoryManagerStateModule],
  exports: [ClientsHistoryManagerComponentModule],
})
export class CobiroProClientsHistoryModule {}
