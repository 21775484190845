import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  GOES_TO_PERSONAL_MODE_COMMAND,
  GoesToPersonalModeCommandPort,
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
} from '@app.cobiro.com/cobiro-pro/context';
import { combineLatest, Observable, of } from 'rxjs';
import { exhaustMap, map, take } from 'rxjs/operators';
import {
  GETS_TEAM_SWITCHER_ITEMS_QUERY,
  GetsTeamSwitcherItemsQuery,
  TeamSwitcherItemQuery,
} from '../../../../application/ports/primary/gets-team-switcher-items.query';

type TeamSwitcherMenuItem = TeamSwitcherItemQuery & { selected: boolean };

@Component({
  selector: 'lib-teams-switcher-widget',
  templateUrl: './teams-switcher-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    ['data-selector']: 'teams-switcher-widget',
    class: 'd-block',
  },
})
export class TeamsSwitcherWidgetComponent {
  readonly isInPersonalSpace$ = this._getsCobiroProContextQueryPort
    .getContext()
    .pipe(map(context => !context.selectedTeamId));
  readonly teamSwitcherItems$: Observable<TeamSwitcherMenuItem[] | null> = combineLatest([
    this._getsTeamSwitcherItemsQuery.getTeamSwitcherItemsQuery(),
    this._getsCobiroProContextQueryPort.getContext(),
  ]).pipe(
    map(
      ([teams, context]) =>
        teams && teams.map(team => ({ ...team, selected: team.id === context.selectedTeamId })),
    ),
  );

  isWidgetOpened = false;

  constructor(
    @Inject(GETS_TEAM_SWITCHER_ITEMS_QUERY)
    private readonly _getsTeamSwitcherItemsQuery: GetsTeamSwitcherItemsQuery,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQueryPort: GetsCobiroProContextQueryPort,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private readonly _setsSelectedTeamCommandPort: SetsSelectedTeamCommandPort,
    @Inject(GOES_TO_PERSONAL_MODE_COMMAND)
    private readonly _disablesProModeCommandPort: GoesToPersonalModeCommandPort,
  ) {}

  trackById(item: TeamSwitcherItemQuery): string {
    return item.id;
  }

  onItemClicked(item: TeamSwitcherMenuItem): void {
    this._getsCobiroProContextQueryPort
      .getContext()
      .pipe(
        take(1),
        exhaustMap(context => {
          if (context.selectedTeamId !== item.id) {
            return this._setsSelectedTeamCommandPort.setSelectedTeam(
              item.id,
              item.name,
              item.role,
              item.avatar,
            );
          }

          return of(false);
        }),
      )
      .subscribe();
  }

  onPersonalWorkspaceClicked(): void {
    this._disablesProModeCommandPort.goToPersonalMode().subscribe();
    this._setsSelectedTeamCommandPort.setSelectedTeam(null, null, null, null).subscribe();
  }
}
