<ng-container *ngIf="hasTopBar$ | async">
  <ng-content select="[topBar]"></ng-content>
</ng-container>
<lib-attention-message-widget *ngIf="hasWarning$ | async"> </lib-attention-message-widget>
<lib-impersonation-widget> </lib-impersonation-widget>
<section
  [class.bg-transparent]="layoutBgTransparent$ | async"
  class="layout-container h-100 d-flex flex-1"
>
  <aside *ngIf="(hasNavigation$ | async) && (hasSidebar$ | async)" class="layout-sidebar">
    <ng-container *ngTemplateOutlet="navigation"></ng-container>
  </aside>

  <article class="layout-body d-flex flex-1 flex-column">
    <main
      #bodyContent
      class="d-flex flex-column flex-1-0 w-100 overflow-x-hidden overflow-y-auto h-100"
      id="layout-body-scroll-container"
    >
      <ng-content select="[routerOutlet]"></ng-content>
    </main>

    <nav
      *ngIf="(hasNavigation$ | async) && (hasBottombar$ | async) && (hasSidebar$ | async)"
      class="d-none"
    >
      <ng-container *ngTemplateOutlet="navigation"></ng-container>
    </nav>

    <button
      class="cs-btn cs-btn-stroked d-none"
      (click)="goBackToTheApp()"
      *ngIf="hasBackButton$ | async"
    ></button>
  </article>
</section>

<ng-template #navigation>
  <lib-sidebar [site]="site$ | async"></lib-sidebar>
  <div class="d-block d-md-none w-100 position-absolute cs-bottom-0 nav-sidebar-slim cs-z-index-3">
    <img
      (click)="onLogoClicked()"
      class="cs-cursor-pointer cs-width-32-xs cs-height-32-xs m-auto d-none d-md-block"
      slot="nav-header-item"
      src="/assets/logos/cobiro-logo-color.svg"
    />
    <div
      class="d-flex justify-content-between flex-grow-1 cs-ph-15 cs-bg-shade-0 cs-bt-1 cs-b-shade-10"
    >
      <ng-container *ngFor="let sidebarItem of sidebarItems$ | async">
        <article
          (click)="onSidebarItemClicked(sidebarItem)"
          [class.active]="sidebarItem.active"
          class="d-flex align-items-center justify-content-center cs-size-xl sidebar-item"
        >
          <mat-icon [svgIcon]="sidebarItem.icon" class="cs-size-md cs-fill-shade-40"></mat-icon>
        </article>
      </ng-container>
    </div>
  </div>
</ng-template>
