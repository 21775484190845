import { NgModule } from '@angular/core';
import { ApplicationBusCobiroProUpgradePlanRequestedDispatcher } from './application-bus-cobiro-pro-upgrade-plan-requested.dispatcher';
import { UPGRADE_PLAN_REQUESTED_DISPATCHER } from '../../../application/ports/secondary/upgrade-plan-requested.dispatcher-port';

@NgModule({
  providers: [
    ApplicationBusCobiroProUpgradePlanRequestedDispatcher,
    {
      provide: UPGRADE_PLAN_REQUESTED_DISPATCHER,
      useExisting: ApplicationBusCobiroProUpgradePlanRequestedDispatcher,
    },
  ],
})
export class ApplicationBusCobiroProUpgradePlanRequestedDispatcherModule {}
