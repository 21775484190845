import { ActivatedRouteSnapshot } from '@angular/router';
import { Inject, Injector, Provider } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { Observable, of } from 'rxjs';

export class UtmResolver  {
  constructor(@Inject(STORAGE) private _storage: Storage, private _utmKey: string) {
    if (!this._utmKey || this._utmKey === '') {
      throw new NoKeyDataProvidedError();
    }
  }

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const utmValue = route.queryParamMap.get(this._utmKey);

    if (utmValue && utmValue !== '') {
      this._storage.setItem(this._utmKey, utmValue);
      return of(utmValue);
    }

    return of(null);
  }
}

export class NoKeyDataProvidedError extends Error {
  public readonly message = 'No utm key data provided for utm resolver!';
  public readonly name = 'NO_KEY_DATA_PROVIDED';
}

export const makeUtmResolver = (injector: Injector, utmKey: string) => {
  const storage = injector.get(STORAGE);
  return new UtmResolver(storage, utmKey);
};

export const provideUtmResolver = (name: string, utmKey: string): Provider => {
  return {
    provide: name,
    useFactory: (injector: Injector) => makeUtmResolver(injector, utmKey),
    deps: [Injector],
  };
};
