import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Auth, UserState } from '../user.state';
import { UserImpersonateLogoutEvent } from '@app.cobiro.com/core/events';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';

// TODO: This one will have to be moved to a lib - it should not stay here in project/cobiro legacy code
@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard  {
  constructor(
    @Inject(APPLICATION_BUS) private _applicationBus: Dispatcher<UserImpersonateLogoutEvent>,
    private router: Router,
    private userState: UserState,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.userState.getAuth().pipe(
      take(1),
      switchMap((auth: Auth) => {
        if (!auth?.accessToken) {
          return throwError('Not authenticated');
        }
        return this.userState.getCurrent().pipe(take(1));
      }),
      map(() => true),
      catchError(_ => {
        // TODO: investigate why this guard is opened after logout and throws this error
        this._applicationBus.dispatch(new UserImpersonateLogoutEvent());
        return of(
          this.router.createUrlTree(['/auth/login'], {
            queryParams: {
              backUrl: state.url,
              ...state.root.queryParams,
            },
          }),
        );
      }),
    );
  }
}
