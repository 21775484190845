<img
  *ngIf="avatarUrl; else backupAvatar"
  class="cs-avatar"
  [ngClass]="'cs-avatar-' + shape + ' ' + customClasses?.join(' ')"
  [class.cs-avatar-xxl]="size === 'xxl'"
  [class.cs-avatar-sm]="size === 'small'"
  [src]="avatarUrl"
  [alt]="name"
  data-selector="hui-avatar-image"
/>
<ng-template #backupAvatar>
  <div
    class="cs-avatar cs-avatar-text"
    *ngIf="name"
    [ngClass]="'cs-avatar-' + shape + ' ' + customClasses?.join(' ')"
    [class.cs-avatar-sm]="size === 'small'"
    [class.cs-avatar-xxl]="size === 'xxl'"
  >
    <span
      [ngClass]="{
        'cs-title-7': size === 'regular',
        'cs-title-8': size === 'small',
        'cs-title-4': size === 'xxl'
      }"
      class="cs-c-shade-40"
      data-selector="hui-avatar-text"
    >
      {{ name[0]?.toUpperCase() }}
    </span>
  </div>
</ng-template>
