import { Injectable } from '@angular/core';
import { UserChangedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { LayoutState } from '../../layout/layout.state';

@Injectable()
export class HideTopBarEventsHandler implements ApplicationEventsHandler {
  eventsClasses = [UserChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly layoutState: LayoutState) {}

  handle([event]: UserChangedEvent[]) {
    const { isGuest } = event.user;
    if (!isGuest) {
      return;
    }

    this.layoutState.dispatch({ hasTopbar: false });
  }
}
