import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserChangedEvent } from '@app.cobiro.com/core/events';
import { Inject, Injectable } from '@angular/core';
import {
  SETS_USER_DATA_COMMAND,
  SetsUserDataCommandPort,
} from '../../../application/ports/primary/sets-user-data.command-port';

@Injectable()
export class CobiroProLayoutUserChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_USER_DATA_COMMAND) private _setsUserDataCommand: SetsUserDataCommandPort,
  ) {}

  handle([event]: [UserChangedEvent]) {
    this._setsUserDataCommand.setUserData({
      firstName: event.user.firstName,
      lastName: event.user.lastName,
      email: event.user.email,
      avatar: event.user.avatar,
    });
  }
}
