import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationStart, Resolve, Router, Event } from '@angular/router';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { UserState } from '../../../../auth/src/lib/application/user.state';
import { LayoutState } from './layout.state';

@Injectable({
  providedIn: 'root',
})
export class LayoutResolver implements Resolve<void> {
  constructor(
    private readonly layoutState: LayoutState,
    private readonly userState: UserState,
    private readonly router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { topbarItems, sidebarItems, bottombarItems, activeSidebarItem, hasSidebar, hasTopbar } =
      route.data;

    this.userState.user$
      .pipe(
        takeUntil(this.router.events.pipe(filter((e: Event) => e instanceof NavigationStart))),
        tap(({ isGuest }) => {
          this.layoutState.dispatch({
            topbarItems,
            sidebarItems,
            bottombarItems,
            activeSidebarItem,
            hasSidebar,
            hasTopbar: hasTopbar && !isGuest,
            activatedRoute: route,
          });
        }),
      )
      .subscribe();
  }
}
