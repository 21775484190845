export enum TopbarItems {
  home = 'home',
  store = 'store',
  build = 'build',
  grow = 'grow',
  promote = 'promote',
  settings = 'settings',
  profile = 'profile',
  notifications = 'notifications',
  billing = 'billing',
  appIdentifier = ':appIdentifier',
  articleSlug = ':articleSlug',
  domains = 'domains',
}

export interface NavTopbarItem {
  title: string;
  icon?: string;
  route?: string;
}

export const topbarItemsMap = new Map([
  [
    TopbarItems.home,
    {
      title: 'navigation_topbar_breadcrumb_home',
      icon: 'home',
      route: '/site/:siteId',
    },
  ],
  [
    TopbarItems.store,
    {
      title: 'navigation_topbar_breadcrumb_store',
      icon: 'store',
      route: '/site/:siteId/store',
    },
  ],
  [
    TopbarItems.build,
    {
      title: 'navigation_topbar_breadcrumb_build',
      icon: 'build',
      route: '/site/:siteId/build',
    },
  ],
  [
    TopbarItems.grow,
    {
      title: 'navigation_topbar_breadcrumb_grow',
      icon: 'grow',
      route: '/site/:siteId/grow',
    },
  ],
  [
    TopbarItems.promote,
    {
      title: 'navigation_topbar_breadcrumb_promote',
      icon: 'promote',
      route: '/site/:siteId/promote',
    },
  ],
  [
    TopbarItems.settings,
    {
      title: 'navigation_topbar_breadcrumb_settings',
      icon: 'gear',
      route: '/settings',
    },
  ],
  [
    TopbarItems.profile,
    {
      title: 'navigation_topbar_breadcrumb_profile',
      route: '/settings/profile',
    },
  ],
  [
    TopbarItems.notifications,
    {
      title: 'navigation_topbar_breadcrumb_notifications',
      route: '/settings/notification',
    },
  ],
  [
    TopbarItems.billing,
    {
      title: 'navigation_topbar_breadcrumb_billing',
      route: '/settings/billing',
    },
  ],
  [
    TopbarItems.appIdentifier,
    {
      title: ':appIdentifier',
    },
  ],
  [
    TopbarItems.articleSlug,
    {
      title: ':articleSlug',
      route: '/site/:siteId/store/article/:articleSlug',
    },
  ],
  [
    TopbarItems.domains,
    {
      title: 'domains_title',
      route: '/site/:siteId/domains',
    },
  ],
]);
