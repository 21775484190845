import { InjectionToken } from '@angular/core';
import { UIState } from '@app.cobiro.com/shared/ui-state';

export const TOPBAR_BORDER_HIDDEN: InjectionToken<boolean> = new InjectionToken<boolean>(
  'TOPBAR_BORDER_HIDDEN',
);
export const LAYOUT_BG_TRANSPARENT: InjectionToken<boolean> = new InjectionToken<boolean>(
  'LAYOUT_TRANSPARENT',
);

export const TOPBAR_BORDER_HIDDEN_STUB_PROVIDER = {
  provide: TOPBAR_BORDER_HIDDEN,
  useFactory: () => new UIState<boolean>(false),
};

export const LAYOUT_BG_TRANSPARENT_STUB_PROVIDER = {
  provide: LAYOUT_BG_TRANSPARENT,
  useFactory: () => new UIState<boolean>(false),
};
