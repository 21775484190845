import { Injectable } from '@angular/core';
import { ServiceUrls } from '@cbr/services';
import { Environment } from '../../../../../../environments/environment.config';
import { GetsConfig } from './gets-config';

@Injectable()
export class WindowEnvConfig implements GetsConfig {
  static readonly configKey = 'config';

  constructor() {}

  private get _env(): Environment {
    const browserWindow = window || {};
    return browserWindow[WindowEnvConfig.configKey] || {};
  }

  get<T extends Environment[keyof Environment]>(key: keyof Environment): T {
    if (this._env[key] === undefined) {
      throw new Error(`Cannot find value for key: ${key}`);
    }

    return this._env[key] as T;
  }

  // (Deprecated) Backward compatibility usage only
  get services(): ServiceUrls {
    return {
      gateway: { url: this._env.gateway },
      customer: { url: this._env.customer },
      hub: { url: this._env.hub },
      cdn: { url: this._env.cdn },
      publicCdn: { url: this._env.publicCdn },
      optimizely: { url: this._env.optimizely.url },
    };
  }

  // (Deprecated) Backward compatibility usage only
  get keys(): Map<string, string> {
    return new Map([
      ['optimizely', this._env.optimizely.key],
      ['adyen_env', this._env.adyen.env],
      ['adyen', this._env.adyen.originKey],
      ['googleFonts', this._env.google.fonts],
    ]);
  }
}

export const provideWindowConfig = () => (window || {})[WindowEnvConfig.configKey] as Environment;
