import { Inject, Injectable } from '@angular/core';
import {
  ApplicationEvent,
  ApplicationEventsHandler,
  ongoingEventsOrchestrationStrategy,
} from '@cobiro/eda';
import { SiteSelectedEvent } from '@app.cobiro.com/core/events';
import { APP_PRELOADER_VISIBLE, UIState } from '@app.cobiro.com/shared/ui-state';

@Injectable()
export class PreloaderVisibleSiteSelectedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [SiteSelectedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(@Inject(APP_PRELOADER_VISIBLE) private _appPreloaderVisible: UIState<boolean>) {}

  handle(events: ApplicationEvent[]) {
    this._appPreloaderVisible.set(true);
  }
}
