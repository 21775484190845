import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  GET_SELECTED_CLIENT_QUERY,
  GetsSelectedClientQuery,
} from '../../../../application/ports/primary/gets-selected-client.query-port';
import { Observable } from 'rxjs';
import { SelectedClientQuery } from '../../../../application/ports/primary/selected-client.query';
import {
  MANAGER_BACK_TO_WORKSPACE_COMMAND,
  ManagerBackToWorkspaceCommandPort,
} from '../../../../application/ports/primary/manager-back-to-workspace.command-port';
import { VisitedClientQuery } from '../../../../application/ports/primary/visited-client.query';
import {
  GETS_VISITED_CLIENTS_QUERY,
  GetsVisitedClientsQueryPort,
} from '../../../../application/ports/primary/gets-visited-clients.query-port';
import {
  CHANGES_SELECTED_CLIENT_COMMAND,
  ChangesSelectedClientCommandPort,
} from '../../../../application/ports/primary/changes-selected-client.command-port';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-client-workspace-manager',
  templateUrl: './clients-history-manager.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'd-block mr-auto',
  },
})
export class ClientsHistoryManagerComponent {
  readonly selectedClient$: Observable<SelectedClientQuery | null> =
    this._getsSelectedClientQuery.getSelectedClient();
  readonly visitedClients$: Observable<VisitedClientQuery[]> =
    this._getVisitedClientsQuery.getVisitedClients();
  readonly isManagerVisible$: Observable<boolean> = this.visitedClients$.pipe(
    map((visitedClients: VisitedClientQuery[]) => visitedClients?.length > 0),
  );

  isMenuOpened: boolean;

  constructor(
    @Inject(GET_SELECTED_CLIENT_QUERY)
    private readonly _getsSelectedClientQuery: GetsSelectedClientQuery,
    @Inject(MANAGER_BACK_TO_WORKSPACE_COMMAND)
    private readonly _managerBackToWorkspace: ManagerBackToWorkspaceCommandPort,
    @Inject(GETS_VISITED_CLIENTS_QUERY)
    private readonly _getVisitedClientsQuery: GetsVisitedClientsQueryPort,
    @Inject(CHANGES_SELECTED_CLIENT_COMMAND)
    private readonly _changeSelectedClientCommand: ChangesSelectedClientCommandPort,
  ) {}

  onBackButtonClicked(): void {
    this._managerBackToWorkspace.backToWorkspace();
  }

  changeClient(clientId: string): void {
    this._changeSelectedClientCommand.changeClient(clientId);
  }
}
