import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserSignedOutEvent } from '@app.cobiro.com/core/events';
import {
  CLEARS_CLIENTS_HISTORY_COMMAND_PORT,
  ClearsClientsHistoryCommandPort,
} from '../../../application/ports/primary/clears-clients-history.command-port';

@Injectable()
export class ClientsHistoryUserSignedOutEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserSignedOutEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(CLEARS_CLIENTS_HISTORY_COMMAND_PORT)
    private readonly _clearsClientHistoryCommandPort: ClearsClientsHistoryCommandPort,
  ) {}

  handle([event]: [UserSignedOutEvent]) {
    this._clearsClientHistoryCommandPort.clear();
  }
}
