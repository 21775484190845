<ng-container *ngIf="user$ | async as user">
  <button
    class="cs-btn cs-btn-ghost cs-title-8 cs-c-shade-60 d-flex justify-content-center align-items-center cs-pl-50 cs-pr-15"
    data-analytics="topbar-user-menu"
    [mat-menu-trigger-for]="menu"
    data-selector="user-menu-trigger"
  >
    <hui-avatar
      [avatarUrl]="user.avatar"
      [name]="user.displayName"
      shape="round"
      class="avatar-absolute"
    ></hui-avatar>
    <span
      class="d-none d-md-block cs-mr-5 cs-ml-5 cs-ellipsis-200"
      data-selector="user-menu-display-name"
      >{{ user.displayName }}</span
    >
    <mat-icon svgIcon="chevron-down"></mat-icon>
  </button>
  <mat-menu
    #menu="matMenu"
    xPosition="before"
    yPosition="below"
    backdropClass="navigation-dropdown-backdrop"
    [overlapTrigger]="true"
  >
    <header
      class="d-flex justify-content-center align-items-center cs-height-50-xs cs-pv-0 cs-ph-15"
    >
      <hui-avatar
        [avatarUrl]="user.avatar"
        [name]="user.displayName"
        shape="round"
        class="cs-mr-10"
      ></hui-avatar>
      <div>
        <p class="cs-c-shade-80 cs-title-8 cs-ellipsis-200">
          {{ user.displayName }}
        </p>
        <small
          *ngIf="user.firstName && user.lastName"
          class="cs-c-shade-80 cs-text-2 cs-ellipsis-200 d-block"
        >
          {{ user.email }}
        </small>
      </div>
    </header>
    <ng-container *ngFor="let item of menuItems">
      <a
        *ngIf="item.routerLink"
        mat-menu-item
        [routerLink]="item.routerLink"
        data-selector="user-menu-item"
        >{{ item.translationKey | language }}</a
      >
      <a
        *ngIf="item.callback"
        mat-menu-item
        (click)="item.callback()"
        data-selector="user-menu-item"
        >{{ item.translationKey | language }}</a
      >
    </ng-container>
  </mat-menu>
</ng-container>
