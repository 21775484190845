import { UserDTO } from '../secondary/dto/user.dto';
import { UserRoleQuery, ROLE_LABEL_WITH_COLOR_MAP } from './user-role.query';

export class UserQuery {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly avatar: string,
    public readonly email: string,
    public readonly role: UserRoleQuery,
    public readonly createdAt: Date,
    private readonly registered: boolean,
  ) {}

  static fromUserDTO(dto: UserDTO): UserQuery {
    return new UserQuery(
      dto.id,
      dto.name,
      dto.avatar,
      dto.email,
      UserRoleQuery.fromDTO(dto.role),
      new Date(dto.createdAt),
      dto.registered,
    );
  }

  isOwner(): boolean {
    return this.role.label === ROLE_LABEL_WITH_COLOR_MAP.owner.label;
  }

  isNotRegistered(): boolean {
    return this.registered === false;
  }
}
