import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@app.cobiro.com/notifications';
import { DataSelectorModule } from '@app.cobiro.com/shared/hui/data-selector';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { TopBarFeaturesLoadedEventHandler } from './adapters/top-bar-features-loaded.event-handler';
import { TopBarSiteSelectedEventHandler } from './adapters/top-bar-site-selected.event-handler';
import { TopBarUserChangedEventHandler } from './adapters/top-bar-user-changed.event-handler';
import {
  TOP_BAR_STATE_GETTER,
  TOP_BAR_STATE_SETTER,
  TopBarState,
} from './application/top-bar.state';
import { NavigationDropdownComponent } from './ui/navigation/navigation-dropdown/navigation-dropdown.component';
import { NavigationListComponent } from './ui/navigation/navigation-list/navigation-list.component';
import { TopBarComponent } from './ui/top-bar/top-bar.component';
import {
  ApplicationBusModule,
  provideApplicationEventHandler,
  provideApplicationEventsHandler,
} from '@cobiro/eda';
import { RolesModule } from '@cobiro/roles';
import { MatIconModule } from '@angular/material/icon';
import { TopBarUserAdditionalDataChangedEventHandler } from './adapters/top-bar-user-additional-data-changed.event-handler';
import { HuiUserMenuModule } from '@app.cobiro.com/shared/hui/user-menu';
import { TeamsSwitcherWidgetComponentModule } from '@app.cobiro.com/cobiro-pro-teams-switcher';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LanguageModule,
    ApplicationBusModule,
    DataSelectorModule,
    MatMenuModule,
    FeatureFlagsModule,
    NotificationsModule,
    RolesModule,
    MatIconModule,
    HuiUserMenuModule,
    TeamsSwitcherWidgetComponentModule,
  ],
  declarations: [TopBarComponent, NavigationListComponent, NavigationDropdownComponent],
  providers: [
    TopBarState,
    provideApplicationEventHandler(TopBarSiteSelectedEventHandler),
    provideApplicationEventHandler(TopBarFeaturesLoadedEventHandler),
    provideApplicationEventHandler(TopBarUserChangedEventHandler),
    provideApplicationEventsHandler(TopBarUserAdditionalDataChangedEventHandler),
    { provide: TOP_BAR_STATE_GETTER, useExisting: TopBarState },
    { provide: TOP_BAR_STATE_SETTER, useExisting: TopBarState },
  ],
  exports: [TopBarComponent],
})
export class TopBarModule {}
