<nav class="d-flex position-sticky cs-topbar cs-z-index-10">
  <div class="d-flex justify-content-center align-items-center w-100">
    <ng-container *ngIf="currentTeam$ | async as team">
      <div
        data-selector="cobiro-pro-team-logo"
        class="d-flex align-items-center cs-transition-regular-ease-out cs-bg-hover-shade-6 cs-cursor-pointer br-5 cs-p-5"
        (click)="onCurrentTeamClicked()"
      >
        <hui-avatar [avatarUrl]="team.avatar" [name]="team.name"></hui-avatar>
        <p class="cs-title-7 cs-ml-10">{{ team.name }}</p>
      </div>
    </ng-container>
    <ng-container *libFeatureFlags="['cobiro_pro_new_team_switcher']">
      <lib-teams-switcher-widget class="cs-ml-5"></lib-teams-switcher-widget>
    </ng-container>
    <button
      *ngIf="hasUpgradeButton$ | async"
      class="cs-ml-5 cs-btn cs-btn-sm cs-btn-gradient"
      data-selector="cobiro-pro-upgrade-plan-button"
      (click)="onUpgradeButtonClicked()"
    >
      <mat-icon [svgIcon]="'crown'" class="cs-btn-icon"></mat-icon>
      {{ '_upgrade' | language }}
    </button>
    <div class="d-flex mx-auto position-absolute">
      <lib-client-workspace-manager></lib-client-workspace-manager>
    </div>
    <div class="flex-1"></div>
    <hui-user-menu [userDetails]="userDetails$ | async" [menuItems]="userMenuItems"></hui-user-menu>
  </div>
</nav>
