import { Inject, Injectable } from '@angular/core';
import { SiteSelectedEvent } from '@app.cobiro.com/core/events';
import { TOP_BAR_STATE_SETTER, TopBarStateSetter } from '../application/top-bar.state';
import { ApplicationEventHandler } from '@cobiro/eda';

@Injectable()
export class TopBarSiteSelectedEventHandler implements ApplicationEventHandler {
  eventClass = SiteSelectedEvent;
  constructor(@Inject(TOP_BAR_STATE_SETTER) private _topBarState: TopBarStateSetter) {}

  handle(event: SiteSelectedEvent): void {
    this._topBarState.setSiteId(event.siteId);
  }
}
