<div
  class="position-relative d-flex flex-column justify-content-between align-items-center cs-bg-shade-0"
>
  <mat-icon
    svgIcon="close"
    class="position-absolute cs-top-15 cs-right-15 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-create-client-close-modal"
    [mat-dialog-close]="true"
  ></mat-icon>
  <div
    *huiLoaderContainer="product$ | async as product"
    class="d-flex flex-md-row flex-column justify-content-center align-items-center cs-max-width-905-xs"
  >
    <div class="d-flex flex-1" *ngIf="product.img">
      <img class="d-block w-100 content-loading-image-height" [src]="product.img" />
    </div>
    <div class="d-flex flex-1 flex-column cs-p-40">
      <h1 class="cs-title-4">
        {{ 'cobiro_pro_setup_marketing_' + product.product + '_title' | language }}
      </h1>
      <p
        class="cs-text-1 cs-mb-20 cs-mt-10"
        [innerHTML]="'cobiro_pro_setup_marketing_' + product.product + '_description' | language"
      ></p>
      <div class="d-flex flex-column justify-content-left">
        <div
          *ngFor="let item of product.advantages"
          class="cs-z-index-2 cs-mb-10 d-flex justify-content-left"
        >
          <div class="d-flex align-items-start cs-mr-10">
            <mat-icon
              svgIcon="checkmark-green-circled-2"
              class="cs-size-sm cs-c-shade-0 cs-mr-5 cs-mt-3"
            ></mat-icon>
            <p class="cs-text-1">
              {{ item | language }}
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-left">
        <button class="cs-btn cs-btn-primary cs-mt-30 cs-mb-10" (click)="goToProduct()">
          {{ '_continue' | language }}
        </button>
      </div>
    </div>
  </div>
</div>
