import { NgModule } from '@angular/core';
import { PATCHES_USERS_CONTEXT_STORAGE } from '../../../application/ports/secondary/context/patches-users-context.storage-port';
import { SELECTS_USERS_CONTEXT_STORAGE } from '../../../application/ports/secondary/context/selects-users-context.storage-port';
import { InMemoryUsersContextStorage } from './in-memory-users-context.storage';
@NgModule({
  providers: [
    InMemoryUsersContextStorage,
    { provide: SELECTS_USERS_CONTEXT_STORAGE, useExisting: InMemoryUsersContextStorage },
    { provide: PATCHES_USERS_CONTEXT_STORAGE, useExisting: InMemoryUsersContextStorage },
  ],
})
export class InMemoryUsersContextStorageModule {}
