import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HuiUserMenuComponent } from './user-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    LanguageModule,
    HuiAvatarModule,
  ],
  declarations: [HuiUserMenuComponent],
  exports: [HuiUserMenuComponent],
})
export class HuiUserMenuModule {}
