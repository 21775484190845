import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  group: string;
  partnerId: number;
  createdAt: number;
  avatar: string;
}

export interface GetsDetails {
  get(): Observable<UserDetails>;
}

export const GETS_DETAILS = new InjectionToken<GetsDetails>('GETS_DETAILS');
